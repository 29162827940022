import Popover from "@material-ui/core/Popover";
import React, { Fragment } from "react";

import { PopoverFormStyles } from "../../../sales/components/PopoverFormStyles";
import { InputSelect } from "../../../shared/components/FormElements/InputSelect";
import { InputSelectRequiredwithId } from "../../../shared/components/FormElements/InputSelectRequiredwithId";
import {
  AuthContextProps,
  FormHookDispState,
  SettingsContextProps,
} from "../../../shared/data/types";

interface PopoverSelectUserProps {
  anchorEl: HTMLButtonElement | HTMLLIElement | null;
  setAnchorEl: React.Dispatch<
    React.SetStateAction<HTMLButtonElement | HTMLLIElement>
  >;
  inputHandler: (
    id: string,
    value: string | number | boolean | string[] | File,
    isValid: boolean,
    label: string
  ) => void;
  usersOpts: {
    txt: string;
    id: string;
  }[];
  auth: AuthContextProps;
  formState: FormHookDispState;
  isLoading: boolean;
  settingsCtx: SettingsContextProps;
}

export const PopoverSelectUser = (props: PopoverSelectUserProps) => {
  const {
    anchorEl,
    setAnchorEl,
    inputHandler,
    usersOpts,
    auth,
    formState,
    isLoading,
    settingsCtx,
  } = props;
  const open = Boolean(anchorEl);
  const classes = PopoverFormStyles();

  const onChangeHandler = () => {
    setAnchorEl(null);
  };

  const salesTeams =
    settingsCtx.teams
      ?.filter((team) => {
        return team.type === "SALES";
      })
      ?.filter((team) => {
        if (auth.type === "VENDOR") {
          return team.leader === auth.userId;
        } else {
          return true;
        }
      })
      ?.map((team) => {
        return { txt: team?.name || "", id: team?._id || "" };
      }) || [];

  return (
    <Fragment>
      <Popover
        id="popover-select-user"
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        style={{ zIndex: 502 }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <form className={classes.formRoot}>
          {salesTeams?.length > 0 && (
            <InputSelect
              id="filterType"
              label="Tipo de Filtro"
              variant="outlined"
              onInput={inputHandler}
              options={["INDIVIDUAL", "EQUIPE"]}
              initialValue={
                formState?.inputs?.filterType?.value?.toString() || "INDIVIDUAL"
              }
              disabled={isLoading}
            />
          )}
          {formState?.inputs?.filterType?.value !== "EQUIPE" && (
            <InputSelectRequiredwithId
              id="user"
              label="Filtrar por"
              variant="outlined"
              onInput={inputHandler}
              options={usersOpts}
              initialValue={
                usersOpts
                  ?.map((u) => u.id)
                  ?.includes(formState?.inputs?.user?.value?.toString())
                  ? formState?.inputs?.user?.value?.toString()
                  : auth.type !== "VENDOR"
                  ? auth?.managerId || auth.userId
                  : auth.userId || usersOpts?.[0]?.id
              }
              disabled={isLoading}
              onChangeHandler={onChangeHandler}
            />
          )}
          {formState?.inputs?.filterType?.value === "EQUIPE" && (
            <InputSelectRequiredwithId
              id="user"
              label="Filtrar por"
              variant="outlined"
              onInput={inputHandler}
              options={salesTeams}
              initialValue={
                salesTeams
                  ?.map((t) => t.id)
                  ?.includes(formState?.inputs?.user?.value?.toString())
                  ? formState?.inputs?.user?.value?.toString()
                  : salesTeams?.[0]?.id
              }
              disabled={isLoading}
              onChangeHandler={onChangeHandler}
            />
          )}
        </form>
      </Popover>
    </Fragment>
  );
};
