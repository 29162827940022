import CloudDoneRoundedIcon from "@material-ui/icons/CloudDoneRounded";
import SystemUpdateRoundedIcon from "@material-ui/icons/SystemUpdateRounded";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import SignalWifiOffRoundedIcon from "@material-ui/icons/SignalWifiOffRounded";

export const getAppStatusIcon = (
  needCriticalUpdate: boolean,
  needUpdate: boolean,
  updateAvailable: boolean
) => {
  const connectionStatus: "ONLINE" | "OFFLINE" =
    (localStorage.getItem("connectionStatus") as "ONLINE" | "OFFLINE") ||
    "ONLINE";

  if (connectionStatus === "OFFLINE") {
    return <SignalWifiOffRoundedIcon />;
  } else if (connectionStatus === "ONLINE") {
    if (needCriticalUpdate) {
      return <WarningRoundedIcon />;
    } else if (!updateAvailable && !needUpdate) {
      return <CloudDoneRoundedIcon />;
    } else {
      return <SystemUpdateRoundedIcon />;
    }
  }
};

export const getAppStatusTitle = (
  needCriticalUpdate: boolean,
  needUpdate: boolean,
  updateAvailable: boolean
) => {
  const connectionStatus: "ONLINE" | "OFFLINE" =
    (localStorage.getItem("connectionStatus") as "ONLINE" | "OFFLINE") ||
    "ONLINE";

  if (connectionStatus === "OFFLINE") {
    return "App Offline";
  } else if (connectionStatus === "ONLINE") {
    if (needCriticalUpdate) {
      return "Atualização Importante";
    } else if (!updateAvailable && !needUpdate) {
      return "App Atualizado";
    } else {
      return "Atualização Disponível";
    }
  }
};
