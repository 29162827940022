import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { ModalConfirmStyles } from "./ModalConfirmStyles";

export interface ModalConfirmProps {
  open: boolean;
  closeHandler: () => void;
  confirmHandler: () => void | Promise<void>;
  message: string | JSX.Element;
  confirmOnly?: boolean;
  confirmTxt?: string;
  confirmTitle?: string;
  positiveTitle?: boolean;
}

export const ModalConfirm = (props: ModalConfirmProps) => {
  const {
    open,
    closeHandler,
    confirmHandler,
    message,
    confirmOnly,
    confirmTxt,
    positiveTitle,
    confirmTitle,
  } = props;
  const classes = ModalConfirmStyles();

  return (
    <div>
      <Dialog style={{ zIndex: 10002 }} open={open} onClose={closeHandler}>
        <DialogTitle
          className={
            !positiveTitle
              ? classes.confirmModalTitle
              : classes.confirmModalTitlePos
          }
        >
          {confirmTitle || "AVISO"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.confirmModalContent}>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {confirmTxt && (
            <Button
              onClick={confirmHandler}
              color={positiveTitle ? "primary" : "secondary"}
            >
              {confirmTxt}
            </Button>
          )}
          {!confirmTxt && (
            <Button
              onClick={confirmHandler}
              color={positiveTitle ? "primary" : "secondary"}
            >
              {!confirmOnly ? "Sim" : "Entendi"}
            </Button>
          )}
          {!confirmOnly && (
            <Button onClick={closeHandler} color="default">
              Não
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};
