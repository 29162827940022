import React, { useReducer, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import NumberFormat from "react-number-format";

import { InputDispState, InputDispAction } from "./Input";
import { NumberFormatCustomProps } from "./InputCardNumber";
import { validate } from "../../util/validators";

function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, name, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: name,
            value: values.value,
          },
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      isNumericString
      decimalScale={2}
      fixedDecimalScale
    />
  );
}

const inputReducer = (state: InputDispState, action: InputDispAction) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: /^[0-9,.]*$/.test(action.val)
          ? action.val
          : action.val.replace(/[^.,\d]/g, ""),
        isValid: validate(
          /^[0-9,.]*$/.test(action.val)
            ? action.val
            : action.val.replace(/[^.,\d]/g, ""),
          action.validators
        ),
      };
    case "TOUCH":
      return {
        ...state,
        isTouched: true,
      };
    case "WIPE_ZERO_TOUCH":
      return {
        ...state,
        value: Number(state.value) === 0 ? (state.value = "") : state.value,
      };
    case "UNTOUCH":
      return {
        ...state,
        isTouched: false,
      };
    default:
      return state;
  }
};

export interface InputPrefixOutlinedFloatProps {
  id: string;
  label: string;
  onInput: (id: string, value: string, isValid: boolean, label: string) => void;
  helperText: string;
  type: string;
  initialValue?: string;
  initialValid?: boolean;
  variant?: "standard" | "filled" | "outlined";
  forceError?: boolean;
  placeholder?: string;
  disabled?: boolean;
  reset?: boolean;
  reinitialize?: boolean;
  wipeZero?: boolean;
  updateInitValue?: boolean;
  prefix: string;
  validators: { type: string; val?: number }[];
  extraInputHandler?: (value: string | number) => void;
  style?: {};
  size?: "small" | "medium";
  onBlurHandler?: () => void;
}

export const InputPrefixOutlinedFloat = (
  props: InputPrefixOutlinedFloatProps
) => {
  const {
    id,
    label,
    onInput,
    forceError,
    helperText,
    type,
    initialValue,
    initialValid,
    variant,
    placeholder,
    disabled,
    validators,
    reinitialize,
    reset,
    wipeZero,
    updateInitValue,
    prefix,
    extraInputHandler,
    style,
    size = "medium",
    onBlurHandler,
  } = props;
  const [inputState, dispatch] = useReducer(inputReducer, {
    value: initialValue || "",
    isTouched: false,
    isValid: initialValid || false,
  });
  const { value, isValid, isTouched } = inputState;

  useEffect(() => {
    onInput(id, value, isValid, label.charAt(0).toUpperCase() + label.slice(1));
  }, [id, value, isValid, label, onInput]);

  useEffect(() => {
    if (reset) {
      dispatch({
        type: "CHANGE",
        val: "",
        validators: validators,
      });
      dispatch({
        type: "UNTOUCH",
      });
    }
  }, [reset, reinitialize, initialValue]);

  useEffect(() => {
    if (updateInitValue || reinitialize) {
      dispatch({
        type: "CHANGE",
        val: initialValue,
        validators: validators,
      });
      dispatch({
        type: "UNTOUCH",
      });
    }
  }, [reinitialize, initialValue, updateInitValue]);

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: "CHANGE",
      val: event.target.value,
      validators: validators,
    });

    if (extraInputHandler) {
      extraInputHandler(event.target.value);
    }
  };

  const touchHandler = () => {
    dispatch({ type: "TOUCH" });

    if (onBlurHandler) {
      onBlurHandler();
    }
  };

  const wipeZeroHandler = () => {
    if (wipeZero) {
      dispatch({ type: "WIPE_ZERO_TOUCH" });
    }
  };

  return (
    <TextField
      size={size}
      style={style}
      disabled={disabled}
      error={(!isValid && isTouched) || forceError}
      id={id}
      name={id}
      value={value}
      variant={variant}
      onChange={changeHandler}
      onFocus={wipeZeroHandler}
      onBlur={touchHandler}
      type={type}
      label={label.charAt(0).toUpperCase() + label.slice(1)}
      helperText={forceError ? helperText : !isValid && isTouched && helperText}
      placeholder={placeholder}
      InputProps={
        !!prefix
          ? {
              startAdornment: (
                <InputAdornment position="start">{prefix}</InputAdornment>
              ),
              inputComponent: NumberFormatCustom as any,
              onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === "Enter") {
                  e.preventDefault();

                  if (onBlurHandler) {
                    onBlurHandler();
                  }
                }
              },
            }
          : {
              inputComponent: NumberFormatCustom as any,
              onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === "Enter") {
                  e.preventDefault();

                  if (onBlurHandler) {
                    onBlurHandler();
                  }
                }
              },
            }
      }
    />
  );
};
