import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";

import { LoadingSpinnerFullScreenGraphStyles } from "./LoadingSpinnerFullScreenGraphStyles";
import "./LoadingSpinnersFullScreenCommonStyles.css";

interface LoadingSpinnerFullScreenGraphProps {
  closerToTop?: boolean;
  contentSpinner?: boolean;
  overlay?: boolean;
  overlay2?: boolean;
}

export const LoadingSpinnerFullScreenGraph = (
  props: LoadingSpinnerFullScreenGraphProps
) => {
  const { closerToTop, contentSpinner, overlay, overlay2 } = props;
  const classes = LoadingSpinnerFullScreenGraphStyles();

  return (
    <React.Fragment>
      {!contentSpinner && !overlay && (
        <div className={classes.linearRoot}>
          <LinearProgress />
        </div>
      )}
      {!closerToTop && !contentSpinner && !overlay && !overlay2 && (
        <div className={classes.roundRoot}>
          <div className="loadingio-spinner-wedges-lnysajxxlyq">
            <div className="ldio-8i84ida6juc">
              <div>
                <div>
                  <div></div>
                </div>
                <div>
                  <div></div>
                </div>
                <div>
                  <div></div>
                </div>
                <div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {closerToTop && (
        <div className={classes.roundRootCloserToTop}>
          <div className="loadingio-spinner-wedges-lnysajxxlyq">
            <div className="ldio-8i84ida6juc">
              <div>
                <div>
                  <div></div>
                </div>
                <div>
                  <div></div>
                </div>
                <div>
                  <div></div>
                </div>
                <div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {contentSpinner && (
        <div className={classes.rootContent}>
          <div className="loadingio-spinner-wedges-lnysajxxlyq">
            <div className="ldio-8i84ida6juc">
              <div>
                <div>
                  <div></div>
                </div>
                <div>
                  <div></div>
                </div>
                <div>
                  <div></div>
                </div>
                <div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {(overlay || overlay2) && (
        <div className={overlay ? classes.rootOverlay : classes.rootOverlay2}>
          <div className="loadingio-spinner-wedges-lnysajxxlyq">
            <div className="ldio-8i84ida6juc">
              <div>
                <div>
                  <div></div>
                </div>
                <div>
                  <div></div>
                </div>
                <div>
                  <div></div>
                </div>
                <div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
