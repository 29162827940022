import { useState, useEffect } from "react";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import { Input } from "../../../shared/components/FormElements/Input";
import { InputPhone } from "../../../shared/components/FormElements/InputPhone";
import { InputSelectRequired } from "../../../shared/components/FormElements/InputSelectRequired";
import { LoadingSpinnerOverlayRegular } from "../../../shared/components/UIElements/LoadingSpinnerOverlayRegular";
import { ModalError } from "../../../shared/components/UIElements/ModalError";
import { ModalSuccess } from "../../../shared/components/UIElements/ModalSuccess";
import { ModalInvalid } from "../../../shared/components/UIElements/ModalInvalid";
import { useHttpClient } from "../../../shared/hooks/httpHook";
import { useForm } from "../../../shared/hooks/formHook";
import { removeFormStateProperty } from "../../../shared/util/removeFormStateProperties";
import { updateFormValidity } from "../../../shared/util/updateFormValidity";
import { openNewTabUrl } from "../../../shared/util/openNewTabUrl";
import { useQuery } from "../../../shared/util/useQuery";
import {
  VALIDATOR_REQUIRE,
  VALIDATOR_EMAIL,
  VALIDATOR_MINLENGTH,
} from "../../../shared/util/validators";
import { openWhatsapp } from "../../../shared/util/openWhatsapp";
import { aboutPageFormHandler } from "../../api/signupPagesAPI";
import { TIME_WORKING, SALES_TEAM_STATUS } from "../../../shared/data/static";

import { PopoverFormStyles } from "./PopoverFormStyles";

interface AboutTrialFormPopoverProps {
  id: string;
  anchorEl: HTMLButtonElement | HTMLSpanElement | null;
  setAnchorEl: React.Dispatch<
    React.SetStateAction<HTMLButtonElement | HTMLSpanElement>
  >;
}

export const AboutTrialFormPopover = (props: AboutTrialFormPopoverProps) => {
  const { id, anchorEl, setAnchorEl } = props;
  const classes = PopoverFormStyles();
  const { isLoading, error, success, sendRequest, clearError, clearSuccess } =
    useHttpClient();
  const open = Boolean(anchorEl);
  const { formState, inputHandler, setFormData, reportInvalid } = useForm(
    {},
    false
  );
  const [invalidInputs, setInvalidInputs] = useState<{
    showInvalid: boolean;
    invalidFields: string[];
  }>({
    showInvalid: false,
    invalidFields: [],
  });
  const [vendorPhone, setVendorPhone] = useState<string>("");
  const [extFormValid, setExtFormValid] = useState<boolean>(false);
  const src = useQuery().get("src") || "";
  const tag = useQuery().get("tag") || "";

  const resetHandler = () => {
    setFormData({}, false, true);
  };

  useEffect(() => {
    if (formState.inputs?.timeWorking?.value?.toString()?.includes("Ainda")) {
      removeFormStateProperty(formState, "salesTeamStatus");
    }
  }, [formState?.inputs?.timeWorking?.value]);

  useEffect(() => {
    setExtFormValid(updateFormValidity(formState));
  }, [formState]);

  const registerHandler = () => {
    openNewTabUrl("https://assineazume.com.br/obrigado");
    aboutPageFormHandler({ sendRequest, formState, setVendorPhone, src, tag })
      .then(() => {
        // if (!openedTyp) {
        //   openNewTabUrl("https://assineazume.com.br/obrigado", setOpenedtyp);
        // }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const reportInvalidHandler = () => {
    const whatIsInvalid = reportInvalid(formState.inputs);
    setInvalidInputs({ showInvalid: true, invalidFields: whatIsInvalid });
  };

  const confirmModalCloseHandler = () => {
    setInvalidInputs({ showInvalid: false, invalidFields: [] });
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={() => {
        setAnchorEl(null);
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      {isLoading && <LoadingSpinnerOverlayRegular />}
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        className={classes.formRoot}
      >
        <ModalError error={error} onClear={clearError} />
        <ModalSuccess
          success={success}
          onClear={clearSuccess}
          message="Cadastro efetuado com sucesso! Em até 24h úteis um de nossos consultores entrará em contato com você."
          dontPush
          reset={resetHandler}
        />
        <ModalInvalid
          open={invalidInputs.showInvalid}
          closeHandler={confirmModalCloseHandler}
          message={invalidInputs.invalidFields.map((field) => {
            return <li key={field + "key"}>{field}</li>;
          })}
        />
        <Typography className="about-centered-subtitle-no-margin">
          Cadastro para Contato
        </Typography>
        <Input
          id="name"
          type="text"
          label="nome"
          variant="outlined"
          validators={[VALIDATOR_REQUIRE()]}
          helperText="Insira um nome válido."
          onInput={inputHandler}
          isName
          forceUpperCase
          reset={formState.reset}
          forceError={formState.inputs?.name?.forceError}
        />
        <Input
          id="email"
          type="email"
          label="e-mail"
          variant="outlined"
          validators={[VALIDATOR_EMAIL()]}
          helperText="Insira um e-mail válido."
          onInput={inputHandler}
          reset={formState.reset}
          forceError={formState.inputs?.email?.forceError}
        />
        <InputPhone
          id="phone"
          type="text"
          label="celular"
          variant="outlined"
          validators={[VALIDATOR_MINLENGTH(10)]}
          helperText="Insira um número válido."
          onInput={inputHandler}
          reset={formState.reset}
          forceError={formState.inputs?.phone?.forceError}
        />
        <InputSelectRequired
          id="timeWorking"
          label="Já trabalha com Energia Solar?"
          variant="outlined"
          onInput={inputHandler}
          options={TIME_WORKING}
          reset={formState.reset}
          forceError={formState.inputs?.contactType?.forceError}
        />
        {!!formState.inputs?.timeWorking?.isValid &&
          !formState.inputs?.timeWorking?.value
            ?.toString()
            ?.includes("Ainda") && (
            <InputSelectRequired
              id="salesTeamStatus"
              label="Possui quantos vendedores?"
              variant="outlined"
              onInput={inputHandler}
              options={SALES_TEAM_STATUS}
              reset={formState.reset}
              forceError={formState.inputs?.contactType?.forceError}
            />
          )}
        {extFormValid && (
          <Button
            onClick={registerHandler}
            type="submit"
            variant="contained"
            color="primary"
          >
            ENTRAR EM CONTATO
          </Button>
        )}
        {!extFormValid && (
          <Button
            onClick={reportInvalidHandler}
            variant="contained"
            color="secondary"
          >
            O QUE FALTA PREENCHER?
          </Button>
        )}
      </form>
    </Popover>
  );
};
