import { withStyles, WithStyles, createStyles } from "@material-ui/core/styles";
import { Legend } from "@devexpress/dx-react-chart-material-ui";

const legendLabelStyles = createStyles({
  label: {
    whiteSpace: "nowrap",
  },
});

const legendLabelBase = (props: WithStyles<typeof legendLabelStyles>) => {
  const { classes, ...restProps } = props;
  return (
    <Legend.Label
      className={classes.label}
      text={restProps["text"]}
      {...restProps}
    />
  );
};

const Label = (withStyles(legendLabelStyles, { name: "LegendLabel" })(
  legendLabelBase
) as unknown) as React.ComponentType<any>;

export default Label;
