import Popover from "@material-ui/core/Popover";
import React, { useContext, Fragment, useState } from "react";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import { InputCalendar } from "../../shared/components/FormElements/InputCalendar";
import { PopoverFormStyles } from "./PopoverFormStyles";
import { SettingsContext } from "../../shared/context/settingsContext";
import { editSaleStatus } from "../api/salesFunnelAPI";
import { dateFormatFromISOString } from "../../shared/util/dateFormatFromISOString";
import { AuthContextProps, KanbanFilledData } from "../../shared/data/types";

interface PopoverFormClosedProjExecStatusProps {
  itemId: string;
  item: KanbanFilledData["items"][0];
  anchorEl: HTMLButtonElement | HTMLLIElement | null;
  setAnchorEl: React.Dispatch<
    React.SetStateAction<HTMLButtonElement | HTMLLIElement>
  >;
  auth: AuthContextProps;
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean
  ) => Promise<any>;
  setKanbanData: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
  setActiveItem?: React.Dispatch<React.SetStateAction<string>>;
  setUpdateColumnsTotals?: React.Dispatch<React.SetStateAction<string[]>>;
}

export const PopoverFormClosedProjExecStatus = (
  props: PopoverFormClosedProjExecStatusProps
) => {
  const {
    item,
    anchorEl,
    setAnchorEl,
    auth,
    sendRequest,
    setKanbanData,
    setActiveItem,
    setUpdateColumnsTotals,
  } = props;
  const settingsCtx = useContext(SettingsContext);
  const open = Boolean(anchorEl);
  const classes = PopoverFormStyles();
  const [execStatus, setExecStatus] = useState<"INTERNAL" | "OUTSOURCED">(
    "INTERNAL"
  );
  const [finishedDate, setFinishedDate] = useState<string>(
    new Date().toISOString()
  );
  const options = [
    { txt: "Execução Interna", id: "INTERNAL" },
    { txt: "Execução Terceirizada", id: "OUTSOURCED" },
  ];

  const changeValueHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setExecStatus(e.target.value as "INTERNAL" | "OUTSOURCED");
  };

  const selectectFinishedDateHandler = (date: string) => {
    setFinishedDate(date);
  };

  const confirmHandler = () => {
    editSaleStatus({
      sendRequest,
      auth,
      status: "CLOSED",
      approvedProposal: "",
      execStatus,
      itemId: item?._id,
      setKanbanData,
      toBeRemovedItem: item,
      finishedDate: dateFormatFromISOString(finishedDate),
    }).then(() => {
      if (setActiveItem) {
        setActiveItem(null);
      }
      if (setUpdateColumnsTotals) {
        setUpdateColumnsTotals([item.kanbanData?.column, "0"]);
      }
    });
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <Popover
        id={item?._id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        style={{ zIndex: 502 }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <form className={classes.formRoot}>
          <FormControl>
            <InputLabel id="fetch-type-filter-selector-label">
              Execução do serviço
            </InputLabel>
            <Select
              labelId="fetch-type-filter-selector-label"
              id="fetch-type-filter-selector"
              value={execStatus}
              onChange={changeValueHandler}
            >
              {options?.map((option) => {
                return (
                  <MenuItem key={option?.id} value={option?.id}>
                    {option?.txt}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {auth.signupPlan === "ENTERPRISE" && (
            <InputCalendar
              id="finishedDate"
              label="Data de Finalização"
              format="dd/MM/yy"
              disableFuture
              initialvalue={finishedDate}
              selectedEarlierTimePeriodHandler={selectectFinishedDateHandler}
            />
          )}
          <Button
            variant="contained"
            color="primary"
            disabled={!execStatus}
            onClick={() => {
              confirmHandler();
              setAnchorEl(null);
            }}
          >
            FINALIZAR
          </Button>
        </form>
      </Popover>
    </Fragment>
  );
};
