import React, { useContext, useEffect, useState } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Badge from "@material-ui/core/Badge";
import NotificationsActiveRoundedIcon from "@material-ui/icons/NotificationsActiveRounded";
import SyncRoundedIcon from "@material-ui/icons/SyncRounded";
import IconButton from "@material-ui/core/IconButton";

import { NotificationItem } from "./NotificationItem";
import { MenuCustomNotifications } from "../MUIThemed/MenuCustomNotifications";
import { ButtonLoadMore } from "../UIElements/ButtonLoadMore";
import { AuthContext } from "../../context/authContext";
import { useHttpClient } from "../../hooks/httpHook";

export type Notification = {
  id: string;
  title: string;
  content: string;
  openUrl: string;
  type: string;
  registryDate: string;
};

export const NotificationsMenu = () => {
  const [deletions, setDeletions] = useState<number>(0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const auth = useContext(AuthContext);
  const [multiplier, setMultiplier] = useState<number>(0);
  const [showLoadMore, setShowLoadMore] = useState<boolean>(false);
  const [totalNotifications, setTotalNotifications] = useState<number>(0);
  const [notifications, setNotifications] = useState<Notification[]>(null);
  const { isLoading, sendRequest } = useHttpClient();

  useEffect(() => {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/notifications/user/${auth.userId}/${multiplier}`;

    const fetchData = async () => {
      try {
        const responseData: {
          notifications: Notification[];
          hasMoreItems: boolean;
          totalItems: number;
        } = await sendRequest(apiUrl, "GET", null, {
          Authorization: "Bearer " + auth.token,
        });

        setNotifications((prevValues) => {
          if (!!prevValues && prevValues.length > 0) {
            return [...prevValues, ...responseData.notifications];
          } else {
            return responseData.notifications;
          }
        });
        setShowLoadMore(responseData.hasMoreItems);
        setTotalNotifications(responseData.totalItems);
        setDeletions(0);
      } catch (err) {}
    };

    if (auth.isLoggedIn && !!auth.userId) {
      fetchData();
    } else {
      var timer = setTimeout(() => {
        if (auth.isLoggedIn && !!auth.userId) {
          fetchData();
        }
      }, 2000);
    }

    if (!auth.isLoggedIn) {
      setMultiplier(0);
      setNotifications(null);
      setTotalNotifications(0);
      setShowLoadMore(false);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [auth.isLoggedIn, sendRequest, multiplier]);

  const clickNotificationsHandler = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeNotificationsHandler = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      {auth.isLoggedIn && notifications && (
        <React.Fragment>
          <IconButton onClick={clickNotificationsHandler}>
            <Badge
              badgeContent={totalNotifications - deletions}
              color="secondary"
            >
              <NotificationsActiveRoundedIcon style={{ color: "#263351" }} />
            </Badge>
          </IconButton>
          <div>
            <MenuCustomNotifications
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={closeNotificationsHandler}
            >
              <MenuItem
                style={{
                  width: "0",
                  height: "0",
                  padding: "0",
                  margin: "0",
                  minHeight: "0",
                }}
              ></MenuItem>
              {notifications &&
                notifications.length > 0 &&
                notifications.map((notification, index) => {
                  return (
                    <NotificationItem
                      notification={notification}
                      key={`notificationItem ${index}`}
                      setDeletions={setDeletions}
                    />
                  );
                })}
              {notifications && notifications.length === 0 && (
                <MenuItem>
                  <ListItemText primary={"Nenhuma Notificação"} />
                </MenuItem>
              )}
              <ButtonLoadMore
                setMultiplier={setMultiplier}
                showLoadMore={showLoadMore}
                isLoading={isLoading}
                small
                fetchItems={async (multiplier) => {}}
              />
            </MenuCustomNotifications>
          </div>
        </React.Fragment>
      )}
      {auth.isLoggedIn ? (
        !notifications ? (
          <IconButton aria-label="notificacao">
            <SyncRoundedIcon style={{ color: "#263351" }} />
          </IconButton>
        ) : null
      ) : null}
    </React.Fragment>
  );
};
