import { makeStyles } from "@material-ui/core/styles";

export const WarningBarStyles = makeStyles(() => ({
  warningSection: {
    width: "100%",
    height: "auto",
    padding: "1rem 0",
    backgroundColor: "rgb(244, 67, 54)",
    boxShadow:
      "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  blueBackground: {
    backgroundColor: "rgb(38, 51, 81)",
  },
  redBackground: {
    backgroundColor: "rgb(244, 67, 54)",
  },
  orangeBackground: {
    backgroundColor: "rgb(251, 140, 0)",
  },
  warning: {
    width: "90%",
    textAlign: "center",
    color: "#fff",
  },
  warningLink: {
    margin: "10px auto 5px",
    backgroundColor: "#fff",
    padding: "5px 10px",
    borderRadius: "5px",
    color: "rgb(244, 67, 54)",
    maxWidth: "300px",
    fontWeight: 700,
    cursor: "pointer",
    transition: "all 0.2s",
    "&:hover": {
      backgroundColor: "#eee",
    },
    "&:focus": {
      backgroundColor: "#eee",
    },
  },

  warningLinkAlt: {
    margin: "10px auto 5px",
    color: "#fff",
    fontWeight: 700,
  },
  redText: {
    color: "rgb(244, 67, 54)",
  },
  orangeText: {
    color: "rgb(251, 140, 0)",
  },
  blueText: {
    color: "rgb(38, 51, 81)",
  },
}));
