import { useEffect, useState } from "react";
import { Chart, PieSeries } from "@devexpress/dx-react-chart-material-ui";
import { Animation } from "@devexpress/dx-react-chart";
import { Palette } from "@devexpress/dx-react-chart";

import { useHttpClient } from "../../../shared/hooks/httpHook";
import { ModalError } from "../../../shared/components/UIElements/ModalError";
import { LoadingSpinnerOverlayRegular } from "../../../shared/components/UIElements/LoadingSpinnerOverlayRegular";
import { addThousandSeparator } from "../../../shared/util/addThousandSeparator";
import { getMonthNameAndYear } from "../../../shared/util/getMonthNameAndYear";
import { getClosedSales } from "../../api/analyticsAPI";
import {
  AuthContextProps,
  SettingsContextProps,
} from "../../../shared/data/types";

import "./HomeDashboard.scss";

interface HomeDashboardSoldPowerProps {
  settingsCtx: SettingsContextProps;
  auth: AuthContextProps;
  fetchId: string;
  loadedData: {
    label: string;
    val: number;
    sales: number;
    power: number;
    delta: number;
    deltaIsPositive: boolean;
  }[];
  isLoadingClosedSales: boolean;
}

export const HomeDashboardSoldPower = (props: HomeDashboardSoldPowerProps) => {
  const { settingsCtx, auth, fetchId, loadedData, isLoadingClosedSales } =
    props;

  const hasData =
    loadedData.length > 0
      ? !!loadedData?.find((data) => {
          return data.power > 0;
        })
      : false;

  return (
    <div className="dashboard-item dashboard-item--sold-power">
      {isLoadingClosedSales && <LoadingSpinnerOverlayRegular />}
      {/* <ModalError error={error} onClear={clearError} /> */}
      <p className="dashboard-item__title">Potência Vendida (kWp)</p>
      {loadedData.length > 0 && hasData && (
        <div className="dashboard-item__doughnut-chart">
          <Chart height={250} data={loadedData?.slice(0, 3)}>
            <Palette scheme={["#05445e", "#189ab4", "#75e6da", "#d4f1f4"]} />
            <PieSeries
              valueField="power"
              argumentField="label"
              innerRadius={0.6}
            />
            <Animation />
          </Chart>
        </div>
      )}
      {loadedData.length > 0 && !hasData && (
        <div className="dashboard-item__doughnut-chart">
          <Chart height={250} data={[{ val: 1, label: "" }]}>
            <Palette scheme={["#d4f1f4"]} />
            <PieSeries
              valueField="val"
              argumentField="label"
              innerRadius={0.6}
            />
            <Animation />
          </Chart>
        </div>
      )}
      <div className="dashboard-item__legends">
        <div className="dashboard-item__legend">
          <div className="dashboard-item__legend-color dashboard-item__legend-color--1"></div>
          <div className="dashboard-item__legend-data">
            <p className="dashboard-item__legend-value">
              {addThousandSeparator(loadedData?.[0]?.power || 0)}
            </p>
            <p className="dashboard-item__legend-period">
              {getMonthNameAndYear(0)}
            </p>
          </div>
        </div>
        <div className="dashboard-item__legend">
          <div className="dashboard-item__legend-color dashboard-item__legend-color--2"></div>
          <div className="dashboard-item__legend-data">
            <p className="dashboard-item__legend-value">
              {addThousandSeparator(loadedData?.[1]?.power || 0)}
            </p>
            <p className="dashboard-item__legend-period">
              {getMonthNameAndYear(-1)}
            </p>
          </div>
        </div>
        <div className="dashboard-item__legend">
          <div className="dashboard-item__legend-color dashboard-item__legend-color--3"></div>
          <div className="dashboard-item__legend-data">
            <p className="dashboard-item__legend-value">
              {addThousandSeparator(loadedData?.[2]?.power || 0)}
            </p>
            <p className="dashboard-item__legend-period">
              {getMonthNameAndYear(-2)}
            </p>
          </div>
        </div>
      </div>
      <div className="dashboard-item__filler">
        <img
          className="dashboard-item__filler-img"
          src={`${process.env.PUBLIC_URL}/potencia-vendida.png`}
          alt=""
        />
      </div>
    </div>
  );
};
