import { CardStyles } from "./CardStyles";

interface CardProps {
  children: JSX.Element | JSX.Element[];
  cardType:
    | "simpleCard"
    | "aboutCard"
    | "mediumCard"
    | "infoCard"
    | "searchCard"
    | "searchCardNoMarginBottom"
    | "funnelSearchCard"
    | "tinyCard"
    | "extraTinyCard"
    | "simpleForm"
    | "signupCard100"
    | "signupCard"
    | "signupForm"
    | "infoCardSimple"
    | "websiteCard";
}

export const Card = (props: CardProps) => {
  const { cardType, children } = props;
  const classes = CardStyles();

  return (
    <div className={`card-item ${classes.root}`}>
      <div className={cardType}>{children}</div>
    </div>
  );
};
