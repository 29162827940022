import {
  ProposalData,
  StringNumbers,
  UserData,
  FormHookDispState,
  KanbanFilledData,
  SimulationResultsData,
} from "./types";
import { dateFormat } from "../util/dateFormat";

export const setMonthlyGenerationData = (responseData: {
  proposal: ProposalData;
}) => {
  return [
    {
      month: "jan",
      generation: Math.round(
        responseData.proposal.realSystemData.monthyGeneration[0]
      ),
      consumption: Math.round(responseData.proposal.totalMonthlyConsumption[0]),
      firstYearWithSystem: Math.round(
        responseData.proposal.revenueData.finantialViability.firstYearWithSystem
      ),
      firstYearNoSystem: Math.round(
        responseData.proposal.revenueData.finantialViability
          .firstYearNoSystem[0]
      ),
    },
    {
      month: "fev",
      generation: Math.round(
        responseData.proposal.realSystemData.monthyGeneration[1]
      ),
      consumption: Math.round(responseData.proposal.totalMonthlyConsumption[1]),
      firstYearWithSystem: Math.round(
        responseData.proposal.revenueData.finantialViability.firstYearWithSystem
      ),
      firstYearNoSystem: Math.round(
        responseData.proposal.revenueData.finantialViability
          .firstYearNoSystem[1]
      ),
    },
    {
      month: "mar",
      generation: Math.round(
        responseData.proposal.realSystemData.monthyGeneration[2]
      ),
      consumption: Math.round(responseData.proposal.totalMonthlyConsumption[2]),
      firstYearWithSystem: Math.round(
        responseData.proposal.revenueData.finantialViability.firstYearWithSystem
      ),
      firstYearNoSystem: Math.round(
        responseData.proposal.revenueData.finantialViability
          .firstYearNoSystem[2]
      ),
    },
    {
      month: "abr",
      generation: Math.round(
        responseData.proposal.realSystemData.monthyGeneration[3]
      ),
      consumption: Math.round(responseData.proposal.totalMonthlyConsumption[3]),
      firstYearWithSystem: Math.round(
        responseData.proposal.revenueData.finantialViability.firstYearWithSystem
      ),
      firstYearNoSystem: Math.round(
        responseData.proposal.revenueData.finantialViability
          .firstYearNoSystem[3]
      ),
    },
    {
      month: "mai",
      generation: Math.round(
        responseData.proposal.realSystemData.monthyGeneration[4]
      ),
      consumption: Math.round(responseData.proposal.totalMonthlyConsumption[4]),
      firstYearWithSystem: Math.round(
        responseData.proposal.revenueData.finantialViability.firstYearWithSystem
      ),
      firstYearNoSystem: Math.round(
        responseData.proposal.revenueData.finantialViability
          .firstYearNoSystem[4]
      ),
    },
    {
      month: "jun",
      generation: Math.round(
        responseData.proposal.realSystemData.monthyGeneration[5]
      ),
      consumption: Math.round(responseData.proposal.totalMonthlyConsumption[5]),
      firstYearWithSystem: Math.round(
        responseData.proposal.revenueData.finantialViability.firstYearWithSystem
      ),
      firstYearNoSystem: Math.round(
        responseData.proposal.revenueData.finantialViability
          .firstYearNoSystem[5]
      ),
    },
    {
      month: "jul",
      generation: Math.round(
        responseData.proposal.realSystemData.monthyGeneration[6]
      ),
      consumption: Math.round(responseData.proposal.totalMonthlyConsumption[6]),
      firstYearWithSystem: Math.round(
        responseData.proposal.revenueData.finantialViability.firstYearWithSystem
      ),
      firstYearNoSystem: Math.round(
        responseData.proposal.revenueData.finantialViability
          .firstYearNoSystem[6]
      ),
    },
    {
      month: "ago",
      generation: Math.round(
        responseData.proposal.realSystemData.monthyGeneration[7]
      ),
      consumption: Math.round(responseData.proposal.totalMonthlyConsumption[7]),
      firstYearWithSystem: Math.round(
        responseData.proposal.revenueData.finantialViability.firstYearWithSystem
      ),
      firstYearNoSystem: Math.round(
        responseData.proposal.revenueData.finantialViability
          .firstYearNoSystem[7]
      ),
    },
    {
      month: "set",
      generation: Math.round(
        responseData.proposal.realSystemData.monthyGeneration[8]
      ),
      consumption: Math.round(responseData.proposal.totalMonthlyConsumption[8]),
      firstYearWithSystem: Math.round(
        responseData.proposal.revenueData.finantialViability.firstYearWithSystem
      ),
      firstYearNoSystem: Math.round(
        responseData.proposal.revenueData.finantialViability
          .firstYearNoSystem[8]
      ),
    },
    {
      month: "out",
      generation: Math.round(
        responseData.proposal.realSystemData.monthyGeneration[9]
      ),
      consumption: Math.round(responseData.proposal.totalMonthlyConsumption[9]),
      firstYearWithSystem: Math.round(
        responseData.proposal.revenueData.finantialViability.firstYearWithSystem
      ),
      firstYearNoSystem: Math.round(
        responseData.proposal.revenueData.finantialViability
          .firstYearNoSystem[9]
      ),
    },
    {
      month: "nov",
      generation: Math.round(
        responseData.proposal.realSystemData.monthyGeneration[10]
      ),
      consumption: Math.round(
        responseData.proposal.totalMonthlyConsumption[10]
      ),
      firstYearWithSystem: Math.round(
        responseData.proposal.revenueData.finantialViability.firstYearWithSystem
      ),
      firstYearNoSystem: Math.round(
        responseData.proposal.revenueData.finantialViability
          .firstYearNoSystem[10]
      ),
    },
    {
      month: "dez",
      generation: Math.round(
        responseData.proposal.realSystemData.monthyGeneration[11]
      ),
      consumption: Math.round(
        responseData.proposal.totalMonthlyConsumption[11]
      ),
      firstYearWithSystem: Math.round(
        responseData.proposal.revenueData.finantialViability.firstYearWithSystem
      ),
      firstYearNoSystem: Math.round(
        responseData.proposal.revenueData.finantialViability
          .firstYearNoSystem[11]
      ),
    },
  ];
};

export const setCashFlowData = (responseData: { proposal: ProposalData }) => {
  return [
    {
      year: "1",
      flow: Math.round(
        responseData.proposal.revenueData.finantialViability.systemCashFlow[0]
      ),
    },
    {
      year: "2",
      flow: Math.round(
        responseData.proposal.revenueData.finantialViability.systemCashFlow[1]
      ),
    },
    {
      year: "3",
      flow: Math.round(
        responseData.proposal.revenueData.finantialViability.systemCashFlow[2]
      ),
    },
    {
      year: "4",
      flow: Math.round(
        responseData.proposal.revenueData.finantialViability.systemCashFlow[3]
      ),
    },
    {
      year: "5",
      flow: Math.round(
        responseData.proposal.revenueData.finantialViability.systemCashFlow[4]
      ),
    },
    {
      year: "6",
      flow: Math.round(
        responseData.proposal.revenueData.finantialViability.systemCashFlow[5]
      ),
    },
    {
      year: "7",
      flow: Math.round(
        responseData.proposal.revenueData.finantialViability.systemCashFlow[6]
      ),
    },
    {
      year: "8",
      flow: Math.round(
        responseData.proposal.revenueData.finantialViability.systemCashFlow[7]
      ),
    },
    {
      year: "9",
      flow: Math.round(
        responseData.proposal.revenueData.finantialViability.systemCashFlow[8]
      ),
    },
    {
      year: "10",
      flow: Math.round(
        responseData.proposal.revenueData.finantialViability.systemCashFlow[9]
      ),
    },
    {
      year: "11",
      flow: Math.round(
        responseData.proposal.revenueData.finantialViability.systemCashFlow[10]
      ),
    },
    {
      year: "12",
      flow: Math.round(
        responseData.proposal.revenueData.finantialViability.systemCashFlow[11]
      ),
    },
    {
      year: "13",
      flow: Math.round(
        responseData.proposal.revenueData.finantialViability.systemCashFlow[12]
      ),
    },
    {
      year: "14",
      flow: Math.round(
        responseData.proposal.revenueData.finantialViability.systemCashFlow[13]
      ),
    },
    {
      year: "15",
      flow: Math.round(
        responseData.proposal.revenueData.finantialViability.systemCashFlow[14]
      ),
    },
    {
      year: "16",
      flow: Math.round(
        responseData.proposal.revenueData.finantialViability.systemCashFlow[15]
      ),
    },
    {
      year: "17",
      flow: Math.round(
        responseData.proposal.revenueData.finantialViability.systemCashFlow[16]
      ),
    },
    {
      year: "18",
      flow: Math.round(
        responseData.proposal.revenueData.finantialViability.systemCashFlow[17]
      ),
    },
    {
      year: "19",
      flow: Math.round(
        responseData.proposal.revenueData.finantialViability.systemCashFlow[18]
      ),
    },
    {
      year: "20",
      flow: Math.round(
        responseData.proposal.revenueData.finantialViability.systemCashFlow[19]
      ),
    },
    {
      year: "21",
      flow: Math.round(
        responseData.proposal.revenueData.finantialViability.systemCashFlow[20]
      ),
    },
    {
      year: "22",
      flow: Math.round(
        responseData.proposal.revenueData.finantialViability.systemCashFlow[21]
      ),
    },
    {
      year: "23",
      flow: Math.round(
        responseData.proposal.revenueData.finantialViability.systemCashFlow[22]
      ),
    },
    {
      year: "24",
      flow: Math.round(
        responseData.proposal.revenueData.finantialViability.systemCashFlow[23]
      ),
    },
    {
      year: "25",
      flow: Math.round(
        responseData.proposal.revenueData.finantialViability.systemCashFlow[24]
      ),
    },
  ];
};

export const setCashFlowMobileData = (responseData: {
  proposal: ProposalData;
}) => {
  return [
    {
      year: "1",
      flow: Math.round(
        responseData.proposal.revenueData.finantialViability.systemCashFlow[0]
      ),
    },
    {
      year: "3",
      flow: Math.round(
        responseData.proposal.revenueData.finantialViability.systemCashFlow[2]
      ),
    },
    {
      year: "5",
      flow: Math.round(
        responseData.proposal.revenueData.finantialViability.systemCashFlow[4]
      ),
    },
    {
      year: "7",
      flow: Math.round(
        responseData.proposal.revenueData.finantialViability.systemCashFlow[6]
      ),
    },
    {
      year: "9",
      flow: Math.round(
        responseData.proposal.revenueData.finantialViability.systemCashFlow[8]
      ),
    },
    {
      year: "11",
      flow: Math.round(
        responseData.proposal.revenueData.finantialViability.systemCashFlow[10]
      ),
    },
    {
      year: "13",
      flow: Math.round(
        responseData.proposal.revenueData.finantialViability.systemCashFlow[12]
      ),
    },
    {
      year: "15",
      flow: Math.round(
        responseData.proposal.revenueData.finantialViability.systemCashFlow[14]
      ),
    },
    {
      year: "17",
      flow: Math.round(
        responseData.proposal.revenueData.finantialViability.systemCashFlow[16]
      ),
    },
    {
      year: "19",
      flow: Math.round(
        responseData.proposal.revenueData.finantialViability.systemCashFlow[18]
      ),
    },
    {
      year: "21",
      flow: Math.round(
        responseData.proposal.revenueData.finantialViability.systemCashFlow[20]
      ),
    },
    {
      year: "23",
      flow: Math.round(
        responseData.proposal.revenueData.finantialViability.systemCashFlow[22]
      ),
    },
    {
      year: "25",
      flow: Math.round(
        responseData.proposal.revenueData.finantialViability.systemCashFlow[24]
      ),
    },
  ];
};

export const setProposalCustomizationData = (responseData: {
  proposal: ProposalData;
}) => {
  return {
    validity: {
      value: !!responseData.proposal.proposalCustomizationData.validity
        ? responseData.proposal.proposalCustomizationData.validity.toString()
        : "15",
      isValid: true,
    },
    validityDate: {
      value: !!responseData.proposal.proposalCustomizationData.validityDate
        ? responseData.proposal.proposalCustomizationData.validityDate
        : dateFormat(15),
      isValid: true,
    },
    theme: {
      value: !!responseData.proposal.proposalCustomizationData.theme
        ? (responseData.proposal.proposalCustomizationData.theme.toString() as StringNumbers)
        : "1",
      isValid: true,
    },
  };
};

export const setPostFormDataRegisterOrEditKit = (
  formState: FormHookDispState,
  pvModItemsLength: number,
  invItemsLength: number,
  additionalItemsLength: number
) => {
  const pvModItems: ProposalData["pvModuleData"] = [];
  const invItems: ProposalData["inverterData"] = [];
  const additionalItems: ProposalData["additionalItems"] = [];

  for (let i = 0; i < pvModItemsLength; i++) {
    pvModItems.push({
      manufacturer:
        (formState.inputs[`mManufacturer${i}`]?.value as string) || "",
      power: +formState.inputs[`mPower${i}`]?.value || 0,
      quantity: +formState.inputs[`mQuantity${i}`]?.value || 0,
      weight: +formState.inputs[`mWeight${i}`]?.value || 0,
      mLength: +formState.inputs[`mLength${i}`]?.value || 0,
      width: +formState.inputs[`mWidth${i}`]?.value || 0,
      warrantyDefect: +formState.inputs[`mWarrantyDefect${i}`]?.value || 0,
      warrantyEfficiency:
        +formState.inputs[`mWarrantyEfficiency${i}`]?.value || 0,
    });
  }

  for (let i = 0; i < invItemsLength; i++) {
    invItems.push({
      iManufacturer:
        (formState.inputs[`iManufacturer${i}`]?.value as string) || "",
      iModel: (formState.inputs[`iModel${i}`]?.value as string) || "",
      iMonitoring: (formState.inputs[`iMonitoring${i}`]?.value as string) || "",
      iPower: +formState.inputs[`iPower${i}`]?.value || 0,
      iQuantity: +formState.inputs[`iQuantity${i}`]?.value || 0,
      iWarranty: +formState.inputs[`iWarranty${i}`]?.value || 0,
      type: formState.inputs[`isMicroinverter${i}`]?.value
        ? "microinverter"
        : "inverter",
    });
  }

  for (let i = 0; i < additionalItemsLength; i++) {
    additionalItems.push({
      item: (formState.inputs[`addItem${i}`]?.value as string) || "",
      quantity: (formState.inputs[`addItemQtd${i}`]?.value as string) || "",
      manufacter:
        (formState.inputs[`addItemManufacturer${i}`]?.value as string) || "",
      detail: (formState.inputs[`addItemDetail${i}`]?.value as string) || "",
    });
  }

  return JSON.stringify({
    pvModuleData: pvModItems,
    inverterData: invItems,
    additionalItems: additionalItems,
    kitData: {
      saveKit: formState.inputs.saveKit?.value || false,
      kitPrice: +formState.inputs.kitPrice?.value || 0,
      kitSupplier: formState.inputs.kitSupplier?.value || "",
      kitVoltage:
        +formState.inputs.kitVoltage?.value?.toString()?.replace(/\D/g, "") ||
        null,
      kitFixation: formState.inputs.kitFixation?.value || "",
    },
    aboutTheEquipament: formState.inputs.aboutTheEquipament?.value,
  });
};

export const setEquipamentData = (proposal: ProposalData) => {
  let mod = proposal.pvModuleData.length;

  const modArray = proposal.pvModuleData;
  const invArray = proposal.inverterData;
  const addItemsArray = proposal.additionalItems || [];
  const items = [...modArray, ...invArray, ...addItemsArray];
  const inv: number = invArray.length;
  const total: number = items.length;
  const itemsMaxPg1Index = mod < 5 ? 4 - mod : 0;

  return { items, itemsMaxPg1Index, mod, inv, total };
};

export const setGetCardToken = (
  paymentSessId: { sessionId: string },
  formState: FormHookDispState,
  issuer: string
) => {
  return JSON.stringify({
    sessionId: paymentSessId.sessionId,
    cardNumber: formState.inputs.cardNumber.value,
    brand: issuer,
    cvv: formState.inputs.securityCode.value,
    expirationMonth: formState.inputs.monthValidity.value,
    expirationYear: "20" + formState.inputs.yearValidity.value,
  });
};

export const setFinDataPostRequest = (
  formState: FormHookDispState,
  serviceCostsQtd: number,
  serviceRevenuesQtd: number,
  percComps: ProposalData["revenueData"]["percentageComponents"],
  financingQtd: number,
  optionsQtds: number[],
  pricingType: "EDIT" | "AUTO",
  proposalFinOptsIds: string[]
) => {
  const serviceCosts: ProposalData["revenueData"]["serviceCosts"] = [];
  for (let i = 0; i < serviceCostsQtd; i++) {
    serviceCosts.push({
      name:
        (formState.inputs?.[`svcCostName${i}`]?.value as string) ||
        "Valor de Serviço",
      value: +formState.inputs?.[`svcCostValue${i}`]?.value || 0,
      isRevenue: false,
    });
  }

  for (let i = 0; i < serviceRevenuesQtd; i++) {
    serviceCosts.push({
      name: formState.inputs?.[`svcRevName${i}`]?.value as string,
      value: +formState.inputs?.[`svcRevValue${i}`]?.value || 0,
      isRevenue: true,
    });
  }

  const newPercComps: ProposalData["revenueData"]["percentageComponents"] = [];
  const serviceCostPercs =
    percComps?.filter((item) => {
      return item.type === "PERCENTAGE_SERVICE" && !item.isRevenue;
    }) || [];

  serviceCostPercs.forEach((pc, i) => {
    newPercComps.push({
      name: formState.inputs?.[`percCompNameSvcCost${i}`]?.value as string,
      value: +formState.inputs?.[`percCompValueSvcCost${i}`]?.value || 0,
      type: pc.type,
      isRevenue: pc.isRevenue,
    });
  });

  const serviceRevPercs =
    percComps?.filter((item) => {
      return item.type === "PERCENTAGE_SERVICE" && item.isRevenue;
    }) || [];

  serviceRevPercs.forEach((pc, i) => {
    newPercComps.push({
      name: formState.inputs?.[`percCompNameSvcRev${i}`]?.value as string,
      value: +formState.inputs?.[`percCompValueSvcRev${i}`]?.value || 0,
      type: pc.type,
      isRevenue: pc.isRevenue,
    });
  });

  const kitCostPercs =
    percComps?.filter((item) => {
      return item.type === "PERCENTAGE_KIT" && !item.isRevenue;
    }) || [];

  kitCostPercs.forEach((pc, i) => {
    newPercComps.push({
      name: formState.inputs?.[`percCompNameEqpCost${i}`]?.value as string,
      value: +formState.inputs?.[`percCompValueEqpCost${i}`]?.value || 0,
      type: pc.type,
      isRevenue: pc.isRevenue,
    });
  });

  const kitRevPercs =
    percComps?.filter((item) => {
      return item.type === "PERCENTAGE_KIT" && item.isRevenue;
    }) || [];

  kitRevPercs.forEach((pc, i) => {
    newPercComps.push({
      name: formState.inputs?.[`percCompNameEqpRev${i}`]?.value as string,
      value: +formState.inputs?.[`percCompValueEqpRev${i}`]?.value || 0,
      type: pc.type,
      isRevenue: pc.isRevenue,
    });
  });

  const serviceAndKitCostPercs =
    percComps?.filter((item) => {
      return (
        (item.type === "PERCENTAGE_SERVICE_AND_KIT" ||
          item.type === "COMMISSION" ||
          item.type === "INDIVIDUAL_COMMISSION") &&
        !item.isRevenue
      );
    }) || [];

  serviceAndKitCostPercs.forEach((pc, i) => {
    newPercComps.push({
      name: formState.inputs?.[`percCompNameTotalCost${i}`]?.value as string,
      value: +formState.inputs?.[`percCompValueTotalCost${i}`]?.value || 0,
      type: pc.type,
      isRevenue: pc.isRevenue,
    });
  });

  const serviceAndKitRevPercs =
    percComps?.filter((item) => {
      return item.type === "PERCENTAGE_SERVICE_AND_KIT" && item.isRevenue;
    }) || [];

  serviceAndKitRevPercs.forEach((pc, i) => {
    newPercComps.push({
      name: formState.inputs?.[`percCompNameTotalRev${i}`]?.value as string,
      value: +formState.inputs?.[`percCompValueTotalRev${i}`]?.value || 0,
      type: pc.type,
      isRevenue: pc.isRevenue,
    });
  });

  const profitCostPercs =
    percComps?.filter((item) => {
      return item.type === "PERCENTAGE_PROFIT" && !item.isRevenue;
    }) || [];

  profitCostPercs.forEach((pc, i) => {
    newPercComps.push({
      name: formState.inputs?.[`percCompNameProfitCost${i}`]?.value as string,
      value: +formState.inputs?.[`percCompValueProfitCost${i}`]?.value || 0,
      type: pc.type,
      isRevenue: pc.isRevenue,
    });
  });

  const profitRevPercs =
    percComps?.filter((item) => {
      return item.type === "PERCENTAGE_PROFIT" && item.isRevenue;
    }) || [];

  profitRevPercs.forEach((pc, i) => {
    newPercComps.push({
      name: formState.inputs?.[`percCompNameProfitRev${i}`]?.value as string,
      value: +formState.inputs?.[`percCompValueProfitRev${i}`]?.value || 0,
      type: pc.type,
      isRevenue: pc.isRevenue,
    });
  });

  let financing: ProposalData["revenueData"]["financing"] = [];
  for (let i = 0; i < financingQtd; i++) {
    financing.push({
      bank: (formState.inputs?.[`bank${i}`]?.value || "") as string,
      insurance: (formState.inputs?.[`insurance${i}`]?.value ||
        false) as boolean,
      gracePeriod: +formState.inputs?.[`gracePeriod${i}`]?.value || 0,
      entry: +formState.inputs?.[`entry${i}`]?.value || 0,
      entryInstallments:
        +formState.inputs?.[`entryInstallments${i}`]?.value || 0,
      finOptId: proposalFinOptsIds?.[i] || "",
      options: [],
    });

    for (let j = 0; j < optionsQtds[i]; j++) {
      if (
        +formState.inputs?.[`installment${i}${j}`]?.value > 0 &&
        +formState.inputs?.[`value${i}${j}`]?.value > 0 &&
        financing?.[i]?.options
      ) {
        financing?.[i]?.options?.push({
          installments: +formState.inputs?.[`installment${i}${j}`]?.value || 0,
          value: +formState.inputs?.[`value${i}${j}`]?.value || 0,
          insuranceValue:
            +formState.inputs?.[`insuranceValue${i}${j}`]?.value || 0,
        });
      }
    }
  }

  return JSON.stringify({
    revenueData: {
      pvKitCost: +formState.inputs.pvKitCost.value || 0,
      servicePricePerkWp: +formState.inputs.servicePricePerkWp?.value || 0,
      serviceCosts: serviceCosts,
      percentageComponents: newPercComps,
      nfValue: +formState.inputs.nfValue?.value || 0,
      taxes: +formState.inputs.taxes?.value || 0,
      inflation: +formState.inputs.inflation?.value || 10,
      marginEquip: +formState.inputs.marginEquip?.value || 0,
      margin: +formState.inputs.margin?.value || 20,
      paymentConditions: formState.inputs.paymentConditions?.value || "",
      equipamentProfit: +formState.inputs.equipamentProfit?.value || 0,
      tradingMargin: +formState.inputs.tradingMargin?.value || 0,
      totalEqp:
        +formState.inputs.totalEqp?.value.toString().replace(/\D/g, "") / 100 ||
        0,
      totalSvc:
        +formState.inputs.totalSvc?.value.toString().replace(/\D/g, "") / 100 ||
        0,
      totalPerKwp:
        +formState.inputs.totalPerKwp?.value.toString().replace(/\D/g, "") /
          100 || 0,
      profit:
        +formState.inputs.profit?.value.toString().replace(/\D/g, "") / 100 ||
        0,
      profitPerKwp:
        +formState.inputs.profitPerKwp?.value.toString().replace(/\D/g, "") /
          100 || 0,
      finalPrice:
        +formState.inputs.finalPrice?.value.toString().replace(/\D/g, "") /
          100 || 0,
    },
    financing: financing,
  });
};

export const setSimCashFlowData = (loadedResults: SimulationResultsData) => {
  if (loadedResults?.cashFlow) {
    return [
      {
        year: "1",
        flow: Math.round(loadedResults.cashFlow[0]),
      },
      {
        year: "2",
        flow: Math.round(loadedResults.cashFlow[1]),
      },
      {
        year: "3",
        flow: Math.round(loadedResults.cashFlow[2]),
      },
      {
        year: "4",
        flow: Math.round(loadedResults.cashFlow[3]),
      },
      {
        year: "5",
        flow: Math.round(loadedResults.cashFlow[4]),
      },
      {
        year: "6",
        flow: Math.round(loadedResults.cashFlow[5]),
      },
      {
        year: "7",
        flow: Math.round(loadedResults.cashFlow[6]),
      },
      {
        year: "8",
        flow: Math.round(loadedResults.cashFlow[7]),
      },
      {
        year: "9",
        flow: Math.round(loadedResults.cashFlow[8]),
      },
      {
        year: "10",
        flow: Math.round(loadedResults.cashFlow[9]),
      },
      {
        year: "11",
        flow: Math.round(loadedResults.cashFlow[10]),
      },
      {
        year: "12",
        flow: Math.round(loadedResults.cashFlow[11]),
      },
      {
        year: "13",
        flow: Math.round(loadedResults.cashFlow[12]),
      },
      {
        year: "14",
        flow: Math.round(loadedResults.cashFlow[13]),
      },
      {
        year: "15",
        flow: Math.round(loadedResults.cashFlow[14]),
      },
      {
        year: "16",
        flow: Math.round(loadedResults.cashFlow[15]),
      },
      {
        year: "17",
        flow: Math.round(loadedResults.cashFlow[16]),
      },
      {
        year: "18",
        flow: Math.round(loadedResults.cashFlow[17]),
      },
      {
        year: "19",
        flow: Math.round(loadedResults.cashFlow[18]),
      },
      {
        year: "20",
        flow: Math.round(loadedResults.cashFlow[19]),
      },
      {
        year: "21",
        flow: Math.round(loadedResults.cashFlow[20]),
      },
      {
        year: "22",
        flow: Math.round(loadedResults.cashFlow[21]),
      },
      {
        year: "23",
        flow: Math.round(loadedResults.cashFlow[22]),
      },
      {
        year: "24",
        flow: Math.round(loadedResults.cashFlow[23]),
      },
      {
        year: "25",
        flow: Math.round(loadedResults.cashFlow[24]),
      },
    ];
  } else {
    return [];
  }
};

export const setSimCashFlowMobileData = (
  loadedResults: SimulationResultsData
) => {
  if (loadedResults?.cashFlow) {
    return [
      {
        year: "1",
        flow: Math.round(loadedResults.cashFlow[0]),
      },
      {
        year: "3",
        flow: Math.round(loadedResults.cashFlow[2]),
      },
      {
        year: "5",
        flow: Math.round(loadedResults.cashFlow[4]),
      },
      {
        year: "7",
        flow: Math.round(loadedResults.cashFlow[6]),
      },
      {
        year: "9",
        flow: Math.round(loadedResults.cashFlow[8]),
      },
      {
        year: "11",
        flow: Math.round(loadedResults.cashFlow[10]),
      },
      {
        year: "13",
        flow: Math.round(loadedResults.cashFlow[12]),
      },
      {
        year: "15",
        flow: Math.round(loadedResults.cashFlow[14]),
      },
      {
        year: "17",
        flow: Math.round(loadedResults.cashFlow[16]),
      },
      {
        year: "19",
        flow: Math.round(loadedResults.cashFlow[18]),
      },
      {
        year: "21",
        flow: Math.round(loadedResults.cashFlow[20]),
      },
      {
        year: "23",
        flow: Math.round(loadedResults.cashFlow[22]),
      },
      {
        year: "25",
        flow: Math.round(loadedResults.cashFlow[24]),
      },
    ];
  } else {
    return [];
  }
};
