import { NotificationData, AuthContextProps } from "../../shared/data/types";

interface FetchProps {
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean
  ) => Promise<any>;
  auth: AuthContextProps;
}

interface GetLastPublicNotificationProps extends FetchProps {
  setLastPublicNotification: React.Dispatch<
    React.SetStateAction<NotificationData>
  >;
}

export const getLastPublicNotification = async (
  props: GetLastPublicNotificationProps
) => {
  const { auth, sendRequest, setLastPublicNotification } = props;
  let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/notifications/last-public/${auth.userId}`;

  try {
    const responseData: { lastPublicNotification: NotificationData } =
      await sendRequest(apiUrl, "GET", null, {
        Authorization: "Bearer " + auth.token,
      });

    if (responseData?.lastPublicNotification) {
      setLastPublicNotification(responseData.lastPublicNotification);
    }
  } catch (err) {}
};
