import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { ModalConfirmStyles } from "./ModalConfirmStyles";

interface ModalInvalidProps {
  open: boolean;
  closeHandler: () => void;
  finForm?: boolean;
  creditCardCustomForm?: boolean;
  message: JSX.Element[] | JSX.Element | string | string[];
}

export const ModalInvalid = (props: ModalInvalidProps) => {
  const { open, closeHandler, finForm, creditCardCustomForm, message } = props;
  const classes = ModalConfirmStyles();

  return (
    <div>
      <Dialog open={open} onClose={closeHandler}>
        <DialogTitle className={classes.confirmModalTitle}>
          O QUE HÁ DE ERRADO?
        </DialogTitle>
        {!finForm && !creditCardCustomForm && (
          <DialogContent>
            <ul>{message}</ul>
          </DialogContent>
        )}
        {finForm && <DialogContent>{message}</DialogContent>}
        {creditCardCustomForm && (
          <DialogContent>
            <p>{message}</p>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={closeHandler} color="primary">
            Entendi
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
