import { Fragment, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import Collapse from "@material-ui/core/Collapse";
import PersonRoundedIcon from "@material-ui/icons/PersonRounded";

import { useHttpClient } from "../../shared/hooks/httpHook";
import { LoadingSpinnerOverlayRegular } from "../../shared/components/UIElements/LoadingSpinnerOverlayRegular";
import { HistoryData, AuthContextProps } from "../../shared/data/types";

import "./SaleDashHistoryItem.scss";

interface SaleDashHistoryItemProps {
  auth: AuthContextProps;
  index: number;
  loadedHistory: HistoryData;
  allHistory: HistoryData[];
  setLoadedData: React.Dispatch<React.SetStateAction<HistoryData[]>>;
}

export const SaleDashHistoryItem = (props: SaleDashHistoryItemProps) => {
  const { allHistory, loadedHistory, auth, setLoadedData, index } = props;
  const { sendRequest, isLoading } = useHttpClient();
  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>(null);
  const showDateDivider =
    !allHistory?.[index - 1] ||
    allHistory?.[index - 1]?.registryDate?.substring(0, 10) !==
      loadedHistory?.registryDate?.substring(0, 10);

  return (
    <Fragment>
      {showDateDivider && (
        <p className="history-date-divider">
          {loadedHistory?.registryDate?.substring(0, 10) || ""}
        </p>
      )}
      <div
        className="sale-prop-hist-item"
        onClick={(e) => {
          if (!anchorEl) {
            setOpen(!open);
          }
        }}
      >
        {isLoading && <LoadingSpinnerOverlayRegular image color="primary" />}
        <div className="sale-prop-hist-item__data">
          <p className="sale-prop-hist-item__data-item sale-prop-hist-item__data-item--thin">
            {loadedHistory.title}
          </p>
          <div className="sale-prop-hist-item__user-data">
            <PersonRoundedIcon />
            <div className="sale-prop-hist-item__user-data-content">
              <p className="sale-prop-hist-item__data-item">
                {loadedHistory.user?.personalData?.name ||
                  loadedHistory?.userName}
              </p>
              <p className="sale-prop-hist-item__data-item">
                {loadedHistory.user?.email || loadedHistory?.userEmail}
              </p>
            </div>
          </div>
        </div>
        <div className="sale-prop-hist-item__controls">
          <IconButton
            size="small"
            title={open ? "Ver Mais" : "Fechar"}
            className={`table__item-action${open ? "--open" : ""}`}
            onClick={(e) => {
              if (!anchorEl) {
                setOpen(!open);
                e.stopPropagation();
              }
            }}
          >
            <ExpandMoreRoundedIcon style={{ fontSize: "22px" }} />
          </IconButton>
        </div>
        <div className="sale-prop-hist-item__details">
          <Collapse in={open}>
            <div className="sale-prop-hist-item__details-content">
              <p className="sale-prop-hist-item__data-item sale-prop-hist-item__data-item--thin">
                {loadedHistory?.content}
              </p>
              <p className="sale-prop-hist-item__data-item sale-prop-hist-item__data-item--thin">
                Registro: <span>{loadedHistory?.registryDate || ""}</span>
              </p>
            </div>
          </Collapse>
        </div>
      </div>
    </Fragment>
  );
};
