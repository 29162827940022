import { useEffect, Fragment } from "react";

import { useHttpClient } from "../../shared/hooks/httpHook";
import { useForm } from "../../shared/hooks/formHook";
import { Input } from "../../shared/components/FormElements/Input";
import { ModalError } from "../../shared/components/UIElements/ModalError";
import { LoadingSpinnerOverlayRegular } from "../../shared/components/UIElements/LoadingSpinnerOverlayRegular";
import { answerChecklistItem } from "../api/checklistsAPI";
import {
  AuthContextProps,
  ChecklistData,
  KanbanFilledData,
} from "../../shared/data/types";
import { VALIDATOR_REQUIRE } from "../../shared/util/validators";

import "./SaleDash.scss";

interface SaleDashChecklistTextProps {
  auth: AuthContextProps;
  saleId: string;
  kanbanChecklistId: string;
  kanbanChecklistItem: ChecklistData["items"][0];
  saleChecklistItem?: ChecklistData["items"][0];
  setLoadedSaleChecklist: React.Dispatch<React.SetStateAction<ChecklistData>>;
  index: number;
  type:
    | "SALES"
    | "PROJECT"
    | "INSTALLATION"
    | "AFTER_SALES"
    | "PRE_SALES"
    | "ADMINISTRATIVE";
  setLoadedData: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
}

export const SaleDashChecklistText = (props: SaleDashChecklistTextProps) => {
  const {
    auth,
    kanbanChecklistId,
    saleId,
    kanbanChecklistItem,
    saleChecklistItem,
    setLoadedSaleChecklist,
    index,
    setLoadedData,
  } = props;
  const { error, sendRequest, clearError, isLoading } = useHttpClient();
  const { formState, inputHandler } = useForm(
    {
      [`answer${index}`]: {
        value: saleChecklistItem?.answerString,
        isValid: !!saleChecklistItem?.answerString,
      },
    },
    false
  );

  const onBlurHandler = () => {
    if (
      !!formState?.inputs?.[`answer${index}`]?.value &&
      saleChecklistItem?.answerString?.trim() !==
        formState?.inputs?.[`answer${index}`]?.value?.toString()?.trim() &&
      formState?.inputs?.[`answer${index}`]?.isValid
    ) {
      answerChecklistItem({
        sendRequest,
        auth,
        setLoadedSaleChecklist,
        saleId,
        checklistId: kanbanChecklistId,
        itemId: kanbanChecklistItem?._id,
        answer: formState?.inputs?.[`answer${index}`]?.value as string,
        setLoadedData,
      });
    }
  };

  // useEffect(() => {

  // }, [formState?.inputs?.[`answer${index}`]?.value]);

  return (
    <Fragment>
      <ModalError error={error} onClear={clearError} />
      <div className="sale-dash__checklist-field">
        {isLoading && <LoadingSpinnerOverlayRegular />}
        <p className="sale-dash__checklist-task">{kanbanChecklistItem?.task}</p>
        <Input
          size="small"
          isMessage
          id={`answer${index}`}
          type="text"
          label=""
          variant="outlined"
          validators={[VALIDATOR_REQUIRE()]}
          helperText=""
          initialValue={saleChecklistItem?.answerString || ""}
          initialValid={!!saleChecklistItem?.answerString}
          onInput={inputHandler}
          forceError={formState?.inputs?.[`answer${index}`]?.forceError}
          reinitialize
          onBlurHandler={onBlurHandler}
        />
      </div>
    </Fragment>
  );
};
