import { mobileCheck } from "../util/mobileCheck";

export const openWhatsapp = (number: number | string, message = "") => {
  let apilink = "http://";
  const isMobile = mobileCheck();
  const phone = `55${number}`;
  apilink += isMobile ? "api" : "web";
  apilink +=
    ".whatsapp.com/send?phone=" + phone + "&text=" + encodeURI(message);
  window.open(apilink);
};
