import { formatPhoneNumber } from "../../shared/util/formatPhoneNumber";
import {
  AuthContextProps,
  FormHookDispState,
  SignatureData,
} from "../../shared/data/types";

export interface FetchProps {
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean
  ) => Promise<any>;
  auth: AuthContextProps;
}

// GET SIGNATURE ///////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
interface GetSinatureProps extends FetchProps {
  saleId: string;
  saleContractId: string;
  setLoadedSignature: React.Dispatch<React.SetStateAction<SignatureData>>;
}

export const getSinature = async (props: GetSinatureProps) => {
  const { saleId, saleContractId, sendRequest, auth, setLoadedSignature } =
    props;

  let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/signatures/single/${saleId}/${saleContractId}`;
  try {
    const responseData: { signature: SignatureData } = await sendRequest(
      apiUrl,
      "GET",
      null,
      {
        Authorization: "Bearer " + auth.token,
      }
    );

    if (responseData.signature) {
      setLoadedSignature(responseData.signature);
      return responseData.signature;
    } else {
      return null;
    }
  } catch (err) {}
};

// CREATE SIGNATURE ////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
interface CreateSinatureProps extends FetchProps {
  saleId: string;
  saleContractId: string;
  setLoadedSignature: React.Dispatch<React.SetStateAction<SignatureData>>;
}

export const createSignature = async (props: CreateSinatureProps) => {
  const { saleId, saleContractId, sendRequest, auth, setLoadedSignature } =
    props;

  let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/signatures/single/${saleId}/${saleContractId}`;
  try {
    const responseData: { signature: SignatureData } = await sendRequest(
      apiUrl,
      "POST",
      null,
      {
        Authorization: "Bearer " + auth.token,
      }
    );

    setLoadedSignature(responseData.signature);
    return responseData.signature;
  } catch (err) {}
};

// ADD PERSON TO SIGNATURE /////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
interface AddPersonToSignatureProps extends FetchProps {
  saleId: string;
  saleContractId: string;
  setLoadedSignature: React.Dispatch<React.SetStateAction<SignatureData>>;
  formState: FormHookDispState;
}

export const addPersonToSignature = async (
  props: AddPersonToSignatureProps
) => {
  const {
    saleId,
    saleContractId,
    sendRequest,
    auth,
    setLoadedSignature,
    formState,
  } = props;

  let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/signatures/single/person/${saleId}/${saleContractId}`;
  try {
    const responseData: { signature: SignatureData } = await sendRequest(
      apiUrl,
      "POST",
      JSON.stringify({
        name: formState.inputs?.name?.value,
        surname: formState.inputs?.surname?.value,
        email: formState.inputs?.email?.value,
        document: formState.inputs?.document?.value
          ?.toString()
          ?.replace(/\D/g, ""),
        phone: formatPhoneNumber(
          formState.inputs?.phone?.value?.toString() || ""
        ),
        auth: formState.inputs?.authType?.value
          ?.toString()
          ?.toLowerCase()
          ?.replace("-", ""),
      }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      }
    );

    setLoadedSignature(responseData.signature);
  } catch (err) {}
};

// EDIT PERSON TO SIGNATURE ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
interface EditPersonToSignatureProps extends FetchProps {
  saleId: string;
  saleContractId: string;
  personId: string;
  setLoadedSignature: React.Dispatch<React.SetStateAction<SignatureData>>;
  formState: FormHookDispState;
}

export const editPersonToSignature = async (
  props: EditPersonToSignatureProps
) => {
  const {
    saleId,
    saleContractId,
    personId,
    sendRequest,
    auth,
    setLoadedSignature,
    formState,
  } = props;

  let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/signatures/single/person/${saleId}/${saleContractId}/${personId}`;
  try {
    const responseData: { signature: SignatureData } = await sendRequest(
      apiUrl,
      "PUT",
      JSON.stringify({
        name: formState.inputs?.name?.value,
        surname: formState.inputs?.surname?.value,
        email: formState.inputs?.email?.value,
        document: formState.inputs?.document?.value
          ?.toString()
          ?.replace(/\D/g, ""),
        phone: formatPhoneNumber(
          formState.inputs?.phone?.value?.toString() || ""
        ),
        auth: formState.inputs?.authType?.value
          ?.toString()
          ?.toLowerCase()
          ?.replace("-", ""),
      }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      }
    );

    setLoadedSignature(responseData.signature);
  } catch (err) {}
};

// DELETE PERSON FROM SIGNATURE ////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
interface DeletePersonFromSignatureProps extends FetchProps {
  saleId: string;
  saleContractId: string;
  personId: string;
  setLoadedSignature: React.Dispatch<React.SetStateAction<SignatureData>>;
}

export const deletePersonFromSignature = async (
  props: DeletePersonFromSignatureProps
) => {
  const {
    saleId,
    saleContractId,
    personId,
    sendRequest,
    auth,
    setLoadedSignature,
  } = props;

  let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/signatures/single/person/${saleId}/${saleContractId}/${personId}`;
  try {
    const responseData: { signature: SignatureData } = await sendRequest(
      apiUrl,
      "DELETE",
      null,
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      }
    );

    setLoadedSignature(responseData.signature);
  } catch (err) {}
};

// SIGN DOCUMENT ///////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
interface SignDocumentProps extends FetchProps {
  saleId: string;
  saleContractId: string;
  setLoadedSignature: React.Dispatch<React.SetStateAction<SignatureData>>;
  base64File: string;
  formState: FormHookDispState;
}

export const signDocument = async (props: SignDocumentProps) => {
  const {
    saleId,
    saleContractId,
    sendRequest,
    auth,
    setLoadedSignature,
    base64File,
    formState,
  } = props;

  let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/signatures/single/${saleId}/${saleContractId}`;
  try {
    const responseData: { signature: SignatureData } = await sendRequest(
      apiUrl,
      "PUT",
      JSON.stringify({
        base64File,
        validityDate: formState?.inputs?.validityDate?.value,
      }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
      true
    );

    setLoadedSignature(responseData.signature);
  } catch (err) {}
};

// LOAD DOCS FROM CLICKSIGN ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
interface LoadDocsFromClicksignProps extends FetchProps {
  saleId: string;
  saleContractId: string;
  setLoadedDownloadUrls: React.Dispatch<
    React.SetStateAction<{
      original_file_url: string;
      signed_file_url: string;
      ziped_file_url: string;
    }>
  >;
  setPersonsWhoSigned: React.Dispatch<React.SetStateAction<string[]>>;
}

export const loadDocsFromClicksign = async (
  props: LoadDocsFromClicksignProps
) => {
  const {
    saleId,
    saleContractId,
    sendRequest,
    auth,
    setLoadedDownloadUrls,
    setPersonsWhoSigned,
  } = props;

  let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/signatures/single/signed/${saleId}/${saleContractId}`;
  try {
    const responseData: {
      downloadUrls: {
        original_file_url: string;
        signed_file_url: string;
        ziped_file_url: string;
      };
      personsWhoSigned: string[];
    } = await sendRequest(apiUrl, "GET", null, {
      Authorization: "Bearer " + auth.token,
    });

    setLoadedDownloadUrls(responseData.downloadUrls);
    setPersonsWhoSigned(responseData.personsWhoSigned || []);
    return responseData.downloadUrls;
  } catch (err) {}
};

// NOTIFY SIGNER ///////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
interface NotifySignerProps extends FetchProps {
  saleId: string;
  saleContractId: string;
  personId: string;
  chosenMethod: "E-Mail" | "WhatsApp" | "SMS";
  setLoadedSignature: React.Dispatch<React.SetStateAction<SignatureData>>;
}

export const notifySigner = async (props: NotifySignerProps) => {
  const {
    saleId,
    saleContractId,
    personId,
    sendRequest,
    auth,
    chosenMethod,
    setLoadedSignature,
  } = props;

  let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/signatures/single/person/notification/${saleId}/${saleContractId}/${personId}`;
  try {
    const responseData: { signature: SignatureData } = await sendRequest(
      apiUrl,
      "POST",
      JSON.stringify({ type: chosenMethod }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
      true
    );

    setLoadedSignature(responseData.signature);
  } catch (err) {}
};

// GET AVAILABLE SIGNATURES ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
interface GetAvailableSignaturesProps extends FetchProps {
  setSingaturesTotals: React.Dispatch<
    React.SetStateAction<{
      totalSignatures: number;
      usedSignatures: number;
      validityDate: string;
    }>
  >;
}

export const getAvailableSignatures = async (
  props: GetAvailableSignaturesProps
) => {
  const { sendRequest, auth, setSingaturesTotals } = props;

  let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/managers/single/signatures`;
  try {
    const responseData: {
      totalSignatures: number;
      usedSignatures: number;
      validityDate: string;
    } = await sendRequest(apiUrl, "GET", null, {
      Authorization: "Bearer " + auth.token,
    });

    setSingaturesTotals(responseData);
  } catch (err) {}
};
