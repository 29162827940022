export const getMonthNameAndYear = (monthsPastOrFuture: number = 0) => {
  const monthNamesArray = [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
  ];

  const thisMonth = new Date().getMonth();
  let selectedYear = new Date().getFullYear();
  let selectedMonth = thisMonth + monthsPastOrFuture;

  if (selectedMonth < 0) {
    selectedMonth += 12;
    selectedYear -= 1;
  }

  if (selectedMonth > 11) {
    selectedMonth -= 12;
    selectedYear += 1;
  }

  return `${monthNamesArray[selectedMonth]}/${selectedYear}`;
};
