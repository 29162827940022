import {
  AuthContextProps,
  ChecklistData,
  KanbanFilledData,
} from "../../shared/data/types";

export interface FetchProps {
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean,
    zeroResultsKey?: string
  ) => Promise<any>;
  auth: AuthContextProps;
}

// GET SINGLE KANBAN CHECKLIST /////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
interface GetSingleKanbanChecklistProps extends FetchProps {
  type:
    | "SALES"
    | "PROJECT"
    | "INSTALLATION"
    | "AFTER_SALES"
    | "PRE_SALES"
    | "ADMINISTRATIVE";
  setLoadedKanbanChecklist: React.Dispatch<React.SetStateAction<ChecklistData>>;
}

export const getSingleKanbanChecklist = async (
  props: GetSingleKanbanChecklistProps
) => {
  const { sendRequest, auth, type, setLoadedKanbanChecklist } = props;

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/checklists/single/kanban/${type}`;
    const responseData: { checklist: ChecklistData } = await sendRequest(
      apiUrl,
      "GET",
      null,
      {
        Authorization: "Bearer " + auth.token,
      }
    );

    setLoadedKanbanChecklist(responseData.checklist);
  } catch (err) {}
};

// GET SINGLE SALE CHECKLIST ///////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
interface GetSingleSaleChecklistProps extends FetchProps {
  saleId: string;
  setLoadedSaleChecklist: React.Dispatch<React.SetStateAction<ChecklistData>>;
}

export const getSingleSaleChecklist = async (
  props: GetSingleSaleChecklistProps
) => {
  const { sendRequest, auth, saleId, setLoadedSaleChecklist } = props;

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/checklists/single/sale/${saleId}`;
    const responseData: { checklist: ChecklistData } = await sendRequest(
      apiUrl,
      "GET",
      null,
      {
        Authorization: "Bearer " + auth.token,
      }
    );

    setLoadedSaleChecklist(responseData.checklist);
  } catch (err) {}
};

// ANSWER CHECKLIST ITEM ///////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
interface AnswerChecklistItemProps extends FetchProps {
  checklistId: string;
  itemId: string;
  saleId: string;
  setLoadedSaleChecklist: React.Dispatch<React.SetStateAction<ChecklistData>>;
  answer: boolean | string | number;
  setLoadedData: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
}

export const answerChecklistItem = async (props: AnswerChecklistItemProps) => {
  const {
    sendRequest,
    auth,
    saleId,
    setLoadedSaleChecklist,
    answer,
    checklistId,
    itemId,
    setLoadedData,
  } = props;

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/checklists/single/item/${checklistId}/${itemId}/${saleId}`;
    const responseData: { checklist: ChecklistData } = await sendRequest(
      apiUrl,
      "POST",
      JSON.stringify({ answer }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      }
    );

    setLoadedSaleChecklist(responseData.checklist);
    setLoadedData((prevValues) => {
      prevValues.items = prevValues?.items?.map((item) => {
        if (item._id === saleId) {
          item.checklist = responseData.checklist;
        }

        return item;
      });

      return prevValues;
    });
  } catch (err) {}
};
