import { DraggableLocation } from "react-beautiful-dnd";

import { moveFile, moveFolder } from "../api/archiveAPI";
import { AuthContextProps, ArchiveData } from "../../shared/data/types";

interface ProcessDragEndProps {
  destination: DraggableLocation;
  source: DraggableLocation;
  draggableId: string;
  type: string;
  auth: AuthContextProps;
  cid: string;
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean
  ) => Promise<any>;
  loadedArchive?: ArchiveData;
  setLoadedArchive?: React.Dispatch<React.SetStateAction<ArchiveData>>;
}

export const processDragEnd = async (props: ProcessDragEndProps) => {
  const {
    destination,
    source,
    draggableId,
    type,
    auth,
    cid,
    sendRequest,
    loadedArchive,
    setLoadedArchive,
  } = props;

  if (!destination) {
    return;
  }

  if (
    destination.droppableId === source.droppableId &&
    destination.index === source.index
  ) {
    return;
  }

  if (type === "FOLDER") {
    const foundFolder = loadedArchive.folders.find((folder) => {
      return folder._id === draggableId;
    });

    const oldOrder = foundFolder.order;
    const newOrder = destination.index;

    if (newOrder < oldOrder) {
      let foldersBetween =
        loadedArchive.folders.filter((folder) => {
          return folder.order >= newOrder && folder.order < oldOrder;
        }) || [];

      foldersBetween =
        foldersBetween.map((folder) => {
          folder.order += 1;
          return folder;
        }) || [];
    } else if (newOrder > oldOrder) {
      let foldersBetween =
        loadedArchive.folders.filter((folder) => {
          return folder.order <= newOrder && folder.order > oldOrder;
        }) || [];

      foldersBetween =
        foldersBetween.map((folder) => {
          folder.order -= 1;
          return folder;
        }) || [];
    }

    foundFolder.order = newOrder;

    await moveFolder({
      sendRequest,
      auth,
      folderId: draggableId,
      setLoadedArchive,
      order: destination.index,
      cid,
    });
    return;
  }

  if (type.includes("FILE")) {
    const foundFile = loadedArchive.files.find((file) => {
      return file.id === draggableId;
    });

    const oldFolder = foundFile.folder;
    const newFolder = destination.droppableId;

    const oldOrder = foundFile.order;
    const newOrder = destination.index;

    if (newFolder === oldFolder) {
      const folderFiles =
        loadedArchive.files.filter((file) => {
          return file.folder === oldFolder;
        }) || [];

      if (newOrder < oldOrder) {
        let filesBetween = folderFiles.filter((file) => {
          return file.order >= newOrder && file.order < oldOrder;
        });

        filesBetween = filesBetween.map((file) => {
          file.order += 1;
          return file;
        });
      } else if (newOrder > oldOrder) {
        let filesBetween = folderFiles.filter((file) => {
          return file.order <= newOrder && file.order > oldOrder;
        });

        filesBetween = filesBetween.map((file) => {
          file.order -= 1;
          return file;
        });
      }
    } else if (newFolder !== oldFolder) {
      let oldFolderFiles =
        loadedArchive.files.filter((file) => {
          return file.folder === oldFolder;
        }) || [];

      let newFolderFiles =
        loadedArchive.files.filter((file) => {
          return file.folder === newFolder;
        }) || [];

      let higherOrderOldFolderFiles = oldFolderFiles?.filter((file) => {
        return file.order > oldOrder;
      });

      higherOrderOldFolderFiles = higherOrderOldFolderFiles?.map((file) => {
        file.order -= 1;
        return file;
      });

      let higherOrderNewFolderFiles = newFolderFiles?.filter((file) => {
        return file.order >= newOrder;
      });

      higherOrderNewFolderFiles = higherOrderNewFolderFiles?.map((file) => {
        file.order += 1;
        return file;
      });
    }

    foundFile.order = newOrder;
    foundFile.folder = newFolder;

    await moveFile({
      sendRequest,
      auth,
      folder: newFolder,
      fileId: draggableId,
      order: destination.index,
      cid,
      setLoadedArchive,
    });

    return;
  }
};
