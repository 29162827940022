import { Fragment, useRef, useState } from "react";
import Button from "@material-ui/core/Button";
import { useIntersection } from "react-use";
import CircularProgress from "@material-ui/core/CircularProgress";

import "./ButtonLoadMoreItemsStyles.css";

interface ButtonLoadMoreProps {
  setMultiplier: React.Dispatch<React.SetStateAction<number>>;
  showLoadMore: boolean;
  isLoading: boolean;
  fetchItems: (multiplier: number) => Promise<void>;
  small?: boolean;
  noMargin?: boolean;
}

export const ButtonLoadMore = (props: ButtonLoadMoreProps) => {
  const {
    setMultiplier,
    showLoadMore,
    isLoading,
    fetchItems,
    small,
    noMargin,
  } = props;
  const [triggered, setTriggered] = useState<boolean>(false);
  const sectionRef = useRef(null);

  const intersection = useIntersection(sectionRef, {
    root: null,
    rootMargin: "0px",
    threshold: 0.8,
  });

  const trigger = () => {
    if (!triggered && !isLoading && showLoadMore) {
      setTriggered(true);

      setMultiplier((prevValues) => {
        fetchItems(prevValues + 1)?.then(() => {
          setTimeout(() => {
            setTriggered(false);
          }, 1000);
        });

        return (prevValues += 1);
      });
    }
  };

  if (intersection && intersection.intersectionRatio > 0.8 && showLoadMore) {
    trigger();
  }

  return (
    <Fragment>
      <div
        ref={sectionRef}
        // style={noMargin ? { margin: "15px 0 15px" } : {}}
        // className={`button-load-more-items-root ${
        //   small ? "button-load-more-items-root--small" : ""
        // }`}
      >
        {showLoadMore && (
          <Fragment>
            {!isLoading && <div style={{ height: "70px" }}></div>}
            {/* {!isLoading && (
            <Button
              onClick={() => {
                setMultiplier((prevValues) => {
                  fetchItems(prevValues + 1);
                  return (prevValues += 1);
                });
              }}
              variant="outlined"
              color="primary"
              size={!small ? "large" : "small"}
            >
              Carregar Mais
            </Button>
          )} */}
            {isLoading && (
              <div
                style={{
                  padding: "20px",
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CircularProgress size={30} color="primary" />
              </div>
            )}
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};
