import { Fragment, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import DeleteForeverRoundedIcon from "@material-ui/icons/DeleteForeverRounded";
import Collapse from "@material-ui/core/Collapse";

import { ModalError } from "../../shared/components/UIElements/ModalError";
import { ModalConfirm } from "../../shared/components/UIElements/ModalConfirm";
import { useHttpClient } from "../../shared/hooks/httpHook";
import { LoadingSpinnerOverlayRegular } from "../../shared/components/UIElements/LoadingSpinnerOverlayRegular";
import { addThousandSeparator } from "../../shared/util/addThousandSeparator";
import { deleteService } from "../api/salesFunnelAPI";
import {
  ProposalData,
  AuthContextProps,
  KanbanFilledData,
} from "../../shared/data/types";

import "./SaleDashProposalItem.scss";

interface SaleDashServiceItemProps {
  auth: AuthContextProps;
  sale: KanbanFilledData["items"][0];
  proposal: ProposalData;
  service: KanbanFilledData["items"][0]["afterSalesData"]["services"][0];
  setLoadedData: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
}

export const SaleDashServiceItem = (props: SaleDashServiceItemProps) => {
  const { proposal, auth, setLoadedData, service, sale } = props;
  const { sendRequest, isLoading, error, clearError } = useHttpClient();
  const [open, setOpen] = useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

  const closeConfirmHandler = () => {
    setConfirmDelete(false);
  };

  const confirmHandler = () => {
    deleteService({
      sendRequest,
      auth,
      serviceId: service?._id,
      saleId: sale?._id,
      setKanbanData: setLoadedData,
    });
    setConfirmDelete(false);
  };

  return (
    <div>
      <ModalConfirm
        open={confirmDelete}
        closeHandler={closeConfirmHandler}
        confirmHandler={confirmHandler}
        message="Deseja excluir permanentemente este arquivo?"
      />
      <div
        className={`sale-prop-item`}
        onClick={(e) => {
          if (!confirmDelete) {
            e.stopPropagation();
            setOpen(!open);
          }
        }}
      >
        {isLoading && <LoadingSpinnerOverlayRegular image color="primary" />}
        <div className="sale-prop-item__data">
          <ModalError error={error} onClear={clearError} />
          <p className="sale-prop-item__data-item sale-prop-item__data-item--thin-less-margin">
            {service.type === "SUPPLY" ? `${service?.quantity || 1}x ` : ""}
            {service?.serviceName}
          </p>
          <p className="sale-prop-item__data-item sale-prop-item__data-item--thin">
            {!service.type || service?.type === "SERVICE"
              ? "Serviço"
              : "Produto"}
            {service.type === "SUPPLY"
              ? ` - ${service?.quantity || 1} Unidades`
              : ""}
          </p>
          <p
            title={`R$ ${addThousandSeparator(service?.price || 0)}`}
            className="sale-prop-item__data-item"
          >
            {service.type === "SUPPLY" ? `${service?.quantity || 1}x ` : ""}
            {`R$ ${addThousandSeparator(service?.price || 0)}`}
            {service.type === "SUPPLY"
              ? ` = R$ ${addThousandSeparator(
                  service?.price * (service?.quantity || 1) || 0
                )} `
              : ""}
          </p>
        </div>
        <div className="sale-prop-item__controls">
          <IconButton
            size="small"
            title={open ? "Ver Mais" : "Fechar"}
            className={`table__item-action${open ? "--open" : ""}`}
            onClick={(e) => {
              setOpen(!open);
              e.stopPropagation();
            }}
          >
            <ExpandMoreRoundedIcon style={{ fontSize: "22px" }} />
          </IconButton>
          <IconButton
            size="small"
            title="Excluir"
            onClick={(e) => {
              setConfirmDelete(true);
              e.stopPropagation();
            }}
          >
            <DeleteForeverRoundedIcon style={{ fontSize: "22px" }} />
          </IconButton>
        </div>
        <div className="sale-prop-item__details">
          <Collapse in={open}>
            <div className="sale-prop-item__details-content">
              {service?.priceComps?.map((pc) => {
                return (
                  <p className="sale-prop-item__data-item-no-wrap sale-prop-item__data-item-no-wrap--thin">
                    {pc}
                  </p>
                );
              })}
            </div>
          </Collapse>
        </div>
      </div>
    </div>
  );
};
