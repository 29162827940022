import { useHistory } from "react-router-dom";

import { LoadingSpinnerFullScreenGear } from "../../../shared/components/UIElements/LoadingSpinnerFullScreenGear";

export default function WaitingPage() {
  const history = useHistory();
  setTimeout(() => {
    history.push("inicio");
  }, 1000);
  return <LoadingSpinnerFullScreenGear />;
}
