import { useReducer, useEffect } from "react";

import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";

import { SliderDefined } from "../MUIThemed/SliderDefined";
import { SliderHighlighted } from "../MUIThemed/SliderHighlighted";
import { InputDispState, InputDispAction } from "./Input";

import { regularSliderStyles } from "./InputSliderStyles";

const inputReducer = (state: InputDispState, action: InputDispAction) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.val,
        isValid: true,
      };
    default:
      return state;
  }
};

interface InputSliderProps {
  id: string;
  label: string;
  onInput: (id: string, value: string, isValid: boolean, label: string) => void;
  marks: { value: number; label: string }[];
  initialValue: number;
  disabled?: boolean;
  reinitialize?: boolean;
  minValue: number;
  maxValue: number;
  title: string;
  step: number;
  defined?: boolean;
  highlighted?: boolean;
  qtdInLabel?: boolean;
  extraInputHandler?: (value: string | number) => void;
  style?: {};
  sliderStyle?: {};
  hideBar?: boolean;
  marksInLabel?: boolean;
}

export const InputSlider = (props: InputSliderProps) => {
  const {
    id,
    label,
    onInput,
    marks,
    initialValue,
    disabled,
    reinitialize,
    minValue,
    maxValue,
    title,
    step,
    defined,
    highlighted,
    qtdInLabel,
    extraInputHandler,
    style = {},
    sliderStyle = {},
    hideBar,
    marksInLabel,
  } = props;
  const classes = regularSliderStyles();
  const [inputState, dispatch] = useReducer(inputReducer, {
    value: initialValue.toString(),
    isTouched: true,
    isValid: true,
  });
  const { value } = inputState;

  useEffect(() => {
    onInput(
      id,
      defined ? initialValue.toString() : value,
      true,
      label.charAt(0).toUpperCase() + label.slice(1)
    );
  }, [id, value, initialValue, label, onInput, defined]);

  useEffect(() => {
    if (reinitialize) {
      dispatch({
        type: "CHANGE",
        val: initialValue.toString(),
      });
    }
  }, [reinitialize, initialValue]);

  const changeHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: number
  ) => {
    dispatch({
      type: "CHANGE",
      val: value.toString(),
    });
  };

  return (
    <div className={classes.root} style={style}>
      <Typography gutterBottom>
        {label.charAt(0).toUpperCase() + label.slice(1)}:
        {qtdInLabel && <span className={classes.labelHighlight}> {value}</span>}
        {marksInLabel && (
          <span
            className={
              +value === 2
                ? classes.labelHighlightRed
                : +value === 1
                ? classes.labelHighlightOrange
                : classes.labelHighlight
            }
          >
            {" "}
            {marks?.find((m) => m?.value === +value)?.label}
          </span>
        )}
      </Typography>
      <div className="u-center">
        {!defined && !highlighted && (
          <Slider
            style={!hideBar ? sliderStyle : { display: "none" }}
            id={id}
            title={title}
            disabled={disabled}
            defaultValue={initialValue}
            min={minValue}
            max={maxValue}
            getAriaValueText={(value) => {
              return value?.toString();
            }}
            onChangeCommitted={() => {
              if (extraInputHandler) {
                extraInputHandler(value.toString());
              }
            }}
            valueLabelDisplay="auto"
            value={Number(value)}
            step={step}
            marks={marks}
            onChange={changeHandler}
          />
        )}
        {defined && !highlighted && (
          <SliderDefined
            style={!hideBar ? sliderStyle : { display: "none" }}
            id={id}
            title={title}
            disabled={disabled}
            defaultValue={initialValue}
            min={minValue}
            max={maxValue}
            getAriaValueText={(value) => {
              return Math.round(value)?.toString();
            }}
            valueLabelDisplay="auto"
            value={initialValue}
            step={step}
            marks={marks}
            onChange={changeHandler}
          />
        )}
        {highlighted && !defined && (
          <SliderHighlighted
            style={!hideBar ? sliderStyle : { display: "none" }}
            id={id}
            title={title}
            disabled={disabled}
            defaultValue={initialValue}
            min={minValue}
            max={maxValue}
            getAriaValueText={(value) => {
              return value.toString();
            }}
            value={Number(value)}
            step={step}
            marks={marks}
            onChange={changeHandler}
          />
        )}
      </div>
    </div>
  );
};
