import { useState, useCallback, useEffect } from "react";

import { SettingsContextData } from "../data/types";

export const useSettings = () => {
  const [origins, setOrigins] = useState<string[]>([]);
  const [salesLostReasons, setSalesLostReasons] = useState<string[]>([]);
  const [rejectReasons, setRejectReasons] = useState<string[]>([]);
  const [notesTypes, setNotesTypes] = useState<string[]>([]);
  const [companyPositions, setCompanyPositions] = useState<string[]>([]);
  const [stdFolders, setStdFolders] = useState<string[]>([]);
  const [disabledSuppliers, setDisabledSuppliers] = useState<string[]>([]);
  const [afterSalesServices, setAfterSalesServices] = useState<
    SettingsContextData["afterSalesServices"]
  >([]);
  const [autoPricingType, setAutoPricingType] =
    useState<SettingsContextData["autoPricingType"]>("SIMPLE");
  const [autoPricingCalc, setAutoPricingCalc] =
    useState<SettingsContextData["autoPricingCalc"]>("MARKUP");
  const [autoPricing, setAutoPricing] = useState<
    SettingsContextData["autoPricing"]
  >([]);
  const [autoPricingDetailed, setAutoPricingDetailed] =
    useState<SettingsContextData["autoPricingDetailed"]>(null);
  const [autoFinancing, setAutoFinancing] =
    useState<SettingsContextData["autoFinancing"]>(null);
  const [preferences, setPreferences] =
    useState<SettingsContextData["preferences"]>(null);
  const [permissions, setPermissions] =
    useState<SettingsContextData["permissions"]>(null);
  const [individualPermissions, setIndividualPermissions] =
    useState<SettingsContextData["individualPermissions"]>(null);
  const [suppliers, setSuppliers] =
    useState<SettingsContextData["suppliers"]>(null);
  const [teams, setTeams] = useState<SettingsContextData["teams"]>(null);
  const [proposalSlug, setProposalSlug] = useState<string>("");
  const [webhooks, setWebhooks] = useState<SettingsContextData["webhooks"]>(null);

  const updateOriginsHandler = useCallback((newOrigins: string[]) => {
    setOrigins(newOrigins);
    updateLocalStorageDataHandler(newOrigins, "origins");
  }, []);

  const updateSalesLostReasonsHandler = useCallback((newReasons: string[]) => {
    setSalesLostReasons(newReasons);
    updateLocalStorageDataHandler(newReasons, "salesLostReasons");
  }, []);

  const updateRejectReasonsHandler = useCallback((newReasons: string[]) => {
    setRejectReasons(newReasons);
    updateLocalStorageDataHandler(newReasons, "rejectReasons");
  }, []);

  const updateNotesTypesHandler = useCallback((newNotes: string[]) => {
    setNotesTypes(newNotes);
    updateLocalStorageDataHandler(newNotes, "notesTypes");
  }, []);

  const updateCompanyPositionsHandler = useCallback(
    (newPositions: string[]) => {
      setCompanyPositions(newPositions);
      updateLocalStorageDataHandler(newPositions, "companyPositions");
    },
    []
  );

  const updateStdFoldersHandler = useCallback((newFolders: string[]) => {
    setStdFolders(newFolders);
    updateLocalStorageDataHandler(newFolders, "stdFolders");
  }, []);

  const updateDisabledSuppliersHandler = useCallback((suppliers: string[]) => {
    setDisabledSuppliers(suppliers);
    updateLocalStorageDataHandler(suppliers, "disabledSuppliers");
  }, []);

  const updateAfterSalesServicesHandler = useCallback(
    (services: SettingsContextData["afterSalesServices"]) => {
      setAfterSalesServices(services);
      updateLocalStorageDataHandler(services, "afterSalesServices");
    },
    []
  );

  const updateAutoPricingTypeHandler = useCallback(
    (newAutoPricingType: SettingsContextData["autoPricingType"]) => {
      setAutoPricingType(newAutoPricingType);
      updateLocalStorageDataHandler(newAutoPricingType, "autoPricingType");
    },
    []
  );

  const updateAutoPricingCalcHandler = useCallback(
    (newAutoPricingCalc: SettingsContextData["autoPricingCalc"]) => {
      setAutoPricingCalc(newAutoPricingCalc);
      updateLocalStorageDataHandler(newAutoPricingCalc, "autoPricingCalc");
    },
    []
  );

  const updateAutoPricingHandler = useCallback(
    (newAutoPricing: SettingsContextData["autoPricing"]) => {
      setAutoPricing(newAutoPricing);
      updateLocalStorageDataHandler(newAutoPricing, "autoPricing");
    },
    []
  );

  const updateAutoPricingDetailedHandler = useCallback(
    (newAutoPricingDetailed: SettingsContextData["autoPricingDetailed"]) => {
      setAutoPricingDetailed(newAutoPricingDetailed);
      updateLocalStorageDataHandler(
        newAutoPricingDetailed,
        "autoPricingDetailed"
      );
    },
    []
  );

  const updateAutoFinancingHandler = useCallback(
    (newAutoFinancing: SettingsContextData["autoFinancing"]) => {
      setAutoFinancing(newAutoFinancing);
      updateLocalStorageDataHandler(newAutoFinancing, "autoFinancing");
    },
    []
  );

  const updatePreferencesHandler = useCallback(
    (newPreferences: SettingsContextData["preferences"]) => {
      setPreferences(newPreferences);
      updateLocalStorageDataHandler(newPreferences, "preferences");
    },
    []
  );

  const updatePermissionsHandler = useCallback(
    (newPermissions: SettingsContextData["permissions"]) => {
      setPermissions(newPermissions);
      updateLocalStorageDataHandler(newPermissions, "permissions");
    },
    []
  );

  const updateIndividualPermissionsHandler = useCallback(
    (newPermissions: SettingsContextData["individualPermissions"]) => {
      setIndividualPermissions(newPermissions);
      updateLocalStorageDataHandler(newPermissions, "individualPermissions");
    },
    []
  );

  const updateTeamsHandler = useCallback(
    (newTeams: SettingsContextData["teams"]) => {
      setTeams(newTeams);
      updateLocalStorageDataHandler(newTeams, "teams");
    },
    []
  );

  const updateSuppliersHandler = useCallback(
    (newSuppliers: SettingsContextData["suppliers"]) => {
      setSuppliers(newSuppliers);
      updateLocalStorageDataHandler(newSuppliers, "suppliers");
    },
    []
  );

  const updateWebhooksHandler = useCallback(
    (newWebhooks: SettingsContextData["webhooks"]) => {
      setWebhooks(newWebhooks);
      updateLocalStorageDataHandler(newWebhooks, "webhooks");
    },
    []
  );

  const updateProposalSlugHandler = useCallback((newSlug: string) => {
    setProposalSlug(newSlug);
    updateLocalStorageDataHandler(newSlug, "proposalSlug");
  }, []);

  const updateLocalStorageDataHandler = useCallback(
    (data: string | string[] | number | number[] | {}, key: string) => {
      const settingsData: SettingsContextData = JSON.parse(
        localStorage.getItem("settingsData")
      );

      if (settingsData) {
        localStorage.setItem(
          "settingsData",
          JSON.stringify({ ...settingsData, [key]: data })
        );
      } else {
        localStorage.setItem("settingsData", JSON.stringify({ [key]: data }));
      }
    },
    []
  );

  useEffect(() => {
    // Loading stored user id and token
    const storedData: SettingsContextData = JSON.parse(
      localStorage.getItem("settingsData")
    );

    if (storedData?.origins) {
      updateOriginsHandler(storedData?.origins);
    }

    if (storedData?.salesLostReasons) {
      updateSalesLostReasonsHandler(storedData?.salesLostReasons);
    }

    if (storedData?.rejectReasons) {
      updateRejectReasonsHandler(storedData?.rejectReasons);
    }

    if (storedData?.notesTypes) {
      updateNotesTypesHandler(storedData?.notesTypes);
    }

    if (storedData?.companyPositions) {
      updateCompanyPositionsHandler(storedData?.companyPositions);
    }

    if (storedData?.stdFolders) {
      updateStdFoldersHandler(storedData?.stdFolders);
    }

    if (storedData?.disabledSuppliers) {
      updateDisabledSuppliersHandler(storedData?.disabledSuppliers);
    }

    if (storedData?.afterSalesServices) {
      updateAfterSalesServicesHandler(storedData?.afterSalesServices);
    }

    if (storedData?.autoPricingType) {
      updateAutoPricingTypeHandler(storedData?.autoPricingType);
    }

    if (storedData?.autoPricingCalc) {
      updateAutoPricingCalcHandler(storedData?.autoPricingCalc);
    }

    if (storedData?.autoPricing) {
      updateAutoPricingHandler(storedData?.autoPricing);
    }

    if (storedData?.autoPricingDetailed) {
      updateAutoPricingDetailedHandler(storedData?.autoPricingDetailed);
    }

    if (storedData?.autoFinancing) {
      updateAutoFinancingHandler(storedData?.autoFinancing);
    }

    if (storedData?.preferences) {
      updatePreferencesHandler(storedData?.preferences);
    }

    if (storedData?.permissions) {
      updatePermissionsHandler(storedData?.permissions);
    }

    if (storedData?.individualPermissions) {
      updateIndividualPermissionsHandler(storedData?.individualPermissions);
    }

    if (storedData?.suppliers) {
      updateSuppliersHandler(storedData?.suppliers);
    }

    if (storedData?.teams) {
      updateTeamsHandler(storedData?.teams);
    }

    if (storedData?.webhooks) {
      updateWebhooksHandler(storedData?.webhooks);
    }

    if (storedData?.proposalSlug) {
      updateProposalSlugHandler(storedData?.proposalSlug);
    }
  }, []);

  return {
    origins,
    salesLostReasons,
    rejectReasons,
    notesTypes,
    companyPositions,
    stdFolders,
    disabledSuppliers,
    afterSalesServices,
    autoPricingType,
    autoPricingCalc,
    autoPricing,
    autoPricingDetailed,
    autoFinancing,
    preferences,
    permissions,
    individualPermissions,
    teams,
    suppliers,
    webhooks,
    proposalSlug,
    updateOriginsHandler,
    updateSalesLostReasonsHandler,
    updateRejectReasonsHandler,
    updateNotesTypesHandler,
    updateCompanyPositionsHandler,
    updateStdFoldersHandler,
    updateDisabledSuppliersHandler,
    updateAfterSalesServicesHandler,
    updateAutoPricingTypeHandler,
    updateAutoPricingCalcHandler,
    updateAutoPricingHandler,
    updateAutoPricingDetailedHandler,
    updateAutoFinancingHandler,
    updatePreferencesHandler,
    updatePermissionsHandler,
    updateIndividualPermissionsHandler,
    updateTeamsHandler,
    updateSuppliersHandler,
    updateWebhooksHandler,
    updateProposalSlugHandler,
  };
};
