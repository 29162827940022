import { addThousandSeparator } from "../../shared/util/addThousandSeparator";
import { formatPhoneNumber } from "../../shared/util/formatPhoneNumber";
import {
  ProposalData,
  SalePopCustData,
  UserData,
} from "../../shared/data/types";

export const getAfterSalesPropTagsValues = (
  sale: SalePopCustData,
  proposal: ProposalData,
  user: UserData,
  validityDate: string
) => {
  const modsQtd = proposal?.pvModuleData?.length
    ? proposal?.pvModuleData?.map((m) => m.quantity)?.reduce((a, b) => a + b)
    : sale?.afterSalesData?.modsQtd || 0;
  const invQtd = proposal?.inverterData?.length
    ? proposal?.inverterData?.map((i) => i.iQuantity)?.reduce((a, b) => a + b)
    : sale?.afterSalesData?.invQtd || 0;
  const distance =
    proposal?.realSystemData?.distance || sale?.afterSalesData?.distance || 0;
  const roofHight = sale?.afterSalesData?.roofHight || 0;
  const inverterType =
    proposal?.inverterData?.[0]?.type !== "microinverter" ? "String" : "Micro";

  let tagsValues: { [key: string]: any } = {
    nome_cliente: sale?.customer?.name || ``,
    uf_cliente: sale?.customer?.uf || ``,
    cidade_cliente: sale?.customer?.city || ``,
    email_cliente: sale?.customer?.email || ``,
    telefone_cliente: formatPhoneNumber(sale?.customer?.phone || "") || ``,
    whatsapp_cliente: formatPhoneNumber(sale?.customer?.whatsapp || "") || ``,
    documento_cliente: sale?.customer?.document || ``,
    endereco_cliente: sale?.customer?.address || ``,
    rg_cliente: sale?.customer?.rg || ``,
    nac_cliente: sale?.customer?.nationality || ``,
    prof_cliente: sale?.customer?.profession || ``,
    nome_resp_cliente: sale?.customer?.personInCharge?.name || ``,
    cpf_resp_cliente: sale?.customer?.personInCharge?.cpf || ``,
    observacoes_cliente: sale?.customer?.observations || ``,
    nome_empresa: user?.name || ``,
    cnpj_empresa: user?.cnpj || ``,
    endereco_empresa: user?.address || ``,
    telefone_empresa: formatPhoneNumber(user?.phone || "") || ``,
    whatsapp_empresa: formatPhoneNumber(user?.whatsapp || "") || ``,
    nome_resp_empresa: user?.personInCharge?.name || ``,
    cpf_resp_empresa: user?.personInCharge?.cpf || ``,
    rg_resp_empresa: user?.personInCharge?.rg || ``,
    prof_resp_empresa: user?.personInCharge?.profession || ``,
    nac_resp_empresa: user?.personInCharge?.nationality || ``,
    valor_total: `R$ ${addThousandSeparator(sale?.amount || 0)}` || ``,
    os: `#${sale?.afterSalesData?.serviceOrder?.toLocaleString("pt-BR", {
      minimumIntegerDigits: 5,
      useGrouping: false,
    })}`,
    potencia:
      `${addThousandSeparator(
        proposal?.realSystemData?.power || sale?.afterSalesData?.power || 0
      )} kWp` || ``,

    qtd_mods: modsQtd || ``,
    qtd_invs: invQtd || ``,
    distancia: typeof distance === "number" ? distance : ``,
    alt_telhado: roofHight || ``,
    tipo_de_inversor: inverterType || ``,
    validade: validityDate || ``,
    data_do_dia: new Date().toLocaleDateString("pt-BR", {
      day: "numeric",
      month: "long",
      year: "numeric",
    }),
    servicos: [],
    produtos: [],
  };

  sale?.afterSalesData?.services
    ?.filter((s) => {
      return s.type !== "SUPPLY";
    })
    ?.forEach((s) => {
      tagsValues.servicos?.push({
        servico_nome: s?.serviceName || ``,
        servico_valor: addThousandSeparator(s?.price || 0) || ``,
      });
    });

  sale?.afterSalesData?.services
    ?.filter((s) => {
      return s.type === "SUPPLY";
    })
    ?.forEach((s) => {
      tagsValues.produtos?.push({
        produto_nome: s?.serviceName || ``,
        produto_valor: addThousandSeparator(s?.price || 0) || ``,
        produto_qtd: addThousandSeparator(s?.quantity, false, false) || ``,
      });
    });

  return tagsValues;
};
