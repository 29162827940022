import { Fragment, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import OpenInBrowserRoundedIcon from "@material-ui/icons/OpenInBrowserRounded";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import DeleteForeverRoundedIcon from "@material-ui/icons/DeleteForeverRounded";
import FileCopyRoundedIcon from "@material-ui/icons/FileCopyRounded";

import { ModalConfirm } from "../../shared/components/UIElements/ModalConfirm";
import { SettingsContext } from "../../shared/context/settingsContext";
import { AuthContextProps, ProposalData } from "../../shared/data/types";

interface MenuProposalOptionsProps {
  proposalId: string;
  loadedProposal: ProposalData;
  anchorEl: HTMLButtonElement;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLButtonElement>>;
  auth: AuthContextProps;
  setLoadedData: React.Dispatch<React.SetStateAction<ProposalData[]>>;
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean,
    zeroResultsKey?: string
  ) => Promise<any>;
  noDeleteOpt: boolean;
}

export const MenuProposalOptions = (props: MenuProposalOptionsProps) => {
  const {
    anchorEl,
    setAnchorEl,
    auth,
    proposalId,
    loadedProposal,
    setLoadedData,
    sendRequest,
    noDeleteOpt,
  } = props;
  const history = useHistory();
  const settingsCtx = useContext(SettingsContext);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

  const confirmModalCloseHandler = () => {
    setShowConfirmModal(false);
  };

  const confirmHandler = async () => {
    setShowConfirmModal(false);
    setAnchorEl(null);
    try {
      const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/proposals/single/${proposalId}`;
      await sendRequest(apiUrl, "DELETE", null, {
        Authorization: "Bearer " + auth.token,
      });

      setLoadedData((prevValues) => {
        return prevValues.filter((prevValue) => {
          return prevValue.frontId !== proposalId;
        });
      });
    } catch (err) {}
  };

  const cloneHandler = async () => {
    try {
      let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/proposals/cloned-proposal/${proposalId}`;
      const responseData: { clonedProposalId: string } = await sendRequest(
        apiUrl,
        "POST",
        null,
        {
          Authorization: "Bearer " + auth.token,
        }
      );
      history.push(
        `/proposta/gerar-proposta/dados-consumo/editar?pid=${proposalId}`
      );
    } catch (err) {}
  };

  const openProposalHandler = () => {
    if (
      !!settingsCtx?.proposalSlug &&
      typeof loadedProposal.count === "number" &&
      loadedProposal.count > 0
    ) {
      history.push(
        `/proposta/final/${settingsCtx.proposalSlug}/${
          loadedProposal.count
        }/${loadedProposal.frontId.substring(0, 5)}`
      );
    } else {
      history.push(`/proposta/final/${loadedProposal?.frontId}`);
    }
  };

  return (
    <Fragment>
      <ModalConfirm
        open={showConfirmModal}
        closeHandler={confirmModalCloseHandler}
        confirmHandler={confirmHandler}
        message="Deseja excluir permanentemente esta proposta? Todos os Projetos e Obras atrelados a esta proposta também serão excluídos. Caso não tenha certeza, clique em 'NÃO'"
      />
      <div>
        <Menu
          style={{ zIndex: 10001 }}
          id="opcoes-item-funil"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => {
            setAnchorEl(null);
          }}
        >
          <MenuItem
            disabled={!loadedProposal?.finished}
            onClick={openProposalHandler}
          >
            <OpenInBrowserRoundedIcon style={{ marginRight: "0.75rem" }} />{" "}
            Abrir
          </MenuItem>
          <MenuItem
            onClick={() => {
              history.push(
                `/proposta/gerar-proposta/dados-consumo/editar?pid=${loadedProposal?.frontId}`
              );
            }}
          >
            <EditRoundedIcon style={{ marginRight: "0.75rem" }} /> Editar
          </MenuItem>
          <MenuItem
            onClick={() => {
              cloneHandler();
            }}
          >
            <FileCopyRoundedIcon style={{ marginRight: "0.75rem" }} /> Clonar
          </MenuItem>
          {!noDeleteOpt && auth.type !== "VENDOR" && (
            <MenuItem
              onClick={() => {
                setShowConfirmModal(true);
              }}
            >
              <DeleteForeverRoundedIcon style={{ marginRight: "0.75rem" }} />{" "}
              Excluir
            </MenuItem>
          )}
        </Menu>
      </div>
    </Fragment>
  );
};
