import { makeStyles } from "@material-ui/core/styles";

export const NotificationsMenuStyles = makeStyles((theme) => ({
  notificationItem: {
    color: "#666",
    width: "300px",
    position: "relative",
    margin: "18px 0",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "280px",
    },
  },
  notificationButtons: {
    display: "flex",
    flexDirection: "row",
    alignItems: "end",
    marginBottom: "10px",
    marginLeft: "auto",
    "& span": {
      fontSize: "0.6rem",
    },
  },
  notificationBorder: {
    width: "80%",
    borderBottom: "solid 1px #ccc",
  },
}));
