interface GetInputChangeEvent {
  message?: boolean;
  name?: boolean;
  mininal?: boolean;
  general?: boolean;
  isSlug?: boolean;
  charOnly?: boolean;
}

export const getInputChangeEvent = (props: GetInputChangeEvent) => {
  const {
    message = false,
    name = false,
    mininal = false,
    general = false,
    isSlug = false,
    charOnly = false,
  } = props;

  if (message) {
    return "CHANGE_CLEAN_MESSAGE";
  } else if (name) {
    return "CHANGE_CLEAN_NAME";
  } else if (mininal) {
    return "CHANGE_CLEAN_MINIMAL";
  } else if (general) {
    return "CHANGE_CLEAN_GENERAL";
  } else if (isSlug) {
    return "CHANGE_SLUG";
  } else if (charOnly) {
    return "CHANGE_CHAR_ONLY";
  } else {
    return "CHANGE";
  }
};
