import { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import SendRoundedIcon from "@material-ui/icons/SendRounded";
import DeleteForeverRoundedIcon from "@material-ui/icons/DeleteForeverRounded";
import AttachMoneyRoundedIcon from "@material-ui/icons/AttachMoneyRounded";
import MoneyOffRoundedIcon from "@material-ui/icons/MoneyOffRounded";
import KeyboardReturnRoundedIcon from "@material-ui/icons/KeyboardReturnRounded";
import DoneOutlineRoundedIcon from "@material-ui/icons/DoneOutlineRounded";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import ThumbDownAltRoundedIcon from "@material-ui/icons/ThumbDownAltRounded";
import PostAddRoundedIcon from "@material-ui/icons/PostAddRounded";

import { ModalConfirm } from "../../shared/components/UIElements/ModalConfirm";
import { LoadingSpinnerOverlayRegular } from "../../shared/components/UIElements/LoadingSpinnerOverlayRegular";
import { PopoverFormClosedSalePropChoice } from "./PopoverFormClosedSalePropChoice";
import { PopoverFormClosedLeadVendorChoice } from "./PopoverFormClosedLeadVendorChoice";
import { PopoverFormLostSaleReasonChoice } from "./PopoverFormLostSaleReasonChoice";
import { PopoverFormReturnSaleToFunnel } from "./PopoverFormReturnSaleToFunnel";
import { PopoverFormReturnSaleToPreSale } from "./PopoverFormReturnSaleToPreSale";
import { PopoverFormClosedProjExecStatus } from "./PopoverFormClosedProjExecStatus";
import { PopoverFormRejectReasonChoice } from "./PopoverFormRejectReasonChoice";
import { PopoverGenAfterSalesProp } from "./PopoverGenAfterSalesProp";
import { PopoverMoveSale } from "./PopoverMoveSale";
import { getSaleTypeClientData } from "../util/getSaleTypeClientData";
import { deleteItem } from "../api/salesFunnelAPI";
import { editSaleStatus } from "../api/salesFunnelAPI";
import {
  AuthContextProps,
  KanbanFilledData,
  SettingsContextData,
} from "../../shared/data/types";

interface MenuSalesItemOptionsProps {
  id: string;
  item: KanbanFilledData["items"][0];
  customerId: string;
  columnId: string;
  anchorEl: HTMLButtonElement;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLButtonElement>>;
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean
  ) => Promise<any>;
  auth: AuthContextProps;
  settingsCtx: SettingsContextData;
  kanbanData: KanbanFilledData;
  setKanbanData: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
  fetchId: string;
  type:
    | "SALES"
    | "PROJECT"
    | "INSTALLATION"
    | "AFTER_SALES"
    | "PRE_SALES"
    | "ADMINISTRATIVE";
  setActiveItem?: React.Dispatch<React.SetStateAction<string>>;
  setSelectedItems?: React.Dispatch<React.SetStateAction<string[]>>;
  isLoading?: boolean;
  setUpdateColumnsTotals?: React.Dispatch<React.SetStateAction<string[]>>;
  isManagerView?: boolean;
  isTeamLoaded?: boolean;
}

export const MenuSalesItemOptions = (props: MenuSalesItemOptionsProps) => {
  const {
    item,
    customerId,
    columnId,
    anchorEl,
    setAnchorEl,
    sendRequest,
    auth,
    id,
    setKanbanData,
    fetchId,
    setActiveItem,
    kanbanData,
    settingsCtx,
    type,
    setSelectedItems,
    isLoading,
    setUpdateColumnsTotals,
    isManagerView = false,
    isTeamLoaded = false,
  } = props;
  const history = useHistory();
  const [anchorElFormMove, setAnchorElFormMove] =
    useState<null | HTMLButtonElement>(null);
  const [anchorElFormCloseSale, setAnchorElFormCloseSale] = useState<
    null | HTMLButtonElement | HTMLLIElement
  >(null);
  const [anchorElFormLostSale, setAnchorElFormLostSale] = useState<
    null | HTMLButtonElement | HTMLLIElement
  >(null);
  const [anchorElFormExecStatus, setAnchorElFormExecStatus] = useState<
    null | HTMLButtonElement | HTMLLIElement
  >(null);
  const [anchorElFormReturn, setAnchorElFormReturn] = useState<
    null | HTMLButtonElement | HTMLLIElement
  >(null);
  const [anchorElFormRejectReason, setAnchorElFormRejectReason] = useState<
    null | HTMLButtonElement | HTMLLIElement
  >(null);
  const [anchorElGenProp, setAnchorElGenProp] = useState<
    null | HTMLButtonElement | HTMLLIElement
  >(null);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [showConfirmFinishModal, setShowConfirmFinishModal] =
    useState<boolean>(false);
  const { typeName, registerUrl } = getSaleTypeClientData(type, auth.userId);
  const ip = settingsCtx?.individualPermissions?.find((ip) => {
    return ip?.user?.toString() === auth?.userId;
  });
  const isManager =
    (type === "SALES" && ip?.salesManager) ||
    (type === "PROJECT" && ip?.projectManager) ||
    (type === "INSTALLATION" && ip?.installationManager) ||
    (type === "AFTER_SALES" && ip?.afterSalesManager) ||
    (type === "PRE_SALES" && ip?.preSalesManager) ||
    (type === "ADMINISTRATIVE" && ip?.administrativeManager) ||
    false;

  const finishTaskHandler = () => {
    setShowConfirmFinishModal(false);
    editSaleStatus({
      sendRequest,
      auth,
      status: "CLOSED",
      approvedProposal: "",
      itemId: item?._id,
      setKanbanData,
      toBeRemovedItem: item,
    }).then(() => {
      if (setActiveItem) {
        setActiveItem(null);
      }
      if (setUpdateColumnsTotals) {
        setUpdateColumnsTotals([columnId, "0"]);
      }
    });
    setAnchorEl(null);
  };

  return (
    <Fragment>
      {item?.status === "IN_PROGRESS" &&
        type !== "SALES" &&
        type !== "PRE_SALES" && (
          <ModalConfirm
            open={showConfirmFinishModal}
            closeHandler={() => {
              setShowConfirmFinishModal(false);
            }}
            confirmHandler={() => {
              finishTaskHandler();
            }}
            message="Deseja marcar este item como finalizado?"
          />
        )}
      {auth.type !== "VENDOR" && (
        <ModalConfirm
          open={showConfirmModal}
          closeHandler={() => {
            setShowConfirmModal(false);
          }}
          confirmHandler={() => {
            deleteItem({
              sendRequest,
              auth,
              setKanbanData,
              itemId: item?._id,
              toBeDeletedItem: item,
              setShowConfirmModal,
            }).then(() => {
              if (setActiveItem) {
                setActiveItem(null);
              }
              if (setUpdateColumnsTotals) {
                setUpdateColumnsTotals([columnId, "0"]);
              }
            });
          }}
          message="Deseja excluir este item permanentemente?"
        />
      )}
      {type === "PROJECT" && (
        <PopoverFormRejectReasonChoice
          itemId={item?._id}
          item={item}
          anchorEl={anchorElFormRejectReason}
          setAnchorEl={setAnchorElFormRejectReason}
          auth={auth}
          sendRequest={sendRequest}
          setKanbanData={setKanbanData}
        />
      )}
      {(type === "SALES" || type === "PRE_SALES") && (
        <PopoverMoveSale
          columnId={columnId}
          itemId={id}
          item={item}
          anchorEl={anchorElFormMove}
          setAnchorEl={setAnchorElFormMove}
          auth={auth}
          sendRequest={sendRequest}
          setKanbanData={setKanbanData}
          senderFetchId={fetchId}
          kanbanType={type}
          type="single"
          setActiveItem={setActiveItem}
          setSelectedItems={setSelectedItems}
          isTeamLoaded={isTeamLoaded}
        />
      )}
      {type === "SALES" && (
        <PopoverFormClosedSalePropChoice
          itemId={item?._id}
          item={item}
          anchorEl={anchorElFormCloseSale}
          setAnchorEl={setAnchorElFormCloseSale}
          auth={auth}
          sendRequest={sendRequest}
          setKanbanData={setKanbanData}
          setActiveItem={setActiveItem}
          settingsCtx={settingsCtx}
          type={type}
          setUpdateColumnsTotals={setUpdateColumnsTotals}
        />
      )}
      {type === "PRE_SALES" && (
        <PopoverFormClosedLeadVendorChoice
          itemId={item?._id}
          item={item}
          anchorEl={anchorElFormCloseSale}
          setAnchorEl={setAnchorElFormCloseSale}
          auth={auth}
          sendRequestParent={sendRequest}
          setKanbanData={setKanbanData}
          setActiveItem={setActiveItem}
          settingsCtx={settingsCtx}
          type={type}
          setUpdateColumnsTotals={setUpdateColumnsTotals}
        />
      )}
      {type === "SALES" && (
        <PopoverFormReturnSaleToPreSale
          itemId={item?._id}
          item={item}
          anchorEl={anchorElFormReturn}
          setAnchorEl={setAnchorElFormReturn}
          auth={auth}
          sendRequestParent={sendRequest}
          setKanbanData={setKanbanData}
          setActiveItem={setActiveItem}
          settingsCtx={settingsCtx}
          type={type}
          setUpdateColumnsTotals={setUpdateColumnsTotals}
        />
      )}
      {(type === "SALES" || type === "PRE_SALES") &&
        item?.status === "IN_PROGRESS" && (
          <PopoverFormLostSaleReasonChoice
            itemId={item?._id}
            item={item}
            anchorEl={anchorElFormLostSale}
            setAnchorEl={setAnchorElFormLostSale}
            auth={auth}
            sendRequest={sendRequest}
            setKanbanData={setKanbanData}
            setActiveItem={setActiveItem}
            setUpdateColumnsTotals={setUpdateColumnsTotals}
          />
        )}
      {item?.status !== "IN_PROGRESS" && (
        <PopoverFormReturnSaleToFunnel
          itemId={item?._id}
          item={item}
          kanbanData={kanbanData}
          anchorEl={anchorElFormReturn}
          setAnchorEl={setAnchorElFormReturn}
          auth={auth}
          sendRequest={sendRequest}
          setKanbanData={setKanbanData}
          setActiveItem={setActiveItem}
        />
      )}
      {(type === "PROJECT" || type === "INSTALLATION") && (
        <PopoverFormClosedProjExecStatus
          itemId={item?._id}
          item={item}
          anchorEl={anchorElFormExecStatus}
          setAnchorEl={setAnchorElFormExecStatus}
          auth={auth}
          sendRequest={sendRequest}
          setKanbanData={setKanbanData}
          setActiveItem={setActiveItem}
          setUpdateColumnsTotals={setUpdateColumnsTotals}
        />
      )}
      {type === "AFTER_SALES" && (
        <PopoverGenAfterSalesProp
          anchorEl={anchorElGenProp}
          setAnchorEl={setAnchorElGenProp}
          sale={item}
          auth={auth}
        />
      )}
      <div>
        <Menu
          style={{ zIndex: 502 }}
          id="opcoes-item-funil"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => {
            setAnchorEl(null);
          }}
        >
          {isLoading && <LoadingSpinnerOverlayRegular />}
          {item?.status === "IN_PROGRESS" && type === "SALES" && (
            <MenuItem
              style={{ color: "#007bed" }}
              onClick={(e) => {
                setAnchorEl(null);
                setAnchorElFormCloseSale(e.currentTarget);
              }}
            >
              <AttachMoneyRoundedIcon style={{ marginRight: "0.75rem" }} /> Dar
              Ganho
            </MenuItem>
          )}
          {item?.status === "IN_PROGRESS" && type === "PRE_SALES" && (
            <MenuItem
              style={{ color: "#007bed" }}
              onClick={(e) => {
                setAnchorEl(null);
                setAnchorElFormCloseSale(e.currentTarget);
              }}
            >
              <DoneOutlineRoundedIcon style={{ marginRight: "0.75rem" }} />{" "}
              Qualificar
            </MenuItem>
          )}
          {item?.status === "IN_PROGRESS" &&
            type !== "SALES" &&
            type !== "PRE_SALES" && (
              <MenuItem
                style={{ color: "#007bed" }}
                onClick={(e) => {
                  setAnchorEl(null);
                  if (type === "PROJECT" || type === "INSTALLATION") {
                    setAnchorElFormExecStatus(e.currentTarget);
                  } else {
                    setShowConfirmFinishModal(true);
                  }
                }}
              >
                <DoneOutlineRoundedIcon style={{ marginRight: "0.75rem" }} />{" "}
                Finalizar
              </MenuItem>
            )}
          {item?.status === "IN_PROGRESS" && type === "PROJECT" && (
            <MenuItem
              style={{ color: "#f50057" }}
              onClick={(e) => {
                setAnchorEl(null);
                setAnchorElFormRejectReason(e.currentTarget);
              }}
            >
              <ThumbDownAltRoundedIcon style={{ marginRight: "0.75rem" }} />{" "}
              Add. Reprova
            </MenuItem>
          )}
          {item?.status === "IN_PROGRESS" && type === "SALES" && (
            <MenuItem
              style={{ color: "#f50057" }}
              onClick={(e) => {
                setAnchorEl(null);
                setAnchorElFormLostSale(e.currentTarget);
              }}
            >
              <MoneyOffRoundedIcon style={{ marginRight: "0.75rem" }} /> Dar
              Perdido
            </MenuItem>
          )}
          {item?.status === "IN_PROGRESS" && type === "PRE_SALES" && (
            <MenuItem
              style={{ color: "#f50057" }}
              onClick={(e) => {
                setAnchorEl(null);
                setAnchorElFormLostSale(e.currentTarget);
              }}
            >
              <CloseRoundedIcon style={{ marginRight: "0.75rem" }} />{" "}
              Desqualificar
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              history.push(`${registerUrl}?saleId=${item?.id}`);
            }}
          >
            <EditRoundedIcon style={{ marginRight: "0.75rem" }} />{" "}
            {`Editar ${typeName}`}
          </MenuItem>
          {(auth.type === "MANAGER" || isManager || isManagerView) &&
            (type === "SALES" || type === "PRE_SALES") && (
              <MenuItem
                onClick={() => {
                  setAnchorElFormMove(anchorEl);
                  setAnchorEl(null);
                }}
              >
                <SendRoundedIcon style={{ marginRight: "0.75rem" }} />{" "}
                {type === "SALES" ? "Enviar Venda" : "Enviar Lead"}
              </MenuItem>
            )}
          {item?.status === "IN_PROGRESS" &&
            type === "SALES" &&
            !!item?.relatedPreSale && (
              <MenuItem
                onClick={(e) => {
                  setAnchorEl(null);
                  setAnchorElFormReturn(e.currentTarget);
                }}
              >
                <KeyboardReturnRoundedIcon style={{ marginRight: "0.75rem" }} />{" "}
                {`Retornar à Qualificação`}
              </MenuItem>
            )}
          {item?.status !== "IN_PROGRESS" && (
            <MenuItem
              onClick={(e) => {
                setAnchorEl(null);
                setAnchorElFormReturn(e.currentTarget);
              }}
            >
              <KeyboardReturnRoundedIcon style={{ marginRight: "0.75rem" }} />{" "}
              {`Retornar ao ${
                type === "SALES" || type === "PRE_SALES" ? "Funil" : "Quadro"
              }`}
            </MenuItem>
          )}
          {type === "AFTER_SALES" && (
            <MenuItem
              onClick={(e) => {
                setAnchorEl(null);
                setAnchorElGenProp(e.currentTarget);
              }}
              disabled={
                !auth.activeModules?.includes(
                  "Gerador de Propostas de Pós-Venda"
                )
              }
            >
              <PostAddRoundedIcon style={{ marginRight: "0.75rem" }} /> Gerar
              Proposta
            </MenuItem>
          )}
          {auth.type !== "VENDOR" && (
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                setShowConfirmModal(true);
              }}
            >
              <DeleteForeverRoundedIcon style={{ marginRight: "0.75rem" }} />{" "}
              {`Excluir ${typeName}`}
            </MenuItem>
          )}
        </Menu>
      </div>
    </Fragment>
  );
};
