import { createContext } from "react";
import { AuthContextProps } from "../data/types";

export const AuthContext = createContext<AuthContextProps>({
  isLoggedIn: false,
  userIsAdmin: false,
  userId: null,
  managerId: null,
  managerUserId: null,
  vendors: null,
  type: null,
  token: null,
  statusOk: false,
  status: null,
  signupPlan: null,
  activeModules: [],
  whitelabel: "",
  login: (
    userId,
    managerId,
    managerUserId,
    vendors,
    uToken,
    uType,
    uIsAdmin,
    uStatusOk,
    uStatus,
    usignupPlan,
    activeModules,
    whitelabel,
    expirationDate
  ) => {},
  logout: () => {},
});
