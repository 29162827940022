import { Fragment, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import MoreHorizRoundedIcon from "@material-ui/icons/MoreHorizRounded";
import DoneOutlineRoundedIcon from "@material-ui/icons/DoneOutlineRounded";
import ThumbDownAltRoundedIcon from "@material-ui/icons/ThumbDownAltRounded";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";

import { ModalConfirm } from "../../shared/components/UIElements/ModalConfirm";
import { MenuSalesItemOptions } from "./MenuSalesItemOptions";
import { MoreItems } from "../../shared/components/UIElements/MoreItems";
import { sliceLongString } from "../../shared/util/sliceLongString";
import { openWhatsapp } from "../../shared/util/openWhatsapp";
import { editSaleStatus } from "../api/salesFunnelAPI";
import { validDateChecker } from "../../shared/util/validDateChecker";
import { PopoverFormClosedProjExecStatus } from "./PopoverFormClosedProjExecStatus";
import { PopoverFormRejectReasonChoice } from "./PopoverFormRejectReasonChoice";
import { addThousandSeparator } from "../../shared/util/addThousandSeparator";
import {
  AuthContextProps,
  KanbanFilledData,
  SettingsContextProps,
  ProposalData,
} from "../../shared/data/types";

import "./SaleDash.scss";

interface TaskDashGenDataProps {
  memory: KanbanFilledData["items"][0];
  auth: AuthContextProps;
  settingsCtx: SettingsContextProps;
  kanbanData: KanbanFilledData;
  setLoadedData: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
  fetchId: string;
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean,
    zeroResultsKey?: string
  ) => Promise<any>;
  type:
    | "SALES"
    | "PROJECT"
    | "INSTALLATION"
    | "AFTER_SALES"
    | "PRE_SALES"
    | "ADMINISTRATIVE";
  setActiveItem: React.Dispatch<React.SetStateAction<string>>;
  setUpdateColumnsTotals?: React.Dispatch<React.SetStateAction<string[]>>;
  isManagerView: boolean;
  isTeamLoaded: boolean;
}

export const TaskDashGenData = (props: TaskDashGenDataProps) => {
  const {
    memory,
    auth,
    setLoadedData,
    sendRequest,
    setActiveItem,
    fetchId,
    kanbanData,
    type,
    settingsCtx,
    setUpdateColumnsTotals,
    isManagerView,
    isTeamLoaded,
  } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>(null);
  const [showConfirmFinishModal, setShowConfirmFinishModal] =
    useState<boolean>(false);
  const [anchorElFormExecStatus, setAnchorElFormExecStatus] = useState<
    null | HTMLButtonElement | HTMLLIElement
  >(null);
  const [anchorElFormRejectReason, setAnchorElFormRejectReason] = useState<
    null | HTMLButtonElement | HTMLLIElement
  >(null);
  const [open, setOpen] = useState<boolean>(true);
  const proposal = memory?.approvedProposal as ProposalData;
  const saleOwners = memory?.owners || [];
  const saleOwnersNames = saleOwners
    ?.map((data) => {
      if (data === auth.managerUserId) {
        return "GERENTE";
      } else {
        return auth.vendors?.find((v) => {
          return v._id === data;
        })?.personalData?.name;
      }
    })
    ?.join(" | ");

  const finishTaskHandler = () => {
    setShowConfirmFinishModal(false);
    editSaleStatus({
      sendRequest,
      auth,
      status: "CLOSED",
      approvedProposal: "",
      itemId: memory?._id,
      setKanbanData: setLoadedData,
      toBeRemovedItem: memory,
    }).then(() => {
      if (setActiveItem) {
        setActiveItem(null);
      }
      if (setUpdateColumnsTotals) {
        setUpdateColumnsTotals([memory?.kanbanData?.column, "0"]);
      }
    });
    setAnchorEl(null);
  };

  return (
    <div className="sale-dash__cust-data">
      {(type === "PROJECT" || type === "INSTALLATION") && (
        <PopoverFormClosedProjExecStatus
          itemId={memory?._id}
          item={memory}
          anchorEl={anchorElFormExecStatus}
          setAnchorEl={setAnchorElFormExecStatus}
          auth={auth}
          sendRequest={sendRequest}
          setKanbanData={setLoadedData}
          setActiveItem={setActiveItem}
          setUpdateColumnsTotals={setUpdateColumnsTotals}
        />
      )}
      {type === "PROJECT" && (
        <PopoverFormRejectReasonChoice
          itemId={memory?._id}
          item={memory}
          anchorEl={anchorElFormRejectReason}
          setAnchorEl={setAnchorElFormRejectReason}
          auth={auth}
          sendRequest={sendRequest}
          setKanbanData={setLoadedData}
        />
      )}
      <ModalConfirm
        open={showConfirmFinishModal}
        closeHandler={() => {
          setShowConfirmFinishModal(false);
        }}
        confirmHandler={() => {
          finishTaskHandler();
        }}
        message="Deseja marcar este item como finalizado?"
      />
      <MenuSalesItemOptions
        item={memory}
        customerId={memory?.customer?.id}
        columnId={memory?.kanbanData?.column}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        id={memory?._id}
        sendRequest={sendRequest}
        auth={auth}
        setKanbanData={setLoadedData}
        fetchId={fetchId}
        kanbanData={kanbanData}
        settingsCtx={settingsCtx}
        setActiveItem={setActiveItem}
        type={type}
        setUpdateColumnsTotals={setUpdateColumnsTotals}
        isManagerView={isManagerView}
        isTeamLoaded={isTeamLoaded}
      />
      <div className="sale-dash__title-container">
        <p className="sale-dash__title">{memory?.name}</p>
        <div
          className={`sale-dash__cust-data-actions ${
            type !== "PROJECT" ? "sale-dash__cust-data-actions--2" : ""
          }`}
        >
          <IconButton
            size="small"
            style={{
              color: memory?.status === "IN_PROGRESS" ? "#007bed" : "#aaa",
            }}
            title="Finalizar"
            className={`table__item-action`}
            disabled={memory?.status !== "IN_PROGRESS"}
            onClick={(e) => {
              if (type === "PROJECT" || type === "INSTALLATION") {
                setAnchorElFormExecStatus(e.currentTarget);
              } else {
                setShowConfirmFinishModal(true);
              }
            }}
          >
            <DoneOutlineRoundedIcon style={{ fontSize: "22px" }} />
          </IconButton>
          {type === "PROJECT" && (
            <IconButton
              size="small"
              style={{
                color: memory?.status === "IN_PROGRESS" ? "#f50057" : "#aaa",
              }}
              title="Adicionar Reprova"
              className={`table__item-action`}
              disabled={memory?.status !== "IN_PROGRESS"}
              onClick={(e) => {
                setAnchorElFormRejectReason(e.currentTarget);
              }}
            >
              <ThumbDownAltRoundedIcon style={{ fontSize: "22px" }} />
            </IconButton>
          )}
          <IconButton
            size="small"
            style={{ color: "#263351" }}
            title="Opções"
            className={`table__item-action`}
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
            }}
          >
            <MoreHorizRoundedIcon style={{ fontSize: "24px" }} />
          </IconButton>
        </div>
      </div>
      <div
        className={`sale-dash__checklist sale-dash__checklist--first ${
          open ? "sale-dash__checklist--first-extra-padding-bottom" : ""
        }`}
      >
        <div
          onClick={(e) => {
            setOpen(!open);
          }}
          className="sale-dash__title-container sale-dash__title-container--clickable sale-dash__title-container--secondary"
        >
          <p className={`sale-dash__title sale-dash__title--secondary`}>
            Informações
          </p>
          <div className="sale-dash__cust-data-actions sale-dash__cust-data-actions--1">
            <IconButton
              style={{
                color: "#aaa",
              }}
              size="small"
              title={!open ? "Abrir" : "Fechar"}
              className={`sale-dash__cust-data-action sale-dash__cust-data-action--blue ${
                open ? "sale-dash__cust-data-action--rotated-icon" : ""
              }`}
              onClick={(e) => {
                e.stopPropagation();
                setOpen(!open);
              }}
            >
              <KeyboardArrowDownRoundedIcon style={{ fontSize: "30px" }} />
            </IconButton>
          </div>
        </div>
        {open && (
          <div className="sale-dash__cust-data-item">
            <p className="sale-dash__cust-data-item-label">Número</p>
            <p className="sale-dash__cust-data-item-content">
              {proposal?.count >= 0 ? "#" : ""}
              {proposal?.count?.toLocaleString("pt-BR", {
                minimumIntegerDigits: 5,
                useGrouping: false,
              }) || "-"}
            </p>
            {type === "ADMINISTRATIVE" && (
              <Fragment>
                <p className="sale-dash__cust-data-item-label">Valor:</p>
                <p className="sale-dash__cust-data-item-content">
                  {`R$ ${
                    addThousandSeparator(
                      proposal?.revenueData?.finalPrice || memory?.amount || 0
                    ) || "-"
                  }`}{" "}
                </p>
              </Fragment>
            )}
            <p className="sale-dash__cust-data-item-label">Prazo</p>
            <p
              className={`sale-dash__cust-data-item-content ${
                !validDateChecker(memory?.validityDate) &&
                !!memory?.validityDate
                  ? "sale-dash__cust-data-item-content--red"
                  : !!memory?.validityDate
                  ? "sale-dash__cust-data-item-content--blue"
                  : ""
              }`}
            >
              {memory?.validityDate?.substring(0, 10) || "-"}
            </p>
            <p className="sale-dash__cust-data-item-label">Cliente</p>
            <p className="sale-dash__cust-data-item-content">
              {proposal?.customerData?.name}
            </p>
            <p className="sale-dash__cust-data-item-label">E-mail</p>
            <p className="sale-dash__cust-data-item-content">
              {proposal?.customerData?.email || "-"}
            </p>
            <p className="sale-dash__cust-data-item-label">Telefone</p>
            <p className="sale-dash__cust-data-item-content">
              {proposal?.customerData?.whatsapp && (
                <span
                  onClick={() => {
                    openWhatsapp(
                      proposal?.customerData?.whatsapp?.replace(/\D/g, "")
                    );
                  }}
                  className="sale-dash__cust-data-item-subcontent sale-dash__cust-data-item-subcontent--clickable"
                >
                  {proposal?.customerData?.whatsapp}
                </span>
              )}
              {!proposal?.customerData?.whatsapp && (
                <span className="sale-dash__cust-data-item-subcontent">-</span>
              )}
            </p>
            <p className="sale-dash__cust-data-item-label">Potência</p>
            <p className="sale-dash__cust-data-item-content">
              {`${addThousandSeparator(
                proposal?.realSystemData?.power ||
                  memory?.systemSummary?.power ||
                  0
              )} kWp` || "-"}
            </p>
            <p className="sale-dash__cust-data-item-label">Cidade</p>
            <p className="sale-dash__cust-data-item-content">
              {proposal?.location?.city || "-"}
            </p>
            <p className="sale-dash__cust-data-item-label">Endereço</p>
            <p className="sale-dash__cust-data-item-content">
              {proposal?.location?.address || "-"}
            </p>
            <p className="sale-dash__cust-data-item-label">Proprietários</p>
            <p className="sale-dash__cust-data-item-content">
              <span className="sale-dash__cust-data-item-subcontent">
                {saleOwnersNames?.split(" | ")?.length > 1
                  ? sliceLongString(saleOwnersNames?.split(" | ")?.[0], 15)
                  : saleOwnersNames || "GERENTE"}
                {saleOwnersNames.split(" | ")?.length > 1 && (
                  <MoreItems
                    content={saleOwnersNames.split(" | ")}
                    leftLength={saleOwnersNames.split(" | ").slice(1)?.length}
                  />
                )}
              </span>
            </p>
            <p className="sale-dash__cust-data-item-label">Registro</p>
            <p className="sale-dash__cust-data-item-content">
              {memory?.registryDate || "-"}
            </p>
            <p className="sale-dash__cust-data-item-label">ID</p>
            <p className="sale-dash__cust-data-item-content">{memory?.id}</p>
          </div>
        )}
      </div>
    </div>
  );
};
