import { createTheme } from "@material-ui/core/styles";
import { ptBR } from "@material-ui/core/locale";

export const appTheme = createTheme(
  {
    palette: {
      primary: {
        light: "#2b99ff",
        main: "#007bed",
        dark: "#0061bc",
      },
    },
    typography: {
      h1: {
        fontFamily: '"Fjalla One", "Helvetica", "Arial", sans-serif',
      },
      h2: {
        fontFamily: '"Fjalla One", "Helvetica", "Arial", sans-serif',
      },
      h3: {
        fontFamily: '"Fjalla One", "Helvetica", "Arial", sans-serif',
      },
      h4: {
        fontFamily: '"Fjalla One", "Helvetica", "Arial", sans-serif',
      },
      h5: {
        fontFamily: '"Fjalla One", "Helvetica", "Arial", sans-serif',
      },
      h6: {
        fontFamily: '"Fjalla One", "Helvetica", "Arial", sans-serif',
      },
      subtitle1: {
        fontFamily: '"Noto Sans", "Helvetica", "Arial", sans-serif',
      },
      subtitle2: {
        fontFamily: '"Noto Sans", "Helvetica", "Arial", sans-serif',
        fontWeight: 700,
      },
      body1: {
        fontFamily: '"Noto Sans", "Helvetica", "Arial", sans-serif',
        color: "#616161",
      },
      body2: {
        fontFamily: '"Noto Sans", "Helvetica", "Arial", sans-serif',
      },
      button: {
        fontFamily: '"Noto Sans", "Helvetica", "Arial", sans-serif',
        fontWeight: 700,
      },
    },
  },
  ptBR
);
