import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import { orange } from "@material-ui/core/colors";

export const SwitchCustomOrange = withStyles({
  switchBase: {
    "&$checked": {
      color: orange[600],
    },
    "&$checked + $track": {
      backgroundColor: orange[400],
    },
  },
  checked: {},
  track: {},
})(Switch);
