import Popover from "@material-ui/core/Popover";
import React, { Fragment, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";

import { InputCalendar } from "../../shared/components/FormElements/InputCalendar";
import { useHttpClient } from "../../shared/hooks/httpHook";
import { InputSlider } from "../../shared/components/FormElements/InputSlider";
import { useForm } from "../../shared/hooks/formHook";
import { PopoverFormStyles } from "./PopoverFormStyles";
import { editSaleStatus } from "../api/salesFunnelAPI";
import { getSingleKanban } from "../api/salesFunnelAPI";
import { dateFormatFromISOString } from "../../shared/util/dateFormatFromISOString";
import { LEAD_TEMPERATURE } from "../../shared/data/static";
import {
  AuthContextProps,
  KanbanFilledData,
  SettingsContextData,
  KanbanData,
} from "../../shared/data/types";

interface PopoverFormClosedLeadVendorChoiceProps {
  type:
    | "SALES"
    | "PROJECT"
    | "INSTALLATION"
    | "AFTER_SALES"
    | "PRE_SALES"
    | "ADMINISTRATIVE";
  itemId: string;
  item: KanbanFilledData["items"][0];
  anchorEl: HTMLButtonElement | HTMLLIElement | null;
  setAnchorEl: React.Dispatch<
    React.SetStateAction<HTMLButtonElement | HTMLLIElement>
  >;
  auth: AuthContextProps;
  sendRequestParent: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean
  ) => Promise<any>;
  settingsCtx: SettingsContextData;
  setKanbanData: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
  setActiveItem?: React.Dispatch<React.SetStateAction<string>>;
  setUpdateColumnsTotals?: React.Dispatch<React.SetStateAction<string[]>>;
}

export const PopoverFormClosedLeadVendorChoice = (
  props: PopoverFormClosedLeadVendorChoiceProps
) => {
  const {
    item,
    anchorEl,
    setAnchorEl,
    auth,
    sendRequestParent,
    setKanbanData,
    setActiveItem,
    type,
    settingsCtx,
    setUpdateColumnsTotals,
  } = props;
  const { formState, inputHandler, reportInvalid } = useForm({}, false);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const open = Boolean(anchorEl);
  const classes = PopoverFormStyles();
  const [chosenVend, setChosenVend] = useState<string>("");
  const [chosenStep, setChosenStep] = useState<number>(0);
  const [loadedKanban, setLoadedKanban] = useState<KanbanData>(null);
  const [finishedDate, setFinishedDate] = useState<string>(
    new Date().toISOString()
  );

  const selectectFinishedDateHandler = (date: string) => {
    setFinishedDate(date);
  };

  const vendorsOptions =
    auth.vendors
      ?.filter((item) => {
        return settingsCtx.individualPermissions
          ?.filter((ip) => {
            return ip.salesKanban;
          })
          ?.map((ip) => {
            return ip.user;
          })
          ?.includes(item._id);
      })
      ?.map((vendor) => {
        return {
          value: vendor._id,
          option: `${vendor.personalData?.name}`,
        };
      }) || [];

  const allVendorsOptions = [
    ...vendorsOptions,
    { value: auth.managerUserId, option: "GERENTE" },
    { value: "AUTO", option: "DISTRIBUIÇÃO AUTOMÁTICA" },
  ];

  const columnsOpts =
    loadedKanban?.columns?.length > 0
      ? loadedKanban?.columns
          ?.sort((a, b) => {
            return (a?.order || 0) - (b?.order || 0);
          })
          ?.map((column) => {
            return {
              txt: `Etapa ${column?.order + 1} - ${column?.title}`,
              id: column?.order,
            };
          })
      : [];

  useEffect(() => {
    if (anchorEl) {
      getSingleKanban({ sendRequest, auth, setLoadedKanban, type: "SALES" });
    }
  }, [type, anchorEl]);

  const changeValueHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChosenVend(e.target.value);
  };

  const changeStepValueHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChosenStep(+e.target.value);
  };

  const confirmHandler = () => {
    editSaleStatus({
      sendRequest: sendRequestParent,
      auth,
      status: "CLOSED",
      vendor: chosenVend,
      columnOrder: chosenStep || 0,
      itemId: item?._id,
      setKanbanData,
      toBeRemovedItem: item,
      finishedDate: dateFormatFromISOString(finishedDate),
      leadTemperature: +formState?.inputs?.leadTemperature?.value as 0 | 1 | 2,
    }).then(() => {
      if (setActiveItem) {
        setActiveItem(null);
      }
      if (setUpdateColumnsTotals) {
        setUpdateColumnsTotals([item.kanbanData?.column, "0"]);
      }
    });
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <Popover
        id={item?._id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        style={{ zIndex: 502 }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <form className={classes.formRoot}>
          <FormControl>
            <InputLabel id="fetch-type-filter-selector-label">
              Selecionar Vendedor
            </InputLabel>
            <Select
              labelId="fetch-type-filter-selector-label"
              id="fetch-type-filter-selector"
              value={chosenVend}
              onChange={changeValueHandler}
            >
              {allVendorsOptions?.map((option, i) => {
                return (
                  <MenuItem key={`opt-${i}`} value={option?.value}>
                    {option?.option}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="fetch-type-filter-2-selector-label">
              Selecionar Etapa
            </InputLabel>
            <Select
              labelId="fetch-type-filter-2-selector-label"
              id="fetch-type-filter-2-selector"
              value={chosenStep}
              onChange={changeStepValueHandler}
            >
              {columnsOpts?.map((option, i) => {
                return (
                  <MenuItem key={`opt-${i}`} value={option?.id}>
                    {option?.txt}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {auth.signupPlan === "ENTERPRISE" && (
            <InputCalendar
              id="finishedDate"
              label="Data de Finalização"
              format="dd/MM/yy"
              disableFuture
              initialvalue={finishedDate}
              selectedEarlierTimePeriodHandler={selectectFinishedDateHandler}
            />
          )}
          <div className="u-center">
            <InputSlider
              id="leadTemperature"
              label="Temperatura"
              onInput={inputHandler}
              marks={LEAD_TEMPERATURE}
              initialValue={-1}
              minValue={-1}
              maxValue={2}
              sliderStyle={{ width: "90%" }}
              title="Informe qual é a temperatura do lead."
              step={1}
              highlighted
              marksInLabel
              reinitialize
            />
          </div>
          {!isLoading && (
            <Button
              variant="contained"
              color="primary"
              disabled={!chosenVend || typeof chosenStep !== "number"}
              onClick={() => {
                confirmHandler();
                setAnchorEl(null);
              }}
            >
              QUALIFICAR
            </Button>
          )}
          {isLoading && (
            <div className="u-center">
              <CircularProgress size={30} color="primary" />
            </div>
          )}
        </form>
      </Popover>
    </Fragment>
  );
};
