import { dateFormat } from "./dateFormat";

export const validDateChecker = (date: string) => {
  if (
    !!date &&
    /^[0-9]{2}[/]{1}[0-9]{2}[/]{1}[0-9]{4}$/.test(date.substring(0, 10))
  ) {
    const dateArray = date.substring(0, 10).split("/");
    const today = dateFormat(0);
    const todayArray = today.split("/");
    const dateTime = new Date(
      +dateArray[2],
      +dateArray[1] - 1,
      +dateArray[0]
    ).getTime();
    const todayTime = new Date(
      +todayArray[2],
      +todayArray[1] - 1,
      +todayArray[0]
    ).getTime();

    if (todayTime <= dateTime) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
