export const getSaleTypeClientData = (
  type:
    | "SALES"
    | "PROJECT"
    | "INSTALLATION"
    | "AFTER_SALES"
    | "PRE_SALES"
    | "ADMINISTRATIVE",
  userId: string
) => {
  const kanbanName =
    type === "SALES"
      ? "Vendas"
      : type === "PROJECT"
      ? "Projetos"
      : type === "INSTALLATION"
      ? "Obras"
      : type === "AFTER_SALES"
      ? "Pós-Venda"
      : type === "PRE_SALES"
      ? "Pré-Venda"
      : "Administrativo";

  const typeName =
    type === "SALES"
      ? "Venda"
      : type === "PROJECT"
      ? "Projeto"
      : type === "INSTALLATION"
      ? "Obra"
      : type === "AFTER_SALES"
      ? "Atividade"
      : type === "PRE_SALES"
      ? "Lead"
      : "Operação";

  const typeNamePlural =
    type === "SALES"
      ? "Vendas"
      : type === "PROJECT"
      ? "Projetos"
      : type === "INSTALLATION"
      ? "Obras"
      : type === "AFTER_SALES"
      ? "Atividades"
      : type === "PRE_SALES"
      ? "Leads"
      : "Operações";

  const typeCategory =
    type === "SALES"
      ? "Vendas"
      : type === "PROJECT"
      ? "Projetos"
      : type === "INSTALLATION"
      ? "Obras"
      : type === "AFTER_SALES"
      ? "Pós-Venda"
      : type === "PRE_SALES"
      ? "Pré-Venda"
      : "Administrativo";

  const redirectUrl =
    type === "SALES"
      ? `/vendas/funil/${userId}`
      : type === "PROJECT"
      ? `/projetos/quadro/${userId}`
      : type === "INSTALLATION"
      ? `/instalacoes/quadro/${userId}`
      : type === "AFTER_SALES"
      ? `/pos-venda/quadro/${userId}`
      : type === "PRE_SALES"
      ? `/pre-venda/funil/${userId}`
      : `/administrativo/quadro/${userId}`;

  const registerUrl =
    type === "SALES"
      ? "/vendas/registro"
      : type === "PROJECT"
      ? `/projetos/registro`
      : type === "INSTALLATION"
      ? `/instalacoes/registro`
      : type === "AFTER_SALES"
      ? `/pos-venda/registro`
      : type === "PRE_SALES"
      ? `/pre-venda/registro`
      : `/administrativo/registro`;

  return {
    typeName,
    kanbanName,
    typeNamePlural,
    typeCategory,
    redirectUrl,
    registerUrl,
  };
};
