import { FormHookDispState } from "../data/types";

export const removeFormStateProperties = (
  formState: FormHookDispState,
  rootId: string,
  length: number
) => {
  for (let i = 0; i < length; i++) {
    if (formState.inputs?.[`${rootId}${i}`]) {
      delete formState.inputs[`${rootId}${i}`];
    }
  }
};

export const removeFormStateProperty = (
  formState: FormHookDispState,
  rootId: string
) => {
  delete formState.inputs?.[`${rootId}`];
};
