import Typography from "@material-ui/core/Typography";

import { WarningBarStyles } from "./WarningBarStyles";

interface WarningBarProps {
  text: string | JSX.Element;
  clickableText: string;
  clickAction?: () => void;
  altColor?: boolean;
  blueColor?: boolean;
  notClickable?: boolean;
}

export const WarningBar = (props: WarningBarProps) => {
  const {
    text,
    clickableText,
    clickAction,
    altColor,
    blueColor,
    notClickable,
  } = props;
  const classes = WarningBarStyles();

  return (
    <div
      className={`${classes.warningSection} ${
        altColor
          ? classes.orangeBackground
          : blueColor
          ? classes.blueBackground
          : classes.redBackground
      }`}
    >
      <Typography variant="subtitle1" className={classes.warning}>
        {text}
        <p
          className={`${
            notClickable ? classes.warningLinkAlt : classes.warningLink
          }  ${
            notClickable
              ? ""
              : altColor
              ? classes.orangeText
              : blueColor
              ? classes.blueText
              : classes.redText
          }`}
          onClick={clickAction}
        >
          {clickableText}
        </p>
      </Typography>
    </div>
  );
};
