export const dateFormat = (
  validity: number,
  setMonths: boolean = false,
  roundMonths: boolean = false
) => {
  let sdd = "";
  let smm = "";
  let date = new Date();
  if (!setMonths) {
    date.setDate(date.getDate() + validity);
  } else {
    if (roundMonths && date.getDate() > 28) {
      date.setDate(date.getDate() - 3);
    }
    date.setMonth(date.getMonth() + validity);
  }
  let dd = date.getDate();
  if (dd < 10) {
    sdd = `0${dd}`;
  } else {
    sdd = `${dd}`;
  }
  let mm = date.getMonth();
  if (mm + 1 < 10) {
    smm = `0${mm + 1}`;
  } else {
    smm = `${mm + 1}`;
  }
  return `${sdd}/${smm}/${date.getFullYear()}`;
};
