import {
  AuthContextProps,
  FormHookDispState,
  ContentData,
} from "../../shared/data/types";

export interface FetchProps {
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean
  ) => Promise<any>;
  auth: AuthContextProps;
}

export interface FetchNoProps {
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean
  ) => Promise<any>;
}

// GET CONTENT //////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////
interface GetContentProps extends FetchNoProps {
  setLoadedContent: React.Dispatch<React.SetStateAction<ContentData>>;
}

export const getContent = async (props: GetContentProps) => {
  const { sendRequest, setLoadedContent } = props;

  try {
    const responseData: {
      content: ContentData;
    } = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/content`,
      "GET",
      null
    );

    setLoadedContent(responseData.content);
  } catch (err) {}
};
