import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { ModalSuccessStyles } from "./ModalSuccessStyles";

interface ModalSuccessProps {
  onClear: () => void;
  dontPush?: boolean;
  setVariable?: boolean;
  setVariableFunction?: React.Dispatch<React.SetStateAction<any>>;
  setVariableValue?: any;
  reset?: () => void;
  success: boolean;
  message: string;
  redirect?: string;
  updateData?: boolean;
  stay?: boolean;
  updateDataFlag?: React.MutableRefObject<number>;
}

export const ModalSuccess = (props: ModalSuccessProps) => {
  const {
    onClear,
    dontPush,
    reset,
    success,
    message,
    redirect,
    setVariable,
    stay,
    setVariableFunction,
    setVariableValue,
    updateData,
    updateDataFlag,
  } = props;
  const classes = ModalSuccessStyles();
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const closeHandler = useCallback(() => {
    setOpen(false);
    onClear();
    if (setVariable) {
      setVariableFunction(setVariableValue);
    } else if (dontPush) {
      reset();
    } else if (updateData) {
      updateDataFlag.current++;
    } else if (stay) {
      return;
    } else {
      history.push(redirect);
    }
  }, [
    dontPush,
    history,
    onClear,
    redirect,
    reset,
    setVariable,
    setVariableFunction,
    stay,
    updateData,
    updateDataFlag,
    setVariableValue,
  ]);

  const openHandler = useCallback(() => {
    setOpen(true);
  }, []);

  useEffect(() => {
    if (success) {
      openHandler();
    }
  }, [success, openHandler]);

  return (
    <div>
      <Dialog open={open} onClose={closeHandler}>
        <DialogTitle className={classes.successModalTitle}>SUCESSO</DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.successModalContent}>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeHandler} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
