import Popover from "@material-ui/core/Popover";
import React, { Fragment, useState } from "react";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import { PopoverFormStyles } from "./PopoverFormStyles";
import { editSaleStatus } from "../api/salesFunnelAPI";
import { AuthContextProps, KanbanFilledData } from "../../shared/data/types";

interface PopoverFormReturnSaleToFunnelProps {
  itemId: string;
  kanbanData: KanbanFilledData;
  item: KanbanFilledData["items"][0];
  anchorEl: HTMLButtonElement | HTMLLIElement | null;
  setAnchorEl: React.Dispatch<
    React.SetStateAction<HTMLButtonElement | HTMLLIElement>
  >;
  auth: AuthContextProps;
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean
  ) => Promise<any>;
  setKanbanData: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
  setActiveItem?: React.Dispatch<React.SetStateAction<string>>;
}

export const PopoverFormReturnSaleToFunnel = (
  props: PopoverFormReturnSaleToFunnelProps
) => {
  const {
    kanbanData,
    item,
    anchorEl,
    setAnchorEl,
    auth,
    sendRequest,
    setKanbanData,
    setActiveItem,
  } = props;
  const firstColumn = kanbanData?.kanban?.columns?.sort((a, b) => {
    return (a?.order || 0) - (b?.order || 0);
  })?.[0];
  const columnsOpts =
    kanbanData?.kanban?.columns?.map((c) => {
      return { id: c._id, title: c.title };
    }) || [];
  const open = Boolean(anchorEl);
  const classes = PopoverFormStyles();
  const [chosenOpt, setChosenOpt] = useState<string>(firstColumn?._id);

  const changeValueHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChosenOpt(e.target.value);
  };

  const confirmHandler = () => {
    editSaleStatus({
      sendRequest,
      auth,
      status: "IN_PROGRESS",
      chosenReason: "",
      column: chosenOpt,
      itemId: item?._id,
      setKanbanData,
      toBeRemovedItem: item,
    }).then(() => {
      if (setActiveItem) {
        setActiveItem(null);
      }
    });
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <Popover
        id={item?._id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        style={{ zIndex: 502 }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <form className={classes.formRoot}>
          <FormControl>
            <InputLabel id="fetch-type-filter-selector-label">
              Selecionar coluna do funil
            </InputLabel>
            <Select
              labelId="fetch-type-filter-selector-label"
              id="fetch-type-filter-selector"
              value={chosenOpt}
              onChange={changeValueHandler}
            >
              {columnsOpts?.map((option) => {
                return (
                  <MenuItem key={option.id} value={option.id}>
                    {option.title}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="secondary"
            disabled={!chosenOpt || chosenOpt === ""}
            onClick={() => {
              confirmHandler();
              setAnchorEl(null);
            }}
          >
            RETORNAR VENDA AO FUNIL
          </Button>
        </form>
      </Popover>
    </Fragment>
  );
};
