import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export const regularSliderStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    margin: {
      height: theme.spacing(3),
    },
    labelHighlight: {
      fontWeight: 700,
      color: "#007bed",
      fontSize: "1.4rem",
    },
    labelHighlightOrange: {
      fontWeight: 700,
      color: "#f58f00",
      fontSize: "1.4rem",
    },
    labelHighlightRed: {
      fontWeight: 700,
      color: "#f50057",
      fontSize: "1.4rem",
    },
  })
);
