export const sum = (values: number[]) => {
  const sum =
    values?.length > 0
      ? values?.reduce((v1, v2) => {
          return v1 + v2;
        })
      : 0;

  return sum;
};
