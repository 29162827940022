import { useState, Fragment } from "react";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";

import { SaleDashRejectReasonItem } from "./SaleDashRejectReasonItem";
import {
  AuthContextProps,
  KanbanFilledData,
  SalePopCustData,
} from "../../shared/data/types";

import "./SaleDash.scss";

interface SaleDashRejectReasonsProps {
  auth: AuthContextProps;
  loadedItem: SalePopCustData;
  setLoadedData: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
}

export const SaleDashRejectReasons = (props: SaleDashRejectReasonsProps) => {
  const { auth, loadedItem, setLoadedData } = props;
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Fragment>
      <div className="sale-dash__checklist sale-dash__checklist--next">
        <div
          onClick={(e) => {
            if (loadedItem?.rejectReasons?.length > 0) {
              setOpen(!open);
            }
          }}
          className="sale-dash__title-container sale-dash__title-container--clickable sale-dash__title-container--secondary"
        >
          <p
            className={`sale-dash__title sale-dash__title--secondary ${
              !loadedItem?.rejectReasons ||
              loadedItem?.rejectReasons?.length === 0
                ? "sale-dash__title sale-dash__title--disabled"
                : ""
            }`}
          >
            Reprovas
          </p>
          <div className="sale-dash__cust-data-actions sale-dash__cust-data-actions--1">
            <IconButton
              style={{
                color: "#aaa",
              }}
              size="small"
              title={!open ? "Abrir" : "Fechar"}
              className={`sale-dash__cust-data-action sale-dash__cust-data-action--blue ${
                open ? "sale-dash__cust-data-action--rotated-icon" : ""
              }`}
              disabled={
                !loadedItem?.rejectReasons ||
                loadedItem?.rejectReasons?.length === 0
              }
              onClick={(e) => {
                e.stopPropagation();
                if (loadedItem?.rejectReasons?.length > 0) {
                  setOpen(!open);
                }
              }}
            >
              <KeyboardArrowDownRoundedIcon style={{ fontSize: "30px" }} />
            </IconButton>
          </div>
        </div>
        {open &&
          loadedItem?.rejectReasons?.length > 0 &&
          loadedItem?.rejectReasons?.map((rej, i) => {
            return (
              <SaleDashRejectReasonItem
                auth={auth}
                loadedItem={loadedItem}
                rejReason={rej}
                setLoadedData={setLoadedData}
                key={`rej-reason-${i}`}
              />
            );
          })}
      </div>
    </Fragment>
  );
};
