import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";

import { LoadingSpinnerFullScreenGearStyles } from "./LoadingSpinnerFullScreenGearStyles";
import "./LoadingSpinnersFullScreenCommonStyles.css";

interface LoadingSpinnerFullScreenGearProps {
  fallback?: boolean;
  closerToTop?: boolean;
}

export const LoadingSpinnerFullScreenGear = (
  props: LoadingSpinnerFullScreenGearProps
) => {
  const { fallback, closerToTop = false } = props;
  const classes = LoadingSpinnerFullScreenGearStyles();

  return (
    <React.Fragment>
      <div className={classes.linearRoot}>
        <LinearProgress />
      </div>
      {!fallback && (
        <div className={!closerToTop ? classes.root : classes.rootCloserToTop}>
          <div className="loadingio-spinner-gear-9psd9pl45gd">
            <div className="ldio-050ogejridoo">
              <div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
