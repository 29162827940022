import { useState, useEffect, Fragment } from "react";
import Checkbox from "@material-ui/core/Checkbox";

import { useHttpClient } from "../../shared/hooks/httpHook";
import { ModalError } from "../../shared/components/UIElements/ModalError";
import { LoadingSpinnerOverlayRegular } from "../../shared/components/UIElements/LoadingSpinnerOverlayRegular";
import { answerChecklistItem } from "../api/checklistsAPI";
import {
  AuthContextProps,
  ChecklistData,
  KanbanFilledData,
} from "../../shared/data/types";

import "./SaleDash.scss";

interface SaleDashChecklistChekboxProps {
  auth: AuthContextProps;
  saleId: string;
  kanbanChecklistId: string;
  kanbanChecklistItem: ChecklistData["items"][0];
  saleChecklistItem?: ChecklistData["items"][0];
  setLoadedSaleChecklist: React.Dispatch<React.SetStateAction<ChecklistData>>;
  type:
    | "SALES"
    | "PROJECT"
    | "INSTALLATION"
    | "AFTER_SALES"
    | "PRE_SALES"
    | "ADMINISTRATIVE";
  setLoadedData: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
}

export const SaleDashChecklistChekbox = (
  props: SaleDashChecklistChekboxProps
) => {
  const {
    auth,
    kanbanChecklistId,
    saleId,
    kanbanChecklistItem,
    saleChecklistItem,
    setLoadedSaleChecklist,
    type,
    setLoadedData,
  } = props;
  const { error, sendRequest, clearError, isLoading } = useHttpClient();
  const [checked, setChecked] = useState<boolean>(
    saleChecklistItem?.answerBoolean
  );

  useEffect(() => {
    setChecked(saleChecklistItem?.answerBoolean);
  }, [type, saleId]);

  const changeAnswerHandler = () => {
    const newStatus = !checked;
    setChecked(newStatus);
    answerChecklistItem({
      sendRequest,
      auth,
      setLoadedSaleChecklist,
      saleId,
      checklistId: kanbanChecklistId,
      itemId: kanbanChecklistItem?._id,
      answer: newStatus,
      setLoadedData,
    });
  };

  return (
    <Fragment>
      <ModalError error={error} onClear={clearError} />
      <div className="sale-dash__checklist-checkbox">
        {isLoading && <LoadingSpinnerOverlayRegular />}
        <p
          className={`sale-dash__checklist-task ${
            checked ? "sale-dash__checklist-task--done" : ""
          }`}
        >
          {kanbanChecklistItem?.task}
        </p>
        <Checkbox
          size="small"
          style={{ height: "35px", width: "35px" }}
          checked={checked}
          color="primary"
          onChange={changeAnswerHandler}
        />
      </div>
    </Fragment>
  );
};
