import { useEffect, Fragment } from "react";

import { useHttpClient } from "../../shared/hooks/httpHook";
import { useForm } from "../../shared/hooks/formHook";
import { InputSelectRequired } from "../../shared/components/FormElements/InputSelectRequired";
import { ModalError } from "../../shared/components/UIElements/ModalError";
import { LoadingSpinnerOverlayRegular } from "../../shared/components/UIElements/LoadingSpinnerOverlayRegular";
import { answerChecklistItem } from "../api/checklistsAPI";
import {
  AuthContextProps,
  ChecklistData,
  KanbanFilledData,
} from "../../shared/data/types";

import "./SaleDash.scss";

interface SaleDashChecklistListProps {
  auth: AuthContextProps;
  saleId: string;
  kanbanChecklistId: string;
  kanbanChecklistItem: ChecklistData["items"][0];
  saleChecklistItem?: ChecklistData["items"][0];
  setLoadedSaleChecklist: React.Dispatch<React.SetStateAction<ChecklistData>>;
  index: number;
  type:
    | "SALES"
    | "PROJECT"
    | "INSTALLATION"
    | "AFTER_SALES"
    | "PRE_SALES"
    | "ADMINISTRATIVE";
  setLoadedData: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
}

export const SaleDashChecklistList = (props: SaleDashChecklistListProps) => {
  const {
    auth,
    kanbanChecklistId,
    saleId,
    kanbanChecklistItem,
    saleChecklistItem,
    setLoadedSaleChecklist,
    index,
    setLoadedData,
  } = props;
  const { error, sendRequest, clearError, isLoading } = useHttpClient();
  const { formState, inputHandler } = useForm(
    {
      [`answer${index}`]: {
        value: saleChecklistItem?.answerString,
        isValid: !!saleChecklistItem?.answerString,
      },
    },
    false
  );

  useEffect(() => {
    if (
      kanbanChecklistItem?.list?.includes(
        formState?.inputs?.[`answer${index}`]?.value?.toString()?.trim()
      ) &&
      saleChecklistItem?.answerString?.trim() !==
        formState?.inputs?.[`answer${index}`]?.value?.toString()?.trim() &&
      formState?.inputs?.[`answer${index}`]?.isValid
    ) {
      answerChecklistItem({
        sendRequest,
        auth,
        setLoadedSaleChecklist,
        saleId,
        checklistId: kanbanChecklistId,
        itemId: kanbanChecklistItem?._id,
        answer: formState?.inputs?.[`answer${index}`]?.value as string,
        setLoadedData,
      });
    }
  }, [formState?.inputs?.[`answer${index}`]?.value]);

  return (
    <Fragment>
      <ModalError error={error} onClear={clearError} />
      <div className="sale-dash__checklist-field">
        {isLoading && <LoadingSpinnerOverlayRegular />}
        <p className="sale-dash__checklist-task">{kanbanChecklistItem?.task}</p>
        <InputSelectRequired
          size="small"
          id={`answer${index}`}
          label=""
          variant="outlined"
          options={kanbanChecklistItem?.list}
          initialValue={
            kanbanChecklistItem?.list?.includes(saleChecklistItem?.answerString)
              ? saleChecklistItem?.answerString || ""
              : ""
          }
          onInput={inputHandler}
          forceError={formState?.inputs?.[`answer${index}`]?.forceError}
          updateInitValue
          // onBlurHandler={onBlurHandler}
        />
      </div>
    </Fragment>
  );
};
