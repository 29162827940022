import { useState, useEffect } from "react";
import "date-fns";
import { ptBR } from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";

import { dateFormatFromISOString } from "../../util/dateFormatFromISOString";
import { compareDates } from "../../util/compareDates";

import { InputCalendarStyles } from "./InputCalendarStyles";

interface InputTimeProps {
  id: string;
  label: string;
  initialvalue?: string;
  disabled?: boolean;
  onInput: (id: string, value: string, isValid: boolean, label: string) => void;
}

export const InputTime = (props: InputTimeProps) => {
  const { id, label, initialvalue, disabled, onInput } = props;
  const classes = InputCalendarStyles();
  const today = new Date();
  const initRawDate = !!initialvalue ? new Date(initialvalue) : null;
  const initOffset = initRawDate?.getTimezoneOffset() * 60000 || 0;
  const initDate = !!initialvalue
    ? new Date(initRawDate?.getTime() + initOffset)
    : new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        0,
        0,
        0,
        0
      );
  const [selectedDate, setSelectedDate] = useState<Date | string | null>(
    initDate
  );

  const handleTimeChange = (date: Date | null) => {
    const tzoffset = date?.getTimezoneOffset() * 60000;
    const localDate = new Date(date?.getTime() - tzoffset);
    const localISOdate =
      !!localDate &&
      typeof localDate !== "string" &&
      !!date &&
      date?.toString() !== "Invalid Date"
        ? localDate?.toISOString() || ""
        : "";

    setSelectedDate(date);
    if (onInput && localISOdate) {
      onInput(
        id,
        !!date && date?.toString() !== "Invalid Date" ? localISOdate : "",
        !!date && date?.toString() !== "Invalid Date",
        label.charAt(0).toUpperCase() + label.slice(1)
      );
    }
  };

  useEffect(() => {
    handleTimeChange(selectedDate as Date);
  }, []);

  return (
    <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
      <KeyboardTimePicker
        className={classes.root}
        variant="inline"
        lang="pt-BR"
        disabled={disabled}
        id={id}
        label={label.charAt(0).toUpperCase() + label.slice(1)}
        value={selectedDate}
        helperText={
          !selectedDate
            ? "Data inválida."
            : selectedDate && selectedDate.toString() === "Invalid Date"
            ? "Data inválida."
            : ""
        }
        onChange={handleTimeChange}
        KeyboardButtonProps={{
          "aria-label": "mudar horas",
        }}
      />
    </MuiPickersUtilsProvider>
  );
};
