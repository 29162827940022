import { makeStyles } from "@material-ui/core/styles";

export const ModalPromptStyles = makeStyles(() => ({
  confirmModalTitle: {
    color: "#263351",
  },
  confirmModalContent: {
    color: "#263351",
  },
}));
