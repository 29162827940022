import { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import MoreHorizRoundedIcon from "@material-ui/icons/MoreHorizRounded";
import Collapse from "@material-ui/core/Collapse";

import { useHttpClient } from "../../shared/hooks/httpHook";
import { LoadingSpinnerOverlayRegular } from "../../shared/components/UIElements/LoadingSpinnerOverlayRegular";
import { MenuProposalOptions } from "./MenuProposalOptions";
import { addThousandSeparator } from "../../shared/util/addThousandSeparator";
import { ProposalData, AuthContextProps } from "../../shared/data/types";

import "./SaleDashProposalItem.scss";

interface SaleDashProposalItemProps {
  auth: AuthContextProps;
  loadedProposal: ProposalData;
  approvedProposalId: string;
  setLoadedData: React.Dispatch<React.SetStateAction<ProposalData[]>>;
}

export const SaleDashProposalItem = (props: SaleDashProposalItemProps) => {
  const { loadedProposal, auth, setLoadedData, approvedProposalId } = props;
  const { sendRequest, isLoading } = useHttpClient();
  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>(null);

  return (
    <div
      className={`sale-prop-item ${
        approvedProposalId === loadedProposal?.id
          ? "sale-prop-item--highlighted"
          : ""
      }`}
      onClick={(e) => {
        if (!anchorEl) {
          setOpen(!open);
        }
      }}
    >
      {isLoading && <LoadingSpinnerOverlayRegular image color="primary" />}
      <div className="sale-prop-item__data">
        <MenuProposalOptions
          auth={auth}
          setAnchorEl={setAnchorEl}
          anchorEl={anchorEl}
          proposalId={loadedProposal?.frontId}
          loadedProposal={loadedProposal}
          setLoadedData={setLoadedData}
          sendRequest={sendRequest}
          noDeleteOpt
        />
        <p className="sale-prop-item__data-item sale-prop-item__data-item--thin">
          {loadedProposal.count
            ? `#${loadedProposal.count?.toLocaleString("pt-BR", {
                minimumIntegerDigits: 5,
                useGrouping: false,
              })}`
            : "#000000"}
        </p>
        <p
          title={`${addThousandSeparator(
            loadedProposal?.realSystemData?.power || 0
          )} kWp`}
          className="sale-prop-item__data-item"
        >
          {addThousandSeparator(loadedProposal?.realSystemData?.power || 0)} kWp
        </p>
        <p
          title={`R$ ${addThousandSeparator(
            loadedProposal?.revenueData?.finalPrice || 0
          )}`}
          className="sale-prop-item__data-item sale-prop-item__data-item"
        >
          R${" "}
          {addThousandSeparator(loadedProposal?.revenueData?.finalPrice || 0)}
        </p>
      </div>
      <div className="sale-prop-item__controls">
        <IconButton
          size="small"
          title={open ? "Ver Mais" : "Fechar"}
          className={`table__item-action${open ? "--open" : ""}`}
          onClick={(e) => {
            if (!anchorEl) {
              setOpen(!open);
              e.stopPropagation();
            }
          }}
        >
          <ExpandMoreRoundedIcon style={{ fontSize: "22px" }} />
        </IconButton>
        <IconButton
          size="small"
          title="Opções"
          onClick={(e) => {
            setAnchorEl(e.currentTarget);
            e.stopPropagation();
          }}
        >
          <MoreHorizRoundedIcon style={{ fontSize: "22px" }} />
        </IconButton>
      </div>
      <div className="sale-prop-item__details">
        <Collapse in={open}>
          <div className="sale-prop-item__details-content">
            <p className="sale-prop-item__data-item sale-prop-item__data-item--thin">
              Visualizações:{" "}
              <span>{loadedProposal?.views ? loadedProposal?.views : 0}</span>
            </p>
            <p className="sale-prop-item__data-item sale-prop-item__data-item--thin">
              Cidade: <span>{loadedProposal?.location?.city || ""}</span>
            </p>
            {
              <p className="sale-prop-item__data-item sale-prop-item__data-item--thin">
                Validade:{" "}
                <span>
                  {loadedProposal?.proposalCustomizationData?.validityDate ||
                    "Sem Validade"}
                </span>
              </p>
            }
            <p className="sale-prop-item__data-item sale-prop-item__data-item--thin">
              Registro: <span>{loadedProposal?.registryDate || ""}</span>
            </p>
          </div>
        </Collapse>
      </div>
    </div>
  );
};
