import { Carousel } from "react-responsive-carousel";

import { ContentData, AuthContextProps } from "../../../shared/data/types";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./HomeCarouselStyles.scss";

interface HomeCarouselProps {
  carouselData: ContentData["homeCarousel"];
  auth: AuthContextProps;
}

export const HomeCarousel = (props: HomeCarouselProps) => {
  const { carouselData, auth } = props;

  const clickHandler = (url: string) => {
    if (!!url) {
      window.open(url, "_blank");
    }
  };

  return (
    <div className="home-carousel__container">
      {carouselData?.length > 0 && (
        <Carousel
          autoPlay
          showStatus={false}
          showThumbs={false}
          transitionTime={800}
          infiniteLoop
          swipeable={false}
          interval={6000}
          stopOnHover={false}
          showIndicators={carouselData?.length > 1}
        >
          {carouselData
            ?.filter((data) => {
              return !!data.path;
            })
            ?.filter((data) => {
              return (
                !data.clickUrl?.includes(
                  "api.whatsapp.com/send?phone=31992330282&text=Quero%20fazer%20upgrade!"
                ) || auth?.signupPlan !== "ENTERPRISE"
              );
            })
            ?.filter((data) => {
              return (
                !data.clickUrl?.includes("aldo") ||
                (auth?.whitelabel !== "SERRANA" && auth?.vendors?.length < 6)
              );
            })
            ?.filter((data) => {
              return (
                !data.clickUrl?.includes("serranasolar") ||
                auth?.whitelabel === "SERRANA"
              );
            })
            ?.map((data, i) => {
              return (
                <div
                  style={{
                    backgroundImage: `url(${`${process.env.REACT_APP_ASSET_URL}/${data.path}`})`,
                  }}
                  key={`carousel-container-${i}`}
                  className="home-carousel__slide"
                  onClick={() => {
                    clickHandler(data.clickUrl);
                  }}
                >
                  <img
                    className="home-carousel__slide-img"
                    key={`carousel-image-${i}`}
                    alt="Imagem carrossel"
                    src={`${`${process.env.REACT_APP_ASSET_URL}/${data.path}`}`}
                  />
                </div>
              );
            })}
        </Carousel>
      )}
    </div>
  );
};
