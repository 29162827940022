import Popover from "@material-ui/core/Popover";
import React, { useContext, Fragment, useState } from "react";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import { PopoverFormStyles } from "./PopoverFormStyles";
import { SettingsContext } from "../../shared/context/settingsContext";
import { addRejectReason, editRejectReason } from "../api/salesFunnelAPI";
import { AuthContextProps, KanbanFilledData } from "../../shared/data/types";

interface PopoverFormRejectReasonChoiceProps {
  itemId: string;
  reasonId?: string;
  item: KanbanFilledData["items"][0];
  anchorEl: HTMLButtonElement | HTMLLIElement | null;
  setAnchorEl: React.Dispatch<
    React.SetStateAction<HTMLButtonElement | HTMLLIElement>
  >;
  auth: AuthContextProps;
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean
  ) => Promise<any>;
  setKanbanData: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
}

export const PopoverFormRejectReasonChoice = (
  props: PopoverFormRejectReasonChoiceProps
) => {
  const {
    reasonId = null,
    item,
    anchorEl,
    setAnchorEl,
    auth,
    sendRequest,
    setKanbanData,
  } = props;
  const settingsCtx = useContext(SettingsContext);
  const open = Boolean(anchorEl);
  const classes = PopoverFormStyles();
  const [chosenReason, setChosenReason] = useState<string>(
    settingsCtx?.rejectReasons?.[0]
  );
  const rejectReasons = settingsCtx.rejectReasons;

  const changeValueHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChosenReason(e.target.value);
  };

  const confirmHandler = () => {
    if (!reasonId) {
      addRejectReason({
        sendRequest,
        auth,
        saleId: item?._id,
        reason: chosenReason,
        setKanbanData,
      });
    } else {
      editRejectReason({
        sendRequest,
        auth,
        saleId: item?._id,
        reasonId,
        reason: chosenReason,
        setKanbanData,
      });
    }
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <Popover
        id={item?._id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        style={{ zIndex: 502 }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <form className={classes.formRoot}>
          <FormControl>
            <InputLabel id="fetch-type-filter-selector-label">
              Selecionar motivo
            </InputLabel>
            <Select
              labelId="fetch-type-filter-selector-label"
              id="fetch-type-filter-selector"
              value={chosenReason}
              onChange={changeValueHandler}
            >
              {rejectReasons?.map((option) => {
                return (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="secondary"
            disabled={!chosenReason}
            onClick={() => {
              confirmHandler();
              setAnchorEl(null);
            }}
          >
            ADICIONAR REPROVA
          </Button>
        </form>
      </Popover>
    </Fragment>
  );
};
