import React from "react";
import Zoom from "@material-ui/core/Zoom";
import { useHistory } from "react-router-dom";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";

import "./ButtonChatStyles.css";

interface ButtonWhatsAppProps {
  sales?: boolean;
  zoom?: boolean;
}

export const ButtonWhatsApp = (props: ButtonWhatsAppProps) => {
  const { sales, zoom } = props;
  const history = useHistory();

  const trigger = useScrollTrigger({
    target: window,
    disableHysteresis: true,
    threshold: 100,
  });

  const clickHandler = () => {
    history.push("/contato");
  };

  return (
    <React.Fragment>
      {zoom && (
        <Zoom in={trigger}>
          <div className="chat-btn" onClick={clickHandler}>
            <img
              className="chat-btn__img"
              src={`${process.env.PUBLIC_URL}/avatar-support.png`}
              alt="Suporte"
            />
          </div>
        </Zoom>
      )}
      {!zoom && (
        <div className="chat-btn" onClick={clickHandler}>
          <img
            className="chat-btn__img"
            src={`${process.env.PUBLIC_URL}/avatar-support.png`}
            alt="Suporte"
          />
        </div>
      )}
    </React.Fragment>
  );
};
