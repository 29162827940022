import { getTimestampFromDate } from "./getTimestampFromDate";

export const getProportionalPrice = (
  anualPrice: number,
  discountMultiplier: number,
  addedVendors: number = 2,
  validityDate: string
) => {
  const totalAnualPrice = anualPrice * addedVendors * (discountMultiplier || 1);
  const today = new Date();
  const startOfYear = new Date(today.getFullYear(), 0, 1);
  const endOfYear = new Date(today.getFullYear(), 11, 31);
  const totalTimeInYear = Math.abs(endOfYear.getTime() - startOfYear.getTime());
  const totalTimeLeft = Math.abs(
    getTimestampFromDate(validityDate) - today.getTime()
  );
  const proportion = totalTimeLeft / totalTimeInYear;
  const proportionalPrice = totalAnualPrice * proportion;

  return Math.ceil(proportionalPrice);
};
