import {
  FormHookDispState,
  SettingsContextProps,
  FeeData,
} from "../../shared/data/types";

export const calcSimValues = (
  formState: FormHookDispState,
  loadedFees: FeeData[]
) => {
  const chosenFee = loadedFees.find((fee) => {
    return fee.powerDistCompany === formState.inputs?.powerDistCompany?.value;
  });

  let kwhPrice = 0;
  let kwhPricePeak = 0;
  let avgConsumption = 0;
  let avgConsumptionPeak = 0;
  let demandPrice = 0;
  let demandPricePeak = 0;
  let bWire = 0;
  if (
    !!chosenFee &&
    formState.inputs?.tariffModality?.value === "Grupo B (Baixa Tensão)"
  ) {
    kwhPrice = chosenFee?.b1Tariffs?.tarif || 0;
    avgConsumption = +formState.inputs?.energyBillPrice?.value / kwhPrice || 0;
    bWire =
      chosenFee?.b1Tariffs?.bWireNoTax /
      ((1 - chosenFee?.taxes?.imcs) *
        (1 - (chosenFee?.taxes?.pis + chosenFee?.taxes?.cofins)));
  } else if (
    !!chosenFee &&
    formState.inputs?.tariffModality?.value === "Grupo A (Alta Tensão)"
  ) {
    if (formState.inputs?.classification?.value === "Verde") {
      kwhPrice =
        chosenFee?.a4Tariffs?.green?.nonPeak?.tusd +
          chosenFee?.a4Tariffs?.green?.nonPeak?.te || 0;

      kwhPricePeak =
        chosenFee?.a4Tariffs?.green?.peak?.tusd +
          chosenFee?.a4Tariffs?.green?.peak?.te || 0;

      avgConsumption =
        +formState.inputs?.energyBillPrice?.value / kwhPrice || 0;
      avgConsumptionPeak =
        +formState.inputs?.energyBillPricePeak?.value / kwhPricePeak || 0;

      demandPrice =
        +formState.inputs?.demand?.value * chosenFee.a4Tariffs?.green?.demand;
    } else if (formState.inputs?.classification?.value === "Azul") {
      kwhPrice =
        chosenFee?.a4Tariffs?.blue?.nonPeak?.tusd +
          chosenFee?.a4Tariffs?.blue?.nonPeak?.te || 0;

      kwhPricePeak =
        chosenFee?.a4Tariffs?.blue?.peak?.tusd +
          chosenFee?.a4Tariffs?.blue?.peak?.te || 0;

      avgConsumption =
        +formState.inputs?.energyBillPrice?.value / kwhPrice || 0;
      avgConsumptionPeak =
        +formState.inputs?.energyBillPricePeak?.value / kwhPricePeak || 0;

      demandPrice =
        +formState.inputs?.demand?.value *
        chosenFee?.a4Tariffs?.blue?.nonPeak?.demand;
      demandPricePeak =
        +formState.inputs?.demandPeak?.value *
        chosenFee?.a4Tariffs?.blue?.peak?.demand;
    }
  }

  return {
    kwhPrice,
    kwhPricePeak,
    avgConsumption,
    avgConsumptionPeak,
    demandPrice,
    demandPricePeak,
    bWire,
  };
};
