import { useCallback } from "react";
import { openDB, IDBPDatabase } from "idb";

export const useIdbStorage = () => {
  const openIndexedDBHandlerComp = useCallback(async () => {
    return await openDB("azume-dynamic-data", 17, {
      upgrade(db) {
        if (!db?.objectStoreNames?.contains("home")) {
          db?.createObjectStore("home", { keyPath: "idbId" });
        }
        if (!db?.objectStoreNames?.contains("admin")) {
          db?.createObjectStore("admin", { keyPath: "idbId" });
        }
        if (!db?.objectStoreNames?.contains("payments")) {
          db?.createObjectStore("payments", { keyPath: "idbId" });
        }
        if (!db?.objectStoreNames?.contains("customers")) {
          db?.createObjectStore("customers", { keyPath: "idbId" });
        }
        if (!db?.objectStoreNames?.contains("estimates")) {
          db?.createObjectStore("estimates", { keyPath: "idbId" });
        }
        if (!db?.objectStoreNames?.contains("proposals")) {
          db?.createObjectStore("proposals", { keyPath: "idbId" });
        }
        if (!db?.objectStoreNames?.contains("kits")) {
          db?.createObjectStore("kits", { keyPath: "idbId" });
        }
        if (!db?.objectStoreNames?.contains("templates")) {
          db?.createObjectStore("templates", { keyPath: "idbId" });
        }
        if (!db?.objectStoreNames?.contains("funnels")) {
          db?.createObjectStore("funnels", { keyPath: "idbId" });
        }
        if (!db?.objectStoreNames?.contains("schedules")) {
          db?.createObjectStore("schedules", { keyPath: "idbId" });
        }
        if (!db?.objectStoreNames?.contains("finalEstimates")) {
          db?.createObjectStore("finalEstimates", { keyPath: "idbId" });
        }
        if (!db?.objectStoreNames?.contains("finalProposals")) {
          db?.createObjectStore("finalProposals", { keyPath: "idbId" });
        }
        if (!db?.objectStoreNames?.contains("userSettings")) {
          db?.createObjectStore("userSettings", { keyPath: "idbId" });
        }
      },
    });
  }, []);

  const readAllDataInIdb = useCallback(
    async (idb: IDBPDatabase, st: string) => {
      const tx = idb?.transaction(st, "readonly");
      const store = tx?.objectStore(st);
      return await store?.getAll();
    },
    []
  );

  const readSingleEntry = useCallback(
    async (idb: IDBPDatabase, st: string, id: string) => {
      const tx = idb?.transaction(st, "readonly");
      const store = tx?.objectStore(st);
      return await store?.get(id);
    },
    []
  );

  return { openIndexedDBHandlerComp, readAllDataInIdb, readSingleEntry };
};
