import {
  ContractData,
  AuthContextProps,
  FormHookDispState,
} from "../../shared/data/types";

interface FetchProps {
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean,
    zeroResultsKey?: string
  ) => Promise<any>;
  auth: AuthContextProps;
}

// GET USER CONTRACTS /////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////
interface GetUserContractsProps extends FetchProps {
  setLoadedContracts: React.Dispatch<React.SetStateAction<ContractData[]>>;
}

export const getUserContracts = async (props: GetUserContractsProps) => {
  const { sendRequest, auth, setLoadedContracts } = props;

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/contracts/user/${auth.userId}`;
    const responseData: { contracts: ContractData[] } = await sendRequest(
      apiUrl,
      "GET",
      null,
      { Authorization: "Bearer " + auth.token }
    );
    setLoadedContracts(responseData.contracts);
  } catch (err) {}
};

// GET SINGLE CONTRACT ////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////
interface GetSingleContractProps extends FetchProps {
  setLoadedContract: React.Dispatch<React.SetStateAction<ContractData>>;
  contractId: string;
}

export const getSingleContract = async (props: GetSingleContractProps) => {
  const { sendRequest, auth, setLoadedContract, contractId } = props;

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/contracts/single/${auth.userId}/${contractId}`;
    const responseData: { contract: ContractData } = await sendRequest(
      apiUrl,
      "GET",
      null,
      { Authorization: "Bearer " + auth.token }
    );

    setLoadedContract(responseData.contract);
  } catch (err) {}
};

// CREATE CONTRACT ////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////
interface CreateContractProps extends FetchProps {
  setLoadedContracts: React.Dispatch<React.SetStateAction<ContractData[]>>;
  file: File;
  name: string;
}

export const createContract = async (props: CreateContractProps) => {
  const { sendRequest, auth, setLoadedContracts, file, name } = props;

  const formData = new FormData();
  formData.append("file", file);
  formData.append("name", name);

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/contracts/new/${auth.userId}`;
    const responseData: { contract: ContractData } = await sendRequest(
      apiUrl,
      "POST",
      formData,
      {
        Authorization: "Bearer " + auth.token,
      },
      true
    );

    setLoadedContracts((prevValues) => {
      return [...prevValues, responseData.contract];
    });
  } catch (err) {}
};

// EDIT CONTRACT //////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////
interface EditContractProps extends FetchProps {
  file: File;
  name: string;
  contractId: string;
  setLoadedContracts: React.Dispatch<React.SetStateAction<ContractData[]>>;
}

export const editContract = async (props: EditContractProps) => {
  const { sendRequest, auth, file, name, contractId, setLoadedContracts } =
    props;

  const formData = new FormData();
  formData.append("file", file);
  formData.append("name", name);

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/contracts/single/${auth.userId}/${contractId}`;
    const responseData: { contract: ContractData } = await sendRequest(
      apiUrl,
      "PUT",
      formData,
      {
        Authorization: "Bearer " + auth.token,
      },
      true
    );

    setLoadedContracts((prevValues) => {
      return prevValues.map((c) => {
        if (c._id === contractId) {
          c = responseData.contract;
        }

        return c;
      });
    });
  } catch (err) {}
};

// DELETE CONTRACT ////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////
interface DeleteContractProps extends FetchProps {
  contractId: string;
  setLoadedContracts: React.Dispatch<React.SetStateAction<ContractData[]>>;
}

export const deleteContract = async (props: DeleteContractProps) => {
  const { sendRequest, auth, contractId, setLoadedContracts } = props;

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/contracts/single/${auth.userId}/${contractId}`;
    await sendRequest(apiUrl, "DELETE", null, {
      Authorization: "Bearer " + auth.token,
    });

    setLoadedContracts((prevValues) => {
      return [
        ...prevValues.filter((contract) => {
          return contract._id !== contractId;
        }),
      ];
    });
  } catch (err) {}
};
