import { calcSimValues } from "../util/calcSimValues";
import {
  SimulationResultsData,
  FormHookDispState,
  SettingsContextProps,
  TariffData,
  WebsiteData,
  FeeData,
  History,
} from "../../shared/data/types";
import { ca } from "date-fns/locale";

interface FetchProps {
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean,
    zeroResultsKey?: string
  ) => Promise<any>;
}

interface SimulatePVSystemProps extends FetchProps {
  setLoadedResults: React.Dispatch<React.SetStateAction<SimulationResultsData>>;
  formState: FormHookDispState;
  loadedFees: FeeData[];
  uid: string;
}

export const simulatePVSystem = async (props: SimulatePVSystemProps) => {
  const { sendRequest, setLoadedResults, formState, loadedFees, uid } = props;

  const {
    kwhPrice,
    kwhPricePeak,
    avgConsumption,
    avgConsumptionPeak,
    demandPrice,
    demandPricePeak,
    bWire,
  } = calcSimValues(formState, loadedFees);

  const tariffModality =
    formState.inputs?.tariffModality?.value === "Grupo B (Baixa Tensão)"
      ? "B"
      : "A";

  try {
    const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/websites/simulation/${uid}`;
    const responseData: { simulationResults: SimulationResultsData } =
      await sendRequest(
        apiUrl,
        "POST",
        JSON.stringify({
          name: formState.inputs?.name?.value || "",
          phone: formState.inputs?.phone?.value || "",
          uf: formState.inputs?.uf?.value || "",
          city: formState.inputs?.city?.value || "",
          lat: +formState.inputs?.lat?.value || 0,
          lng: +formState.inputs?.lng?.value || 0,
          ucType: formState.inputs?.ucType?.value || "",
          tariffModality,
          powerDistCompany: formState.inputs?.powerDistCompany?.value || "",
          classification: formState.inputs?.classification?.value || "",
          kwhPrice,
          kwhPricePeak,
          avgConsumption,
          avgConsumptionPeak,
          demandPrice,
          demandPricePeak,
          bWireValue: bWire,
        }),
        {
          "Content-Type": "application/json",
        }
      );

    setLoadedResults(responseData.simulationResults);
  } catch (err) {}
};

interface GetWebsiteTemplateProps extends FetchProps {
  setLoadedWebsite: React.Dispatch<React.SetStateAction<WebsiteData>>;
  uid: string;
}

export const getWebsiteTemplate = async (props: GetWebsiteTemplateProps) => {
  const { sendRequest, uid, setLoadedWebsite } = props;

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/websites/user/${uid}`;
    const responseData: { website: WebsiteData } = await sendRequest(
      apiUrl,
      "GET",
      null,
      {}
    );
    setLoadedWebsite(responseData.website);
  } catch (err) {}
};

interface GetWebsiteTemplateBySlugProps extends FetchProps {
  setLoadedWebsite: React.Dispatch<React.SetStateAction<WebsiteData>>;
  slug: string;
}

export const getWebsiteTemplateBySlug = async (
  props: GetWebsiteTemplateBySlugProps
) => {
  const { sendRequest, slug, setLoadedWebsite } = props;

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/websites/user-by-slug/${slug}`;
    const responseData: { website: WebsiteData } = await sendRequest(
      apiUrl,
      "GET",
      null,
      {}
    );
    setLoadedWebsite(responseData.website);
  } catch (err) {}
};

interface GetCustomDomainRedirectsProps extends FetchProps {
  locationDomain: string;
  setCustomDomainUserId: React.Dispatch<React.SetStateAction<string>>;
}

export const getCustomDomainRedirects = async (
  props: GetCustomDomainRedirectsProps
) => {
  const { sendRequest, setCustomDomainUserId, locationDomain } = props;
  const cachedDomains: {
    domains: {
      domain: string;
      redirectId: string;
    }[];
  } = JSON.parse(localStorage.getItem("domains"));

  if (cachedDomains?.domains) {
    const domainUserId = cachedDomains.domains?.find((cd) => {
      return cd.domain.includes(locationDomain);
    })?.redirectId;
    setCustomDomainUserId(domainUserId);
  }

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/websites/domains`;
    const responseData: {
      domains: {
        domain: string;
        redirectId: string;
      }[];
    } = await sendRequest(apiUrl, "GET", null, {});

    if (responseData.domains) {
      const domainUserId = responseData.domains?.find((cd) => {
        return cd.domain.includes(locationDomain);
      })?.redirectId;
      setCustomDomainUserId(domainUserId);
    }

    localStorage.setItem(
      "domains",
      JSON.stringify({
        domains: responseData.domains,
      })
    );
  } catch (err) {}
};
