export const dateFormatToISOString = (date: string) => {
  if (!!date && date.length >= 10) {
    const dateArray = date.substring(0, 10).split("/");
    const dateISOString = new Date(
      +dateArray[2],
      +dateArray[1] - 1,
      +dateArray[0]
    ).toISOString();

    return dateISOString;
  }
  return "";
};
