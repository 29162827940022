import { FormHookDispState } from "../data/types";

export const updateFormValidity = (formState: FormHookDispState) => {
  let isValid = true;

  for (const key in formState.inputs) {
    isValid = isValid && formState.inputs[key]?.isValid;
  }

  return isValid;
};

export const updateNewFormDataValidity = (formState: {
  [key: string]: {
    value: string | boolean;
    isValid: boolean;
  };
}) => {
  let isValid = true;

  for (const key in formState) {
    isValid = isValid && formState?.[key]?.isValid;
  }

  return isValid;
};
