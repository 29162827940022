import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Switch from "@material-ui/core/Switch";
import CircularProgress from "@material-ui/core/CircularProgress";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Popover from "@material-ui/core/Popover";
import FilterListRoundedIcon from "@material-ui/icons/FilterListRounded";

import { InputCalendar } from "../../shared/components/FormElements/InputCalendar";
import { Card } from "../../shared/components/UIElements/Card";
import { getFilteredSaleNotes } from "../api/salesFunnelAPI";
import {
  processSelectedEarlierTimePeriodHandler,
  processSelecteOlderdTimePeriodHandler,
} from "../../customer/util/filteringHandlers";
import { NOTE_CALENDAR } from "../../shared/data/static";
import {
  SettingsContextProps,
  AuthContextProps,
  KanbanFilledData,
  TimePeriodData,
  NotePopCustData,
} from "../../shared/data/types";

import "../../shared/components/UIElements/SearchBarGeneralStyles.scss";

interface SaleDashNotesFiltersProps {
  saleId: string;
  settingsCtx: SettingsContextProps;
  auth: AuthContextProps;
  isLoading: boolean;
  setLoadedData: React.Dispatch<React.SetStateAction<NotePopCustData[]>>;
  setShowLoadMore: React.Dispatch<React.SetStateAction<boolean>>;
  setFiltered: React.Dispatch<React.SetStateAction<boolean>>;
  setMultiplier: React.Dispatch<React.SetStateAction<number>>;
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean
  ) => Promise<any>;
  noteType: string;
  setNoteType: React.Dispatch<React.SetStateAction<string>>;
  noteCalendar: "TODAS AS NOTAS" | "NÃO AGENDADAS" | "AGENDADAS";
  setNoteCalendar: React.Dispatch<
    React.SetStateAction<"TODAS AS NOTAS" | "NÃO AGENDADAS" | "AGENDADAS">
  >;
  filter1Active: boolean;
  setFilter1Active: React.Dispatch<React.SetStateAction<boolean>>;
  filter1Value: TimePeriodData;
  setFilter1Value: React.Dispatch<React.SetStateAction<TimePeriodData>>;
  firstLoad: boolean;
  type:
    | "SALES"
    | "PROJECT"
    | "INSTALLATION"
    | "AFTER_SALES"
    | "PRE_SALES"
    | "ADMINISTRATIVE";
}

export const SaleDashNotesFilters = (props: SaleDashNotesFiltersProps) => {
  const {
    saleId,
    settingsCtx,
    auth,
    isLoading,
    setLoadedData,
    setShowLoadMore,
    sendRequest,
    setFiltered,
    setMultiplier,
    noteType,
    setNoteType,
    noteCalendar,
    setNoteCalendar,
    filter1Active,
    setFilter1Active,
    filter1Value,
    setFilter1Value,
    firstLoad,
    type,
  } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>(null);
  const vendorsOptions =
    auth.vendors?.map((vendor) => {
      return {
        value: vendor._id,
        option: `${vendor.personalData?.name} - ${vendor.email}`,
      };
    }) || [];

  const allFetchOptions = [
    { value: auth.userId, option: "GERENTE" },
    ...vendorsOptions,
    { value: auth.managerId, option: "TODOS OS DADOS" },
  ];

  const changeNoteTypeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNoteType(e.target.value);
  };

  const changeNoteCalendarHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNoteCalendar(
      e.target.value as "TODAS AS NOTAS" | "NÃO AGENDADAS" | "AGENDADAS"
    );
  };

  const selectedEarlierTimePeriodHandler = (date: string) => {
    processSelectedEarlierTimePeriodHandler(date, setFilter1Value);
  };

  const selecteOlderdTimePeriodHandler = (date: string) => {
    processSelecteOlderdTimePeriodHandler(date, setFilter1Value);
  };

  const getFilteredNotessHandler = () => {
    getFilteredSaleNotes({
      saleId,
      sendRequest,
      auth,
      setLoadedData,
      setShowLoadMore,
      setFiltered,
      multiplier: 0,
      noteType,
      noteCalendar,
      filter1Active,
      filter1Value,
      setMultiplier,
      type,
    });
  };

  return (
    <div className="">
      <div className="sale-dash__filters-btn">
        <Button
          className="table-metrics__filter-btn"
          onClick={(e) => {
            setAnchorEl(e.currentTarget);
          }}
          color="primary"
          variant="outlined"
        >
          {!isLoading ? (
            <FilterListRoundedIcon />
          ) : (
            <CircularProgress size={20} color="primary" />
          )}
          &nbsp;&nbsp; FILTROS
        </Button>
      </div>
      <Popover
        id="filters-customers"
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div className="sale-dash__filters-popover">
          <FormControl>
            <InputLabel id="fetch-type-filter-selector-label">
              Tipo de nota
            </InputLabel>
            <Select
              labelId="fetch-type-filter-selector-label"
              id="fetch-type-filter-selector"
              value={noteType}
              onChange={changeNoteTypeHandler}
            >
              {["TODOS", ...settingsCtx?.notesTypes]?.map((option) => {
                return (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="fetch-type-filter-selector-label">
              Status de Agendamento
            </InputLabel>
            <Select
              labelId="fetch-type-filter-selector-label"
              id="fetch-type-filter-selector"
              value={noteCalendar}
              onChange={changeNoteCalendarHandler}
            >
              {NOTE_CALENDAR?.map((option) => {
                return (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <div>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    checked={filter1Active}
                    color="primary"
                    id="timePeriod"
                    onChange={() => {
                      setFilter1Active(!filter1Active);
                    }}
                    name="timePeriod"
                  />
                }
                label="Filtro Período"
              />
            </FormGroup>
            {filter1Active && (
              <div className="searchbar-time-period-container-no-margin-top">
                <InputCalendar
                  id="time-period-start"
                  label="Início período"
                  format="dd/MM/yy"
                  initialvalue={filter1Value.timePeriodEarlierSelected}
                  selectedEarlierTimePeriodHandler={
                    selectedEarlierTimePeriodHandler
                  }
                />
                <InputCalendar
                  id="time-period-end"
                  label="Final período"
                  format="dd/MM/yy"
                  initialvalue={filter1Value.timePeriodOlderSelected}
                  selecteOlderdTimePeriodHandler={
                    selecteOlderdTimePeriodHandler
                  }
                />
              </div>
            )}
          </div>

          {(!isLoading || !firstLoad) && (
            <Button
              disabled={!firstLoad}
              color="primary"
              variant="outlined"
              onClick={getFilteredNotessHandler}
            >
              FILTRAR
            </Button>
          )}
          {isLoading && firstLoad && (
            <div
              className="searchbar-loading-spinner"
              style={{ marginTop: "10px" }}
            >
              <CircularProgress color="primary" />
            </div>
          )}
        </div>
      </Popover>
    </div>
  );
};
