import { useRef, useState, useEffect } from "react";
import FindInPageRoundedIcon from "@material-ui/icons/FindInPageRounded";

import "./FileUploadStyles.css";

interface FileUploadProps {
  id: string;
  initialFileName?: string;
  initialImage?: string;
  notRequired?: boolean;
  showImgPreview?: boolean;
  accept?: string;
  onInput: (
    id: string,
    value: string | boolean | File,
    isValid: boolean,
    label: string
  ) => void;
}

export const FileUpload = (props: FileUploadProps) => {
  const {
    id,
    initialFileName,
    onInput,
    initialImage,
    notRequired = false,
    showImgPreview = false,
    accept,
  } = props;
  const filePickerRef = useRef<HTMLInputElement>(null);
  const imgRef1 = useRef<HTMLImageElement>(null);
  const imgRef2 = useRef<HTMLImageElement>(null);
  const [file, setFile] = useState<File>(null);
  const [fileName, setFileName] = useState<string>(null);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [previewUrl, setPreviewUrl] = useState<string>(null);
  const [initImage, setinitImage] = useState<string>(initialImage);
  const [initFileName, setinitFileName] = useState<string>(
    initialFileName || ""
  );

  useEffect(() => {
    if (!file) {
      return;
    }
    const fileReader = new FileReader();
    fileReader.onload = () => {
      if (
        typeof fileReader.result === "string" &&
        fileReader.result?.includes("data:image")
      ) {
        setPreviewUrl(fileReader.result);
        setinitImage(null);
      } else {
        setinitImage(null);
        setPreviewUrl(null);
      }
    };
    fileReader.readAsDataURL(file);
  }, [file]);

  const loadImg1Handler = () => {
    if (imgRef1?.current) {
      if (imgRef1?.current?.clientHeight < imgRef1?.current?.clientWidth) {
        imgRef1?.current?.classList?.add("file-up-img--wide");
      } else {
        imgRef1?.current?.classList?.remove("file-up-img--wide");
      }
    }
  };

  const loadImg2Handler = () => {
    if (imgRef2?.current) {
      if (imgRef2?.current?.clientHeight < imgRef2?.current?.clientWidth) {
        imgRef2?.current?.classList?.add("file-up-img--wide");
      } else {
        imgRef2?.current?.classList?.remove("file-up-img--wide");
      }
    }
  };

  useEffect(() => {
    if (file) {
      setFileName(file.name);
    }
  }, [file]);

  const pickedFileHandler = (event: React.FormEvent<HTMLInputElement>) => {
    let pickedFile: File = null;
    let fileIsValid = isValid;
    if (event.currentTarget.files && event.currentTarget.files.length === 1) {
      pickedFile = event.currentTarget.files[0];
      setFile(pickedFile);
      setIsValid(true);
      fileIsValid = true;
      onInput(id, pickedFile, !notRequired ? fileIsValid : true, "Arquivo");
      setinitFileName(null);
    }
  };

  const pickFileHandler = () => {
    filePickerRef.current.click();
  };

  return (
    <div className="file-upload">
      {!accept && (
        <input
          id={id}
          ref={filePickerRef}
          className="file-upload__input"
          type="file"
          onChange={pickedFileHandler}
        />
      )}
      {!!accept && (
        <input
          id={id}
          ref={filePickerRef}
          className="file-upload__input"
          type="file"
          accept={accept}
          onChange={pickedFileHandler}
        />
      )}
      <div className="file-upload__preview-container">
        <div className="file-upload__preview">
          {fileName || initFileName || "Nenhum arquivo"}
        </div>
        <button
          className="file-upload__btn"
          title="Escolher arquivo"
          onClick={(e) => {
            e.preventDefault();
            pickFileHandler();
          }}
        >
          <FindInPageRoundedIcon />
        </button>
      </div>
      {showImgPreview && !previewUrl && !initImage && (
        <div className="image-upload center">
          <div className={`file-upload__img-preview file`}>
            <img
              src={`${process.env.PUBLIC_URL}/file-placeholder.png`}
              className={`file-up-img`}
              alt="imagem"
              title="imagem"
            />
          </div>
        </div>
      )}
      {showImgPreview && previewUrl && (
        <div className="image-upload center">
          <div className={`file-upload__img-preview file`}>
            <img
              ref={imgRef1}
              src={previewUrl}
              className={`file-up-img`}
              alt="imagem"
              title="imagem"
              onLoad={loadImg1Handler}
            />
          </div>
        </div>
      )}
      {showImgPreview && !!initImage && !previewUrl && (
        <div className="image-upload center">
          <div className={`file-upload__img-preview file`}>
            <img
              ref={imgRef2}
              src={`${process.env.REACT_APP_ASSET_URL}/${initImage}`}
              className={`file-up-img`}
              alt="imagem"
              title="imagem"
              onLoad={loadImg2Handler}
            />
          </div>
        </div>
      )}
    </div>
  );
};
