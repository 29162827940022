import { Fragment, useState } from "react";
import DeleteForeverRoundedIcon from "@material-ui/icons/DeleteForeverRounded";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import NotificationsActiveRoundedIcon from "@material-ui/icons/NotificationsActiveRounded";
import FileCopyRoundedIcon from "@material-ui/icons/FileCopyRounded";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import DoneAllIcon from "@material-ui/icons/DoneAll";

import { ModalSuccess } from "../../shared/components/UIElements/ModalSuccess";
import { SnackbarAlert } from "../../shared/components/UIElements/SnackbarAlert";
import { ModalError } from "../../shared/components/UIElements/ModalError";
import { ModalConfirm } from "../../shared/components/UIElements/ModalConfirm";
import { MenuNotifySignaturePerson } from "./MenuNotifySignaturePerson";
import { LoadingSpinnerOverlayRegular } from "../../shared/components/UIElements/LoadingSpinnerOverlayRegular";
import { PopoverAddSignaturePerson } from "./PopoverAddSignaturePerson";
import { useHttpClient } from "../../shared/hooks/httpHook";
import { formatCpfOrCnpj } from "../../shared/util/formatCpfOrCnpj";
import { deletePersonFromSignature } from "../api/signatureAPI";
import {
  AuthContextProps,
  KanbanFilledData,
  SignatureData,
} from "../../shared/data/types";

import "./SaleDashSignature.scss";

interface SaleDashSignaturePersonProps {
  auth: AuthContextProps;
  saleId: string;
  loadedContract: KanbanFilledData["items"][0]["contracts"][0];
  loadedSignature: SignatureData;
  person: SignatureData["persons"][0];
  setLoadedSignature: React.Dispatch<React.SetStateAction<SignatureData>>;
  loadedDownloadUrls: {
    original_file_url: string;
    signed_file_url: string | undefined;
    ziped_file_url: string;
  };
  personsWhoSigned: string[];
}

export const SaleDashSignaturePerson = (
  props: SaleDashSignaturePersonProps
) => {
  const {
    loadedContract,
    loadedSignature,
    auth,
    saleId,
    person,
    setLoadedSignature,
    loadedDownloadUrls,
    personsWhoSigned,
  } = props;
  const { error, sendRequest, clearError, isLoading, success, clearSuccess } =
    useHttpClient();
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | HTMLDivElement>(
    null
  );
  const [anchorElNot, setAnchorElNot] = useState<
    HTMLButtonElement | HTMLDivElement
  >(null);
  const [openMessage, setOpenMessage] = useState<boolean>(false);

  const closeConfirmHandler = () => {
    setConfirmDelete(false);
  };

  const confirmHandler = () => {
    deletePersonFromSignature({
      sendRequest,
      auth,
      saleId,
      saleContractId: loadedContract?._id,
      personId: person?._id,
      setLoadedSignature,
    });
    setConfirmDelete(false);
  };

  const copyToClipboard = (copyText: string) => {
    navigator.clipboard.writeText(copyText);
    setOpenMessage(true);
  };

  return (
    <Fragment>
      <SnackbarAlert
        openMessage={openMessage}
        setOpenMessage={setOpenMessage}
        severity="success"
        txt="Link da assinatura do contrato copiado!"
      />
      <ModalError error={error} onClear={clearError} />
      <ModalConfirm
        open={confirmDelete}
        closeHandler={closeConfirmHandler}
        confirmHandler={confirmHandler}
        message="Deseja excluir permanentemente este signatário?"
      />
      <PopoverAddSignaturePerson
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        auth={auth}
        setLoadedSignature={setLoadedSignature}
        saleId={saleId}
        loadedContract={loadedContract}
        loadedPerson={person}
      />
      <MenuNotifySignaturePerson
        auth={auth}
        sendRequest={sendRequest}
        anchorEl={anchorElNot}
        setAnchorEl={setAnchorElNot}
        saleId={saleId}
        loadedContract={loadedContract}
        loadedPerson={person}
        loadedSignature={loadedSignature}
        setLoadedSignature={setLoadedSignature}
      />
      <ModalSuccess
        success={success}
        onClear={clearSuccess}
        message="Notificação da assinatura digital enviada com sucesso!"
        dontPush
        reset={() => {}}
      />
      <div
        className={`signature__person ${
          personsWhoSigned?.includes(person._id)
            ? "signature__person--disabled"
            : ""
        }`}
      >
        {isLoading && <LoadingSpinnerOverlayRegular image />}
        {(!loadedSignature?.clicksignKey ||
          loadedSignature?.clicksignKey === "") && (
          <div className="signature__person-actions">
            <div
              title="Editar signatário"
              className="signature__person-action signature__person-action--edit"
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
              }}
            >
              <EditRoundedIcon />
            </div>
            <div
              title="Excluir signatário"
              className="signature__person-action signature__person-action--delete"
              onClick={() => {
                setConfirmDelete(true);
              }}
            >
              <DeleteForeverRoundedIcon />
            </div>
          </div>
        )}
        {!!loadedSignature?.clicksignKey &&
          !personsWhoSigned?.includes(person._id) && (
            <div className="signature__person-actions">
              <div
                title={
                  !person?.notificationSent
                    ? "Notificar signatário"
                    : "Notificação enviada!"
                }
                className={`signature__person-action ${
                  !person?.notificationSent
                    ? "signature__person-action--edit"
                    : "signature__person-action--success"
                } ${
                  loadedDownloadUrls?.signed_file_url
                    ? "signature__person-action--disabled"
                    : ""
                }`}
                onClick={(e) => {
                  if (!loadedDownloadUrls?.signed_file_url) {
                    setAnchorElNot(e.currentTarget);
                  }
                }}
              >
                {!person?.notificationSent ? (
                  <NotificationsActiveRoundedIcon />
                ) : (
                  <CheckCircleOutlineRoundedIcon />
                )}
              </div>
              <div
                title="Copiar link de assinatura"
                className={`signature__person-action signature__person-action--delete ${
                  loadedDownloadUrls?.signed_file_url
                    ? "signature__person-action--disabled"
                    : ""
                }`}
                onClick={() => {
                  if (!loadedDownloadUrls?.signed_file_url) {
                    copyToClipboard(person?.clicksignSignUrl);
                  }
                }}
              >
                <FileCopyRoundedIcon />
              </div>
            </div>
          )}
        {!!loadedSignature?.clicksignKey &&
          personsWhoSigned?.includes(person._id) && (
            <div className="signature__person-actions">
              <div
                title={"Assinatura Realizada!"}
                className={`signature__person-action signature__person-action--disabled`}
                onClick={(e) => {}}
              >
                <CheckCircleRoundedIcon />
              </div>
            </div>
          )}
        <div className="signature__person-data">
          <p className="signature__person-data-item">
            {person?.name + " " + person?.surname || ""}
          </p>
          <p className="signature__person-data-item">{person?.email || ""}</p>
          <p className="signature__person-data-item">
            {formatCpfOrCnpj(person?.document)}
          </p>
          <p className="signature__person-data-item">{person?.phone}</p>
          <p className="signature__person-data-item">{`Autenticação via ${
            person?.auth === "email"
              ? "E-mail"
              : person?.auth === "whatsapp"
              ? "WhatsApp"
              : "SMS"
          }`}</p>
        </div>
      </div>
    </Fragment>
  );
};
