import { withStyles, WithStyles, createStyles } from "@material-ui/core/styles";
import { Legend } from "@devexpress/dx-react-chart-material-ui";

const legendStyles = createStyles({
  root: {
    display: "flex",
    margin: "auto",
    flexDirection: "row",
  },
});

const legendStylesMobile = createStyles({
  root: {
    display: "flex",
    margin: "auto",
    flexDirection: "column",
    "& span": {
      fontSize: "0.8rem",
    },
  },
});

const legendRootBase = (props: WithStyles<typeof legendStyles>) => {
  const { classes, ...restProps } = props;
  return (
    <Legend.Root
      {...restProps}
      children={restProps["children"]}
      className={classes.root}
    />
  );
};

const Root = withStyles(legendStyles, { name: "LegendRoot" })(
  legendRootBase
) as unknown as React.ComponentType<any>;

const RootMobile = withStyles(legendStylesMobile, { name: "LegendRoot" })(
  legendRootBase
) as unknown as React.ComponentType<any>;

const GraphRoots = { Root, RootMobile };

export default GraphRoots;
