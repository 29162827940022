import Slider from "@material-ui/core/Slider";
import { withStyles } from "@material-ui/core/styles";

export const SliderDefined = withStyles({
  root: {
    color: "#0d47a1",
    height: 8,
  },
  thumb: {
    height: 0,
    width: 0,
    "&:focus, &:hover, &$active": {
      boxShadow: "none",
    },
  },
  mark: {
    height: 12,
    width: 2,
    marginTop: -2,
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
  active: {},
  valueLabel: {
    left: "calc(-50% - 10px)",
  },
})(Slider);
