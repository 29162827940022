export const formatPhoneNumber = (phone: string | null | undefined) => {
  if (!!phone && phone.replace(/\D/g, "").length > 0) {
    if (phone.replace(/\D/g, "").length === 11) {
      return `(${phone.replace(/\D/g, "").substring(0, 2)}) ${phone
        .replace(/\D/g, "")
        .substring(2, 7)}-${phone.replace(/\D/g, "").substring(7, 11)}`;
    } else if (phone.replace(/\D/g, "").length === 10) {
      return `(${phone.replace(/\D/g, "").substring(0, 2)}) ${phone
        .replace(/\D/g, "")
        .substring(2, 6)}-${phone.replace(/\D/g, "").substring(6, 10)}`;
    } else if (phone.replace(/\D/g, "").length === 13) {
      return `(${phone.replace(/\D/g, "").substring(2, 4)}) ${phone
        .replace(/\D/g, "")
        .substring(4, 9)}-${phone.replace(/\D/g, "").substring(9, 13)}`;
    } else if (phone.replace(/\D/g, "").length === 12) {
      return `(${phone.replace(/\D/g, "").substring(2, 4)}) ${phone
        .replace(/\D/g, "")
        .substring(4, 8)}-${phone.replace(/\D/g, "").substring(8, 12)}`;
    } else {
      return "";
    }
  } else {
    return "";
  }
};
