import { makeStyles } from "@material-ui/core/styles";

export const PopoverFormStyles = makeStyles((theme) => ({
  formRoot: {
    position: "relative",
    width: "380px",
    padding: "20px 10px",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      width: "300px",
    },
    "& > *": {
      margin: theme.spacing(1),
      width: "365px",
      [theme.breakpoints.down("xs")]: {
        width: "280px",
      },
    },
  },
  gridCoordinates: {
    display: "grid",
    gridTemplateColumns: "40% 54%",
    gridGap: "6%",
  },
}));
