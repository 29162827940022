import React, { Fragment, useEffect, useState, useContext } from "react";
import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import { SettingsContext } from "../../shared/context/settingsContext";
import {
  moveSingleSaleOwner,
  moveColumnSalesOwner,
  moveSelectedSalesOwner,
} from "../api/salesFunnelAPI";
import {
  AuthContextProps,
  KanbanFilledData,
  SalePopCustData,
} from "../../shared/data/types";

import { PopoverFormStyles } from "./PopoverFormStyles";

interface PopoverMoveSaleProps {
  columnId?: string;
  itemId?: string;
  item?: SalePopCustData;
  itemsIds?: string[];
  setSelectedItems?: React.Dispatch<React.SetStateAction<string[]>>;
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLButtonElement>>;
  auth: AuthContextProps;
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean
  ) => Promise<any>;
  setKanbanData: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
  kanbanType: "SALES" | "PRE_SALES";
  type: "column" | "single" | "selected";
  senderFetchId?: string;
  setActiveItem?: React.Dispatch<React.SetStateAction<string>>;
  isTeamLoaded: boolean;
}

export const PopoverMoveSale = (props: PopoverMoveSaleProps) => {
  const {
    columnId,
    itemId,
    item,
    anchorEl,
    itemsIds,
    setAnchorEl,
    auth,
    sendRequest,
    setKanbanData,
    type,
    senderFetchId,
    setActiveItem,
    setSelectedItems,
    isTeamLoaded,
    kanbanType,
  } = props;
  const open = Boolean(anchorEl);
  const classes = PopoverFormStyles();
  const settingsCtx = useContext(SettingsContext);
  const isManager =
    settingsCtx?.individualPermissions?.find((ip) => {
      return ip?.user?.toString() === auth?.userId;
    })?.salesManager || false;

  const userTeamsMembers =
    settingsCtx.teams
      ?.filter((t) => {
        return t.leader === auth.userId && t?.type === kanbanType;
      })
      ?.map((t) => {
        return t.members;
      })
      ?.flat(1) || [];

  const vendorsOptions =
    auth.vendors
      ?.filter((vendor) => {
        if (!item) {
          return vendor._id !== senderFetchId;
        } else {
          return vendor._id !== item.user?.toString();
        }
      })
      ?.filter((v) => {
        return settingsCtx.individualPermissions
          ?.filter((ip) => {
            return (
              (kanbanType === "SALES" && ip.salesKanban) ||
              (kanbanType === "PRE_SALES" && ip.preSalesKanban)
            );
          })
          ?.map((ip) => {
            return ip.user;
          })
          ?.includes(v._id);
      })
      ?.filter((item) => {
        return (
          auth.type === "MANAGER" ||
          isManager ||
          userTeamsMembers?.includes(item?._id) ||
          item?._id === auth?.userId
        );
      })
      ?.map((vendor) => {
        return {
          value: vendor._id,
          option: `${vendor.personalData?.name} - ${vendor.email}`,
        };
      }) || [];

  const allOptions =
    auth.type === "MANAGER" || isManager
      ? [{ value: auth.managerUserId, option: "GERENTE" }, ...vendorsOptions]
      : [...vendorsOptions];
  const [fetchId, setFetchId] = useState<string>(
    auth.type === "MANAGER" || isManager
      ? auth?.managerUserId || ""
      : allOptions?.[0]?.value || ""
  );

  useEffect(() => {
    setFetchId(
      auth.type === "MANAGER" || isManager
        ? auth?.managerUserId || ""
        : allOptions?.[0]?.value || ""
    );
  }, [senderFetchId]);

  const changeValueHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFetchId(e.target.value);
  };

  const moveSaleHandler = () => {
    if (type === "single") {
      moveSingleSaleOwner({
        sendRequest,
        auth,
        columnId,
        saleId: itemId,
        receiverId: fetchId,
        setKanbanData,
        senderFetchId,
        isTeamLoaded,
      }).then(() => {
        if (setActiveItem) {
          setActiveItem(null);
        }
      });
    } else if (type === "column") {
      moveColumnSalesOwner({
        sendRequest,
        auth,
        columnId,
        receiverId: fetchId,
        setKanbanData,
        senderFetchId,
        isTeamLoaded,
        type: kanbanType,
      }).then(() => {
        if (setActiveItem) {
          setActiveItem(null);
        }
      });
    } else if (type === "selected") {
      moveSelectedSalesOwner({
        sendRequest,
        auth,
        salesIds: itemsIds,
        receiverId: fetchId,
        setKanbanData,
        senderFetchId,
        isTeamLoaded,
        type: kanbanType,
      }).then(() => {
        if (setSelectedItems) {
          setSelectedItems([]);
        }
        if (setActiveItem) {
          setActiveItem(null);
        }
      });
    }

    setAnchorEl(null);
    setFetchId(allOptions?.[0]?.value || "");
  };

  return (
    <Fragment>
      <Popover
        id={itemId + "-" + "move-sale-popover"}
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className={classes.formRoot}>
          <FormControl>
            <InputLabel id="fetch-type-filter-selector-label">
              Enviar para
            </InputLabel>
            <Select
              labelId="fetch-type-filter-selector-label"
              id="fetch-type-filter-selector"
              value={fetchId || allOptions?.[0]?.value}
              onChange={changeValueHandler}
            >
              {allOptions.map((option) => {
                return (
                  <MenuItem key={option.value} value={option.value}>
                    {option.option}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Button
            disabled={
              !fetchId ||
              (auth.managerUserId === senderFetchId &&
                allOptions
                  .map((opt) => {
                    return opt.value;
                  })
                  .indexOf(fetchId) === 0) ||
              fetchId === item?.user?.toString()
            }
            color="primary"
            variant="contained"
            onClick={moveSaleHandler}
          >
            {type === "single" ? "ENVIAR" : "ENVIAR"}
          </Button>
        </div>
      </Popover>
    </Fragment>
  );
};
