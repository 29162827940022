import Popover from "@material-ui/core/Popover";
import React, { Fragment, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";

import { useHttpClient } from "../../shared/hooks/httpHook";
import { LoadingSpinnerOverlayRegular } from "../../shared/components/UIElements/LoadingSpinnerOverlayRegular";
import { PopoverFormStyles } from "./PopoverFormStyles";
import { editSaleStatus } from "../api/salesFunnelAPI";
import { addThousandSeparator } from "../../shared/util/addThousandSeparator";
import { dateFormatFromISOString } from "../../shared/util/dateFormatFromISOString";
import { getSingleKanban, returnSaleToPreSale } from "../api/salesFunnelAPI";
import {
  AuthContextProps,
  KanbanFilledData,
  SettingsContextData,
  KanbanData,
} from "../../shared/data/types";

interface PopoverFormReturnSaleToPreSaleProps {
  type: "SALES";
  itemId: string;
  item: KanbanFilledData["items"][0];
  anchorEl: HTMLButtonElement | HTMLLIElement | null;
  setAnchorEl: React.Dispatch<
    React.SetStateAction<HTMLButtonElement | HTMLLIElement>
  >;
  auth: AuthContextProps;
  sendRequestParent: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean
  ) => Promise<any>;
  settingsCtx: SettingsContextData;
  setKanbanData: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
  setActiveItem?: React.Dispatch<React.SetStateAction<string>>;
  setUpdateColumnsTotals?: React.Dispatch<React.SetStateAction<string[]>>;
}

export const PopoverFormReturnSaleToPreSale = (
  props: PopoverFormReturnSaleToPreSaleProps
) => {
  const {
    item,
    anchorEl,
    setAnchorEl,
    auth,
    sendRequestParent,
    setKanbanData,
    setActiveItem,
    type,
    settingsCtx,
    setUpdateColumnsTotals,
  } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const open = Boolean(anchorEl);
  const classes = PopoverFormStyles();
  const [chosenStep, setChosenStep] = useState<string>(null);
  const [loadedKanban, setLoadedKanban] = useState<KanbanData>(null);

  const columnsOpts =
    loadedKanban?.columns?.length > 0
      ? loadedKanban?.columns
          ?.sort((a, b) => {
            return (a?.order || 0) - (b?.order || 0);
          })
          ?.map((column) => {
            return {
              txt: `Etapa ${column?.order + 1} - ${column?.title}`,
              id: column?._id,
            };
          })
      : [];

  useEffect(() => {
    if (anchorEl) {
      getSingleKanban({
        sendRequest,
        auth,
        setLoadedKanban,
        type: "PRE_SALES",
      });
    }
  }, [type, anchorEl]);

  const changeStepValueHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChosenStep(e.target.value);
  };

  const confirmHandler = () => {
    returnSaleToPreSale({
      sendRequest: sendRequestParent,
      auth,
      saleId: item?._id,
      setKanbanData,
      column: chosenStep,
    }).then(() => {
      if (setActiveItem) {
        setActiveItem(null);
      }
      if (setUpdateColumnsTotals) {
        setUpdateColumnsTotals([item.kanbanData?.column, "0"]);
      }
    });
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <Popover
        id={item?._id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        style={{ zIndex: 502 }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <form className={classes.formRoot}>
          <FormControl>
            <InputLabel id="fetch-type-filter-2-selector-label">
              Selecionar Etapa
            </InputLabel>
            <Select
              labelId="fetch-type-filter-2-selector-label"
              id="fetch-type-filter-2-selector"
              value={chosenStep}
              onChange={changeStepValueHandler}
            >
              {columnsOpts?.map((option, i) => {
                return (
                  <MenuItem key={`opt-${i}`} value={option?.id}>
                    {option?.txt}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {!isLoading && (
            <Button
              variant="contained"
              color="primary"
              disabled={!chosenStep}
              onClick={() => {
                confirmHandler();
                setAnchorEl(null);
              }}
            >
              QUALIFICAR
            </Button>
          )}
          {isLoading && (
            <div className="u-center">
              <CircularProgress size={30} color="primary" />
            </div>
          )}
        </form>
      </Popover>
    </Fragment>
  );
};
