import { getPriceComponentsValues } from "../../proposal/util/getPriceComponentsValues";
import { calcKitPriceForVendorMarkup } from "./calcKitPriceForVendorMarkup";
import { calcKitPriceForVendorGrossMargin } from "./calcKitPriceForVendorGrossMargin";
import { AuthContextProps, SettingsContextData } from "../../shared/data/types";

interface CalcKitPriceForVendorProps {
  auth: AuthContextProps;
  settingsCtx: SettingsContextData;
  systemPower: number;
  modulesQtd: number;
  invQtd: number;
  eqpPriceTotalNoMarkup: number;
  ownerId: string;
  distance: number;
  location: string;
  invPower: number;
  voltage: number;
  fixation: string;
  roofHight?: number;
  service?: string;
  inverterType: "String" | "Micro";
  preVendor: string | null;
}

export const calcKitPriceForVendor = (props: CalcKitPriceForVendorProps) => {
  const {
    auth,
    settingsCtx,
    systemPower,
    modulesQtd,
    invQtd,
    eqpPriceTotalNoMarkup,
    ownerId,
    distance,
    location,
    invPower,
    voltage,
    fixation,
    roofHight = 0,
    service,
    inverterType = "String",
    preVendor,
  } = props;

  if (settingsCtx.autoPricingType !== "DETAILED") {
    const foundMargin =
      settingsCtx.autoPricing
        ?.sort((a, b) => {
          return (a?.power || 0) - (b?.power || 0);
        })
        ?.filter((ap) => {
          return systemPower <= ap.power;
        })
        ?.slice(0, 1)?.[0]?.margin || 50;

    let totalWithMargin = 0;
    if (settingsCtx.autoPricingCalc === "GROSS_MARGIN") {
      totalWithMargin = eqpPriceTotalNoMarkup * (1 + foundMargin / 100);
    } else {
      totalWithMargin = eqpPriceTotalNoMarkup / (1 - foundMargin / 100);
    }

    return totalWithMargin;
  } else {
    const { serviceCosts, costPercentages } = getPriceComponentsValues(
      ownerId || auth.userId,
      settingsCtx,
      systemPower,
      modulesQtd,
      invQtd,
      distance || 0,
      location,
      invPower || 0,
      voltage,
      fixation,
      roofHight || 0,
      service || null,
      inverterType,
      preVendor || null
    );

    if (settingsCtx?.autoPricingDetailed?.autoPricingCalc === "MARKUP") {
      const totalWithMarkup = calcKitPriceForVendorMarkup({
        serviceCosts,
        costPercentages,
        eqpPriceTotalNoMarkup,
      });
      return totalWithMarkup;
    } else if (
      settingsCtx?.autoPricingDetailed?.autoPricingCalc === "GROSS_MARGIN"
    ) {
      const totalWithMarkup = calcKitPriceForVendorGrossMargin({
        serviceCosts,
        costPercentages,
        eqpPriceTotalNoMarkup,
      });
      return totalWithMarkup;
    }
  }
};
