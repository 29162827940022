import { makeStyles } from "@material-ui/core/styles";

export const ModalErrorStyles = makeStyles(() => ({
  errorModalTitle: {
    color: "#f50057",
  },
  errorModalContent: {
    color: "#424242",
  },
  criticalWarningImgCont: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    objectFit: "cover",
    width: "200px",
    heigth: "200px",
    margin: "0 auto",
  },
  inputContainer: {
    "& > *": {
      width: "100%",
    },
    padding: "0 2rem",
  },
  criticalWarningImg: {
    width: "100%",
    height: "auto",
  },
}));
