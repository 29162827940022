export const generateFormattedDateFromTimestamp = (timestamp: number) => {
  let date = new Date(timestamp);

  const dd = date.getDate();
  let ddStr = "";
  if (dd < 10) {
    ddStr = `0${dd}`;
  } else {
    ddStr = `${dd}`;
  }

  const mm = date.getMonth();
  let mmStr = "";
  if (mm + 1 < 10) {
    mmStr = `0${mm + 1}`;
  } else {
    mmStr = `${mm + 1}`;
  }

  const h = date.getHours();
  let hStr = "";
  if (h < 10) {
    hStr = `0${h}`;
  } else {
    hStr = `${h}`;
  }

  const min = date.getMinutes();
  let minStr = "";
  if (min < 10) {
    minStr = `0${min}`;
  } else {
    minStr = `${min}`;
  }

  return `${ddStr}/${mmStr}/${date.getFullYear()}\n ${hStr}:${minStr}`;
};
