import { makeStyles } from "@material-ui/core/styles";

export const ModalSuccessStyles = makeStyles(() => ({
  successModalTitle: {
    color: "#007bed",
  },
  successModalContent: {
    color: "#424242",
  },
}));
