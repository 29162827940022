export const processTimePeriodHandler = (
  setTimePeriod: React.Dispatch<React.SetStateAction<boolean>>,
  timePeriod: boolean,
  localStorageName: string
) => {
  setTimePeriod((prevValue) => {
    return !prevValue;
  });
  localStorage.setItem(
    localStorageName,
    JSON.stringify({ timePeriod: !timePeriod })
  );
};

export const processSelectedEarlierTimePeriodHandler = (
  date: string,
  setTimePeriodSelected: React.Dispatch<
    React.SetStateAction<{
      timePeriodEarlierSelected: string;
      timePeriodOlderSelected: string;
    }>
  >
) => {
  setTimePeriodSelected((prevValues) => {
    return { ...prevValues, timePeriodEarlierSelected: date };
  });
};

export const processSelecteOlderdTimePeriodHandler = (
  date: string,
  setTimePeriodSelected: React.Dispatch<
    React.SetStateAction<{
      timePeriodEarlierSelected: string;
      timePeriodOlderSelected: string;
    }>
  >
) => {
  setTimePeriodSelected((prevValues) => {
    return { ...prevValues, timePeriodOlderSelected: date };
  });
};
