import { addThousandSeparator } from "../../shared/util/addThousandSeparator";
import { formatPhoneNumber } from "../../shared/util/formatPhoneNumber";
import {
  ProposalData,
  SalePopCustData,
  UserData,
} from "../../shared/data/types";

export const getContractTagsValues = (
  sale: SalePopCustData,
  proposal: ProposalData,
  user: UserData
) => {
  let tagsValues: { [key: string]: any } = {
    nome_cliente:
      sale?.customer?.name || `*** DADO NÃO CADASTRADO: {nome_cliente} ***`,
    uf_cliente:
      sale?.customer?.uf || `*** DADO NÃO CADASTRADO: {uf_cliente} ***`,
    cidade_cliente:
      sale?.customer?.city || `*** DADO NÃO CADASTRADO: {cidade_cliente} ***`,
    email_cliente:
      sale?.customer?.email || `*** DADO NÃO CADASTRADO: {email_cliente} ***`,
    telefone_cliente:
      formatPhoneNumber(sale?.customer?.phone || "") ||
      `*** DADO NÃO CADASTRADO: {telefone_cliente} ***`,
    whatsapp_cliente:
      formatPhoneNumber(sale?.customer?.whatsapp || "") ||
      `*** DADO NÃO CADASTRADO: {whatsapp_cliente} ***`,
    documento_cliente:
      sale?.customer?.document ||
      `*** DADO NÃO CADASTRADO: {documento_cliente} ***`,
    endereco_cliente:
      sale?.customer?.address ||
      `*** DADO NÃO CADASTRADO: {endereco_cliente} ***`,
    rg_cliente:
      sale?.customer?.rg || `*** DADO NÃO CADASTRADO: {rg_cliente} ***`,
    nac_cliente:
      sale?.customer?.nationality ||
      `*** DADO NÃO CADASTRADO: {nac_cliente} ***`,
    prof_cliente:
      sale?.customer?.profession ||
      `*** DADO NÃO CADASTRADO: {prof_cliente} ***`,
    nome_resp_cliente:
      sale?.customer?.personInCharge?.name ||
      `*** DADO NÃO CADASTRADO: {nome_resp_cliente} ***`,
    cpf_resp_cliente:
      sale?.customer?.personInCharge?.cpf ||
      `*** DADO NÃO CADASTRADO: {cpf_resp_cliente} ***`,
    observacoes_cliente:
      sale?.customer?.observations ||
      `*** DADO NÃO CADASTRADO: {observacoes_cliente} ***`,
    nome_empresa: user?.name || `*** DADO NÃO CADASTRADO: {nome_empresa} ***`,
    cnpj_empresa: user?.cnpj || `*** DADO NÃO CADASTRADO: {cnpj_empresa} ***`,
    endereco_empresa:
      user?.address || `*** DADO NÃO CADASTRADO: {endereco_empresa} ***`,
    telefone_empresa:
      formatPhoneNumber(user?.phone || "") ||
      `*** DADO NÃO CADASTRADO: {telefone_empresa} ***`,
    whatsapp_empresa:
      formatPhoneNumber(user?.whatsapp || "") ||
      `*** DADO NÃO CADASTRADO: {whatsapp_empresa} ***`,
    nome_resp_empresa:
      user?.personInCharge?.name ||
      `*** DADO NÃO CADASTRADO: {nome_resp_empresa} ***`,
    cpf_resp_empresa:
      user?.personInCharge?.cpf ||
      `*** DADO NÃO CADASTRADO: {cpf_resp_empresa} ***`,
    rg_resp_empresa:
      user?.personInCharge?.rg ||
      `*** DADO NÃO CADASTRADO: {rg_resp_empresa} ***`,
    prof_resp_empresa:
      user?.personInCharge?.profession ||
      `*** DADO NÃO CADASTRADO: {prof_resp_empresa} ***`,
    nac_resp_empresa:
      user?.personInCharge?.nationality ||
      `*** DADO NÃO CADASTRADO: {nac_resp_empresa} ***`,
    valor_venda:
      `R$ ${addThousandSeparator(sale?.amount || 0)}` ||
      `*** DADO NÃO CADASTRADO: {valor_venda} ***`,
    potencia_sistema:
      `${addThousandSeparator(proposal?.realSystemData?.power || 0)} kWp` ||
      `*** DADO NÃO CADASTRADO: {potencia_sistema} ***`,
    condicoes_de_pagamento:
      proposal?.revenueData?.paymentConditions ||
      `*** DADO NÃO CADASTRADO: {condicoes_de_pagamento} ***`,
    validade_proposta:
      proposal?.proposalCustomizationData?.validityDate ||
      `*** DADO NÃO CADASTRADO: {validade_proposta} ***`,
    data_do_dia: new Date().toLocaleDateString("pt-BR", {
      day: "numeric",
      month: "long",
      year: "numeric",
    }),
    modulos: [],
    inversores: [],
    adicionais: [],
  };

  proposal.pvModuleData?.forEach((mod) => {
    tagsValues.modulos?.push({
      mod_fab: mod.manufacturer || "",
      mod_pot: addThousandSeparator(mod.power || 0, false, false) || "",
      mod_gar_def: mod.warrantyDefect || "",
      mod_gar_ef: mod.warrantyEfficiency || "",
      mod_qtd: mod.quantity || "",
    });
  });

  proposal.inverterData?.forEach((inv) => {
    tagsValues.inversores?.push({
      inv_mod: inv.iModel || "",
      inv_fab: inv.iManufacturer || "",
      inv_pot:
        inv.iPower > 99
          ? addThousandSeparator(inv.iPower, false, false)
          : addThousandSeparator(inv.iPower * 1000, false, false) || "",
      inv_gar: inv.iWarranty || "",
      inv_monit: inv.iMonitoring || "Wi-Fi",
      inv_qtd: inv.iQuantity || "",
    });
  });

  proposal.additionalItems?.forEach((addItem) => {
    tagsValues.adicionais?.push({
      item_adc_nome: addItem.item || "",
      item_adc_fab: addItem.manufacter || "",
      item_adc_qtd: addItem.quantity || "",
      item_adc_det: addItem.detail || "",
    });
  });

  return tagsValues;
};
