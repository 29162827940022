import {
  StringNumbers,
  SignupPricesAndTaxesData,
} from "../../shared/data/types";

export const getPlanRecPlanPriceById = (
  planId: StringNumbers,
  loadedPrices: SignupPricesAndTaxesData,
  vendorsAmount: number = 2,
  discountMultiplier: number
) => {
  let price = 0;
  if (planId === "1" || planId === "2") {
    price = loadedPrices.crmRec * (discountMultiplier || 1);
    return Number(price.toFixed(2));
  } else if (planId === "3") {
    if (vendorsAmount <= 2) {
      price =
        loadedPrices.enterprise2EmpRec *
        vendorsAmount *
        (discountMultiplier || 1);
    } else if (vendorsAmount <= 5) {
      price =
        loadedPrices.enterprise5EmpRec *
        vendorsAmount *
        (discountMultiplier || 1);
    } else if (vendorsAmount <= 10) {
      price =
        loadedPrices.enterprise10EmpRec *
        vendorsAmount *
        (discountMultiplier || 1);
    } else if (vendorsAmount <= 20) {
      price =
        loadedPrices.enterprise20EmpRec *
        vendorsAmount *
        (discountMultiplier || 1);
    } else if (vendorsAmount <= 50) {
      price =
        loadedPrices.enterprise50EmpRec *
        vendorsAmount *
        (discountMultiplier || 1);
    }

    return price;
  } else {
    return 0;
  }
};
