import { useEffect, useState } from "react";
import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  BarSeries,
  Legend,
} from "@devexpress/dx-react-chart-material-ui";
import { Animation } from "@devexpress/dx-react-chart";
import { Stack } from "@devexpress/dx-react-chart";

import { useHttpClient } from "../../../shared/hooks/httpHook";
import { ModalError } from "../../../shared/components/UIElements/ModalError";
import { LoadingSpinnerOverlayRegular } from "../../../shared/components/UIElements/LoadingSpinnerOverlayRegular";
import { addThousandSeparator } from "../../../shared/util/addThousandSeparator";
import { getMonthNameAndYear } from "../../../shared/util/getMonthNameAndYear";
import { getClosedSales } from "../../api/analyticsAPI";
import {
  AuthContextProps,
  SettingsContextProps,
} from "../../../shared/data/types";

import "./HomeDashboard.scss";

interface HomeDashboardSalesActivityProps {
  settingsCtx: SettingsContextProps;
  auth: AuthContextProps;
  fetchId: string;
  genSales: number[];
  closedSales: number[];
  isLoadingGenSales: boolean;
  isLoadingClosedSales: boolean;
}

export const HomeDashboardSalesActivity = (
  props: HomeDashboardSalesActivityProps
) => {
  const {
    settingsCtx,
    auth,
    fetchId,
    genSales,
    closedSales,
    isLoadingGenSales,
    isLoadingClosedSales,
  } = props;
  const { error, sendRequest, clearError, isLoading } = useHttpClient();
  const [loadedData, setLoadedData] = useState<
    {
      label: string;
      genSales: number;
      closedSales: number;
      month: string;
    }[]
  >([
    {
      label: "",
      genSales: 0,
      closedSales: 0,
      month: "",
    },
  ]);

  useEffect(() => {
    if (
      genSales?.length > 0 &&
      closedSales?.length > 0 &&
      !isLoadingGenSales &&
      !isLoadingClosedSales
    ) {
      let dataToSave: {
        label: string;
        genSales: number;
        closedSales: number;
        month: string;
      }[] = [];

      genSales.forEach((gs, i) => {
        dataToSave.push({
          label: `${i}`,
          genSales: genSales?.[i],
          closedSales: closedSales?.[i],
          month: getMonthNameAndYear(-i),
        });
      });

      setLoadedData(dataToSave?.reverse());
    }
  }, [isLoadingGenSales, isLoadingClosedSales, genSales, closedSales]);

  return (
    <div className="dashboard-item dashboard-item--sales-activity">
      {(isLoadingClosedSales || isLoadingGenSales) && (
        <LoadingSpinnerOverlayRegular />
      )}
      <ModalError error={error} onClear={clearError} />
      <p className="dashboard-item__title">
        Negócios Gerados e Negócios Ganhos
      </p>
      {/* {loadedData.length >= 3 && ( */}
      <div className="dashboard-item__doughnut-chart">
        <Chart height={250} data={loadedData?.slice(0, 3)}>
          <ArgumentAxis />
          <ValueAxis />
          <BarSeries
            name="Negócios Gerados"
            color="#189ab4"
            valueField="genSales"
            argumentField="month"
          />
          <BarSeries
            name="Negócios Ganhos"
            color="#75e6da"
            valueField="closedSales"
            argumentField="month"
          />
          <Stack />
          <Animation />
        </Chart>
      </div>
      {/* )} */}
    </div>
  );
};
