import React, { useReducer, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import NumberFormat from "react-number-format";

import { InputDispState, InputDispAction } from "./Input";
import { NumberFormatCustomProps } from "./InputCardNumber";
import { InputPrefixOutlinedOptionalFloatProps } from "./InputPrefixOutlinedOptionalFloat";

function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, name, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: name,
            value: values.value,
          },
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      isNumericString
      decimalScale={0}
    />
  );
}

const inputReducer = (state: InputDispState, action: InputDispAction) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: /^[0-9]*$/.test(action.val)
          ? action.val
          : action.val.replace(/\D/g, ""),
        isValid: true,
      };
    case "TOUCH":
      return {
        ...state,
        isTouched: true,
      };
    case "UNTOUCH":
      return {
        ...state,
        isTouched: false,
      };
    default:
      return state;
  }
};

export const InputPrefixOutlinedOptionalInt = (
  props: InputPrefixOutlinedOptionalFloatProps
) => {
  const {
    id,
    label,
    onInput,
    helperText,
    type,
    initialValue,
    variant,
    placeholder,
    disabled,
    reinitialize,
    reset,
    prefix,
  } = props;
  const [inputState, dispatch] = useReducer(inputReducer, {
    value: initialValue || "",
    isTouched: false,
    isValid: true,
  });
  const { value, isValid, isTouched } = inputState;

  useEffect(() => {
    onInput(id, value, true, label.charAt(0).toUpperCase() + label.slice(1));
  }, [id, value, isValid, label, onInput]);

  useEffect(() => {
    if (reset) {
      dispatch({
        type: "CHANGE",
        val: "",
      });
      dispatch({
        type: "UNTOUCH",
      });
    }
    if (reinitialize) {
      dispatch({
        type: "CHANGE",
        val: initialValue,
      });
      dispatch({
        type: "UNTOUCH",
      });
    }
  }, [reset, reinitialize, initialValue]);

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: "CHANGE",
      val: event.target.value,
    });
  };

  const touchHandler = () => {
    dispatch({ type: "TOUCH" });
  };

  return (
    <TextField
      disabled={disabled}
      id={id}
      name={id}
      value={value}
      variant={variant}
      onChange={changeHandler}
      onBlur={touchHandler}
      type={type}
      label={label.charAt(0).toUpperCase() + label.slice(1)}
      helperText={isTouched && !value && helperText}
      placeholder={placeholder}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">{prefix}</InputAdornment>
        ),
        inputComponent: NumberFormatCustom as any,
      }}
    />
  );
};
