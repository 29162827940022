import { useReducer, useEffect } from "react";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { InputDispState } from "./Input";
import { SwitchCustomOrange } from "../MUIThemed/SwitchCustomOrange";

export interface InputDispActionBoolean {
  type: string;
  val?: boolean;
  validators?: { type: string; val?: number }[];
}

const inputReducer = (
  state: InputDispState,
  action: InputDispActionBoolean
) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.val,
        isValid: true,
      };
    default:
      return state;
  }
};

interface InputSwitchProps {
  id: string;
  label: string;
  onInput: (
    id: string,
    value: boolean,
    isValid: boolean,
    label: string
  ) => void;
  title: string;
  initialValue?: boolean;
  reinitialize?: boolean;
  disabled?: boolean;
  color: "primary" | "secondary" | "default" | "orange";
  updateInitValue?: boolean;
  resetOnValueChange?: boolean;
  valueToWatchReset?: number;
  style?: {};
}

export const InputSwitch = (props: InputSwitchProps) => {
  const {
    id,
    label,
    onInput,
    title,
    initialValue,
    reinitialize,
    color,
    disabled,
    updateInitValue,
    resetOnValueChange,
    valueToWatchReset,
    style,
  } = props;
  const [inputState, dispatch] = useReducer(inputReducer, {
    value: initialValue || false,
    isTouched: false,
    isValid: true,
  });

  const { value, isValid } = inputState;

  useEffect(() => {
    onInput(
      id,
      value as boolean,
      isValid,
      label.charAt(0).toUpperCase() + label.slice(1)
    );
  }, [id, value, isValid, label, onInput]);

  useEffect(() => {
    if (reinitialize || updateInitValue) {
      dispatch({
        type: "CHANGE",
        val: initialValue,
      });
    }
  }, [reinitialize, initialValue, updateInitValue]);

  useEffect(() => {
    if (resetOnValueChange && typeof valueToWatchReset === "number") {
      dispatch({
        type: "CHANGE",
        val: initialValue,
      });
    }
  }, [resetOnValueChange, valueToWatchReset]);

  const changeHandler = () => {
    dispatch({
      type: "CHANGE",
      val: !value,
    });
  };

  return (
    <FormGroup style={style} row>
      {color !== "orange" && (
        <FormControlLabel
          control={
            <Switch
              title={title}
              checked={value as boolean}
              disabled={disabled}
              color={color}
              id={id}
              onChange={changeHandler}
              name={id}
            />
          }
          label={label.charAt(0).toUpperCase() + label.slice(1)}
        />
      )}
      {color === "orange" && (
        <FormControlLabel
          control={
            <SwitchCustomOrange
              title={title}
              checked={value as boolean}
              disabled={disabled}
              id={id}
              onChange={changeHandler}
              name={id}
            />
          }
          label={label.charAt(0).toUpperCase() + label.slice(1)}
        />
      )}
    </FormGroup>
  );
};
