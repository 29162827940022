import { useState } from "react";
import Popover from "@material-ui/core/Popover";

import "./MoreItems.scss";

interface MoreItemsProps {
  content: string[];
  leftLength: number;
}

export const MoreItems = (props: MoreItemsProps) => {
  const { content, leftLength } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLDivElement>(null);

  return (
    <div className="more-items" style={{ zIndex: 1000001 }}>
      <div
        title={content?.join("\n")}
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
          e.stopPropagation();
        }}
        className={`more-items__btn ${
          !!anchorEl ? "more-items__btn--active" : ""
        }`}
      >
        {leftLength > 1 ? `+${leftLength} itens` : "+1 item"}
      </div>
      <Popover
        id="serrana-inv-model-edit"
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        style={{ zIndex: 10001, marginTop: "0.5rem" }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className="more-items__content">
          {content?.map((c) => {
            return <p className="more-items__content-p">{c}</p>;
          })}
        </div>
      </Popover>
    </div>
  );
};
