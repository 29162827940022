export const getTimestampFromDate = (date: string) => {
  const dateArray = date.substring(0, 10).split("/");
  const dateTimestamp = new Date(
    +dateArray[2],
    +dateArray[1] - 1,
    +dateArray[0]
  ).getTime();

  return dateTimestamp;
};
