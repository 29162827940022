import { useState } from "react";
import "date-fns";
import { ptBR } from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { dateFormatFromISOString } from "../../util/dateFormatFromISOString";
import { compareDates } from "../../util/compareDates";

import { InputCalendarStyles } from "./InputCalendarStyles";

interface InputCalendarProps {
  id: string;
  label: string;
  selectedEarlierTimePeriodHandler?: (date: string) => void;
  selecteOlderdTimePeriodHandler?: (date: string) => void;
  format: string;
  disableFuture?: boolean;
  disablePast?: boolean;
  initialvalue?: string;
  disabled?: boolean;
  onInput?: (
    id: string,
    value: string,
    isValid: boolean,
    label: string
  ) => void;
}

export const InputCalendar = (props: InputCalendarProps) => {
  const {
    id,
    label,
    format,
    selectedEarlierTimePeriodHandler,
    selecteOlderdTimePeriodHandler,
    disableFuture,
    disablePast,
    initialvalue,
    disabled,
    onInput,
  } = props;
  const classes = InputCalendarStyles();
  const [selectedDate, setSelectedDate] = useState<Date | string | null>(
    initialvalue || new Date()
  );

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    if (onInput) {
      onInput(
        id,
        !!date && date.toString() !== "Invalid Date" ? date.toISOString() : "",
        !!date && date.toString() !== "Invalid Date",
        label.charAt(0).toUpperCase() + label.slice(1)
      );
    }
    if (!!date && date.toString() !== "Invalid Date") {
      if (selectedEarlierTimePeriodHandler) {
        selectedEarlierTimePeriodHandler(date.toISOString());
      }
      if (selecteOlderdTimePeriodHandler) {
        selecteOlderdTimePeriodHandler(date.toISOString());
      }
    }
  };

  return (
    <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
      <KeyboardDatePicker
        className={classes.root}
        lang="pt-BR"
        disableToolbar
        disableFuture={disableFuture}
        disablePast={disablePast}
        variant="inline"
        format={format}
        margin="normal"
        disabled={disabled}
        id={id}
        label={label.charAt(0).toUpperCase() + label.slice(1)}
        value={selectedDate}
        helperText={
          !selectedDate
            ? "Data inválida."
            : selectedDate && selectedDate.toString() === "Invalid Date"
            ? "Data inválida."
            : disableFuture &&
              !compareDates(
                dateFormatFromISOString(
                  typeof selectedDate === "string"
                    ? selectedDate
                    : selectedDate.toISOString()
                ),
                new Date().toISOString(),
                "OLDER-EQUAL"
              )
            ? "Data inválida."
            : disablePast &&
              !compareDates(
                dateFormatFromISOString(
                  typeof selectedDate === "string"
                    ? selectedDate
                    : selectedDate.toISOString()
                ),
                new Date().toISOString(),
                "NEWER-EQUAL"
              )
            ? "Data inválida."
            : ""
        }
        onChange={handleDateChange}
        KeyboardButtonProps={{
          "aria-label": "mudar data",
        }}
      />
    </MuiPickersUtilsProvider>
  );
};
