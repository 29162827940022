import { useState, useEffect, Fragment } from "react";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";

import { useHttpClient } from "../../shared/hooks/httpHook";
import { ModalError } from "../../shared/components/UIElements/ModalError";
import { LoadingSpinnerOverlayRegular } from "../../shared/components/UIElements/LoadingSpinnerOverlayRegular";
import { SaleDashChecklistChekbox } from "./SaleDashChecklistChekbox";
import { SaleDashChecklistText } from "./SaleDashChecklistText";
import { SaleDashChecklistNumber } from "./SaleDashChecklistNumber";
import { SaleDashChecklistList } from "./SaleDashChecklistList";
import {
  getSingleKanbanChecklist,
  getSingleSaleChecklist,
} from "../api/checklistsAPI";
import {
  AuthContextProps,
  ChecklistData,
  KanbanFilledData,
} from "../../shared/data/types";

import "./SaleDash.scss";

interface SaleDashChecklistProps {
  auth: AuthContextProps;
  type:
    | "SALES"
    | "PROJECT"
    | "INSTALLATION"
    | "AFTER_SALES"
    | "PRE_SALES"
    | "ADMINISTRATIVE";
  saleId: string;
  setLoadedData: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
}

export const SaleDashChecklist = (props: SaleDashChecklistProps) => {
  const { auth, type, saleId, setLoadedData } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [loadedKanbanChecklist, setLoadedKanbanChecklist] =
    useState<ChecklistData>(null);
  const [loadedSaleChecklist, setLoadedSaleChecklist] =
    useState<ChecklistData>(null);
  const { error, sendRequest, clearError, isLoading } = useHttpClient();

  useEffect(() => {
    setOpen(false);
    getSingleKanbanChecklist({
      sendRequest,
      auth,
      setLoadedKanbanChecklist,
      type,
    });
  }, [type]);

  useEffect(() => {
    setOpen(false);
    getSingleSaleChecklist({
      sendRequest,
      auth,
      setLoadedSaleChecklist,
      saleId,
    });
  }, [saleId]);

  useEffect(() => {
    if (loadedKanbanChecklist && loadedSaleChecklist) {
      setOpen(loadedKanbanChecklist?.items?.length > 0);
    }
  }, [loadedKanbanChecklist, loadedSaleChecklist]);

  return (
    <Fragment>
      <ModalError error={error} onClear={clearError} />
      {
        <div
          className={`sale-dash__checklist sale-dash__checklist--next ${
            open && type === "PROJECT"
              ? "sale-dash__checklist--extra-padding-bottom"
              : ""
          }`}
        >
          <div
            onClick={(e) => {
              if (loadedKanbanChecklist?.items?.length > 0) {
                setOpen(!open);
              }
            }}
            className="sale-dash__title-container sale-dash__title-container--clickable sale-dash__title-container--secondary"
          >
            <p
              className={`sale-dash__title sale-dash__title--secondary ${
                !loadedKanbanChecklist ||
                !loadedSaleChecklist ||
                loadedKanbanChecklist?.items?.length === 0
                  ? "sale-dash__title sale-dash__title--disabled"
                  : ""
              }`}
            >
              Checklist
            </p>
            <div className="sale-dash__cust-data-actions sale-dash__cust-data-actions--1">
              <IconButton
                style={{
                  color: "#aaa",
                }}
                size="small"
                title={!open ? "Abrir" : "Fechar"}
                className={`sale-dash__cust-data-action sale-dash__cust-data-action--blue ${
                  open ? "sale-dash__cust-data-action--rotated-icon" : ""
                }`}
                disabled={
                  !loadedKanbanChecklist ||
                  !loadedSaleChecklist ||
                  loadedKanbanChecklist?.items?.length === 0
                }
                onClick={(e) => {
                  e.stopPropagation();
                  if (loadedKanbanChecklist?.items?.length > 0) {
                    setOpen(!open);
                  }
                }}
              >
                <KeyboardArrowDownRoundedIcon style={{ fontSize: "30px" }} />
              </IconButton>
            </div>
          </div>
          {isLoading && <LoadingSpinnerOverlayRegular />}
          {open &&
            loadedKanbanChecklist &&
            loadedSaleChecklist &&
            loadedKanbanChecklist?.itemsOrder?.map((itemId, i) => {
              const kanbanChecklistItem = loadedKanbanChecklist?.items?.find(
                (item) => {
                  return item?._id === itemId;
                }
              );
              const saleChecklistItem = loadedSaleChecklist?.items?.find(
                (item) => {
                  return item?.templateItemId === itemId;
                }
              );
              const prevItemId =
                loadedKanbanChecklist?.itemsOrder?.[i - 1] || "";
              const prevItem = loadedKanbanChecklist?.items?.find(
                (item) => item._id === prevItemId
              );

              const isDifCat =
                kanbanChecklistItem?.category !== prevItem?.category;

              return (
                <Fragment>
                  {isDifCat && (
                    <div className="sale-dash__title-container sale-dash__title-container--tertiary">
                      <p
                        className={`sale-dash__title sale-dash__title--tertiary`}
                      >
                        {kanbanChecklistItem?.category || "Sem Categoria"}
                      </p>
                    </div>
                  )}
                  {kanbanChecklistItem?.type === "CHECKBOX" && (
                    <SaleDashChecklistChekbox
                      auth={auth}
                      saleId={saleId}
                      kanbanChecklistId={loadedKanbanChecklist?._id}
                      kanbanChecklistItem={kanbanChecklistItem}
                      saleChecklistItem={saleChecklistItem}
                      setLoadedSaleChecklist={setLoadedSaleChecklist}
                      type={type}
                      setLoadedData={setLoadedData}
                    />
                  )}
                  {kanbanChecklistItem?.type === "TEXT" && (
                    <SaleDashChecklistText
                      index={i}
                      auth={auth}
                      saleId={saleId}
                      kanbanChecklistId={loadedKanbanChecklist?._id}
                      kanbanChecklistItem={kanbanChecklistItem}
                      saleChecklistItem={saleChecklistItem}
                      setLoadedSaleChecklist={setLoadedSaleChecklist}
                      type={type}
                      setLoadedData={setLoadedData}
                    />
                  )}
                  {kanbanChecklistItem?.type === "NUMBER" && (
                    <SaleDashChecklistNumber
                      index={i}
                      auth={auth}
                      saleId={saleId}
                      kanbanChecklistId={loadedKanbanChecklist?._id}
                      kanbanChecklistItem={kanbanChecklistItem}
                      saleChecklistItem={saleChecklistItem}
                      setLoadedSaleChecklist={setLoadedSaleChecklist}
                      type={type}
                      setLoadedData={setLoadedData}
                    />
                  )}
                  {kanbanChecklistItem?.type === "LIST" && (
                    <SaleDashChecklistList
                      index={i}
                      auth={auth}
                      saleId={saleId}
                      kanbanChecklistId={loadedKanbanChecklist?._id}
                      kanbanChecklistItem={kanbanChecklistItem}
                      saleChecklistItem={saleChecklistItem}
                      setLoadedSaleChecklist={setLoadedSaleChecklist}
                      type={type}
                      setLoadedData={setLoadedData}
                    />
                  )}
                </Fragment>
              );
            })}
        </div>
      }
    </Fragment>
  );
};
