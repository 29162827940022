import { useEffect, useState } from "react";
import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  BarSeries,
  Legend,
} from "@devexpress/dx-react-chart-material-ui";
import { Animation, Axis } from "@devexpress/dx-react-chart";
import { Stack } from "@devexpress/dx-react-chart";

import { useHttpClient } from "../../../shared/hooks/httpHook";
import { ModalError } from "../../../shared/components/UIElements/ModalError";
import { LoadingSpinnerOverlayRegular } from "../../../shared/components/UIElements/LoadingSpinnerOverlayRegular";
import { addThousandSeparator } from "../../../shared/util/addThousandSeparator";
import { getMonthNameAndYear } from "../../../shared/util/getMonthNameAndYear";
import { getClosedSales } from "../../api/analyticsAPI";
import {
  AuthContextProps,
  SettingsContextProps,
} from "../../../shared/data/types";

import "./HomeDashboard.scss";

interface HomeDashboardSalesAmountsProps {
  settingsCtx: SettingsContextProps;
  auth: AuthContextProps;
  fetchId: string;
  closedSalesAmounts: number[];
  isLoadingClosedSales: boolean;
}

export const HomeDashboardSalesAmounts = (
  props: HomeDashboardSalesAmountsProps
) => {
  const {
    settingsCtx,
    auth,
    fetchId,
    closedSalesAmounts,
    isLoadingClosedSales,
  } = props;
  const { error, sendRequest, clearError, isLoading } = useHttpClient();
  const [loadedData, setLoadedData] = useState<
    {
      label: string;
      amount: number;
      month: string;
    }[]
  >([
    {
      label: "",
      amount: 0,
      month: "",
    },
  ]);

  useEffect(() => {
    if (closedSalesAmounts?.length > 0 && !isLoadingClosedSales) {
      let dataToSave: {
        label: string;
        amount: number;
        month: string;
      }[] = [];

      closedSalesAmounts.forEach((a, i) => {
        dataToSave.push({
          label: `${i}`,
          amount: a,
          month: getMonthNameAndYear(-i),
        });
      });

      setLoadedData(dataToSave?.reverse());
    }
  }, [isLoadingClosedSales, closedSalesAmounts]);

  const labelHalfWidth = 50;
  let lastLabelCoordinate = 0;

  const Label = ({ text, ...props }) => {
    const { x } = props;
    // filter Labels
    if (
      lastLabelCoordinate &&
      lastLabelCoordinate < x &&
      x - lastLabelCoordinate <= labelHalfWidth
    ) {
      return null;
    }
    lastLabelCoordinate = x;

    const labelText = text?.[0] === "−" ? text.substring(1) : text;
    const labelTextNumber = +labelText?.replace(/\D/g, "");

    // if (labelTextNumber === 0) {
    //   return "" as null;
    // }

    const finalLabel =
      labelTextNumber >= 1000000
        ? `${labelTextNumber / 100000}M`
        : `${labelTextNumber / 1000}k`;

    return (
      <ValueAxis.Label
        {...(props as { x: any; y: any; dy: any; textAnchor: any })}
        text={finalLabel}
      />
    );
  };

  return (
    <div className="dashboard-item dashboard-item--sales-amounts">
      {isLoadingClosedSales && <LoadingSpinnerOverlayRegular />}
      <ModalError error={error} onClear={clearError} />
      <p className="dashboard-item__title">Negócios Ganhos (R$)</p>
      {loadedData.length >= 3 && (
        <div className="dashboard-item__doughnut-chart">
          <Chart rotated height={250} data={loadedData?.slice(0, 3)}>
            <ArgumentAxis />
            <ValueAxis labelComponent={Label} />
            <BarSeries
              name="Negócios Gerados"
              color="#75e6da"
              valueField="amount"
              argumentField="month"
            />
            <Animation />
          </Chart>
        </div>
      )}
    </div>
  );
};
