import { useState, Fragment } from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import Collapse from "@material-ui/core/Collapse";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import IconButton from "@material-ui/core/IconButton";
import MoreHorizRoundedIcon from "@material-ui/icons/MoreHorizRounded";

// import { LectureItem } from "./LectureItem";
// import { TestItem } from "./TestItem";
import { FileItem } from "./FileItem";
import { MenuFolderOptions } from "./MenuFolderOptions";
import { useHttpClient } from "../../shared/hooks/httpHook";
import { LoadingSpinnerOverlayRegular } from "../../shared/components/UIElements/LoadingSpinnerOverlayRegular";
import { ModalError } from "../../shared/components/UIElements/ModalError";
import { PopoverCreateFile } from "./PopoverCreateFile";
import { sliceLongString } from "../../shared/util/sliceLongString";
import {} from "../api/archiveAPI";
import { ArchiveData, AuthContextProps } from "../../shared/data/types";

interface FolderItemProps {
  folder: ArchiveData["folders"][0];
  folderFiles: ArchiveData["files"];
  auth: AuthContextProps;
  setLoadedArchive: React.Dispatch<React.SetStateAction<ArchiveData>>;
  index: number;
  isLoadingDrag: boolean;
  cid: string;
}

export const FolderItem = (props: FolderItemProps) => {
  const {
    folder,
    auth,
    setLoadedArchive,
    index,
    isLoadingDrag,
    folderFiles,
    cid,
  } = props;
  const { sendRequest, error, isLoading, clearError } = useHttpClient();
  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const [anchorElFormFile, setAnchorElFormFile] =
    useState<null | HTMLButtonElement>(null);

  return (
    <Fragment>
      <ModalError error={error} onClear={clearError} />
      <MenuFolderOptions
        auth={auth}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        sendRequest={sendRequest}
        setLoadedArchive={setLoadedArchive}
        folder={folder}
        cid={cid}
      />
      <PopoverCreateFile
        id="popover-edit-file"
        anchorEl={anchorElFormFile}
        setAnchorEl={setAnchorElFormFile}
        auth={auth}
        sendRequest={sendRequest}
        setLoadedArchive={setLoadedArchive}
        apiCall="create"
        folderId={folder._id}
        cid={cid}
      />
      <Draggable
        draggableId={folder._id}
        isDragDisabled={isLoadingDrag || open}
        index={index}
      >
        {(provided, snapshot) => {
          return (
            <div
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
              className={`table__item-container ${
                snapshot.isDragging ? "table__item-container--is-dragging" : ""
              }`}
            >
              <div
                className={`table__item ${
                  snapshot.isDragging ? "table__item--is-dragging" : ""
                }`}
              >
                {isLoading && <LoadingSpinnerOverlayRegular infoCard />}
                <div
                  style={{ color: "#263351" }}
                  className="table__item-main-info"
                >
                  <h3
                    style={{ color: "#263351" }}
                    className="heading-tertiary table__item-title"
                    title={folder.name}
                  >
                    {sliceLongString(folder.name, 50)}
                  </h3>
                  <div className="table__item-stats">
                    <span
                      style={{ color: "#263351" }}
                      className="table__item-stat"
                    >
                      Arquivos: {folderFiles?.length || 0}
                    </span>
                  </div>
                </div>
                <div className="table__item-actions">
                  <IconButton
                    style={{ color: "#263351" }}
                    size="medium"
                    title={open ? "Fechar Arquivos" : "Abrir Arquivos"}
                    className={`table__item-action${open ? "--open" : ""}`}
                    onClick={() => {
                      setOpen(!open);
                    }}
                  >
                    <ExpandMoreRoundedIcon />
                  </IconButton>
                  <IconButton
                    style={{ color: "#263351" }}
                    title="Opções"
                    onClick={(e) => {
                      setAnchorEl(e.currentTarget);
                    }}
                  >
                    <MoreHorizRoundedIcon />
                  </IconButton>
                </div>
              </div>
              <Collapse in={open}>
                <Droppable
                  droppableId={`${folder._id}`}
                  direction="vertical"
                  type="FILE"
                >
                  {(provided) => {
                    return (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className="table__secondary"
                      >
                        {folderFiles
                          ?.sort((a, b) => {
                            return (a?.order || 0) - (b?.order || 0);
                          })
                          .map((file, i) => {
                            return (
                              <FileItem
                                file={file}
                                auth={auth}
                                setLoadedArchive={setLoadedArchive}
                                index={i}
                                isLoadingParent={isLoading}
                                key={file._id}
                                cid={cid}
                              />
                            );
                          })}

                        {provided.placeholder}
                      </div>
                    );
                  }}
                </Droppable>
                <div className="table__secondary-add-btn-container">
                  <IconButton
                    style={{ color: "#263351" }}
                    title="Adicionar arquivo"
                    onClick={(e) => {
                      setAnchorElFormFile(
                        e.currentTarget as unknown as HTMLButtonElement
                      );
                    }}
                  >
                    <AddRoundedIcon />
                  </IconButton>
                </div>
              </Collapse>
            </div>
          );
        }}
      </Draggable>
    </Fragment>
  );
};
