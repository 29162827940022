export const getDateFromTimestamp = (ts: number) => {
  let sdd = "";
  let smm = "";
  let date = new Date(ts);

  let dd = date.getDate();
  if (dd < 10) {
    sdd = `0${dd}`;
  } else {
    sdd = `${dd}`;
  }
  let mm = date.getMonth();
  if (mm + 1 < 10) {
    smm = `0${mm + 1}`;
  } else {
    smm = `${mm + 1}`;
  }

  const h = date.getHours();
  let hStr = "";
  if (h < 10) {
    hStr = `0${h}`;
  } else {
    hStr = `${h}`;
  }

  const min = date.getMinutes();
  let minStr = "";
  if (min < 10) {
    minStr = `0${min}`;
  } else {
    minStr = `${min}`;
  }

  return `${sdd}/${smm}/${date.getFullYear()} | ${hStr}:${minStr}`;
};
