import { useEffect, useState } from "react";
import { Chart, PieSeries } from "@devexpress/dx-react-chart-material-ui";
import { Animation } from "@devexpress/dx-react-chart";
import { Palette } from "@devexpress/dx-react-chart";

import { useHttpClient } from "../../../shared/hooks/httpHook";
import { ModalError } from "../../../shared/components/UIElements/ModalError";
import { LoadingSpinnerOverlayRegular } from "../../../shared/components/UIElements/LoadingSpinnerOverlayRegular";
import { addThousandSeparator } from "../../../shared/util/addThousandSeparator";
import { generateFormattedDateFromTimestamp } from "../../../shared/util/generateFormattedDateFromTimestamp";
import { getMonthNameAndYear } from "../../../shared/util/getMonthNameAndYear";
import { populateKanbanItem } from "../../../sales/api/salesFunnelAPI";
import {
  AuthContextProps,
  SettingsContextProps,
  SalePopCustData,
  KanbanFilledData,
  SettingsContextData,
} from "../../../shared/data/types";

import "./HomeDashboard.scss";

interface HomeDashboardViewedPropsSaleItemProps {
  item: SalePopCustData;
  auth: AuthContextProps;
  fetchId: string;
  kanbanData: KanbanFilledData;
  settingsCtx: SettingsContextData;
  setLoadedData: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
  setActiveItem: React.Dispatch<React.SetStateAction<string>>;
}

export const HomeDashboardViewedPropsSaleItem = (
  props: HomeDashboardViewedPropsSaleItemProps
) => {
  const {
    item,
    auth,
    fetchId,
    kanbanData,
    settingsCtx,
    setLoadedData,
    setActiveItem,
  } = props;
  const { error, sendRequest, clearError, isLoading } = useHttpClient();
  const itemStatusTxt =
    item.status === "IN_PROGRESS"
      ? "ABERTO"
      : item.status === "CLOSED"
      ? "GANHO"
      : "PERDIDO";

  const handlePopulateAndActivateItem = () => {
    if (!item?.customer?.user || !item.registryDate) {
      populateKanbanItem({
        sendRequest,
        auth,
        setKanbanData: setLoadedData,
        itemId: item._id,
        type: "SALES",
      }).then(() => {
        setActiveItem(item?._id);
      });
    } else {
      setActiveItem(item?._id);
    }
  };

  return (
    <div className="dashboard-item__list-item-container">
      {isLoading && <LoadingSpinnerOverlayRegular size={25} />}
      <div
        onClick={handlePopulateAndActivateItem}
        className="dashboard-item__list-item dashboard-item__list-item--4-col"
      >
        <p className="dashboard-item__list-item-name">{item.customer?.name}</p>
        <p className="dashboard-item__list-item-amount">
          R$ {addThousandSeparator(item.amount)}
        </p>
        <span
          className={`dashboard-item__list-item-status ${
            item.status === "IN_PROGRESS"
              ? "dashboard-item__list-item-status--neutral"
              : item.status === "CLOSED"
              ? "dashboard-item__list-item-status--pos"
              : "dashboard-item__list-item-status--neg"
          }`}
        >
          {itemStatusTxt}
        </span>
        <p className="dashboard-item__list-item-date">
          {generateFormattedDateFromTimestamp(item.lastViewTimestamp)}
        </p>
      </div>
    </div>
  );
};
