import { useEffect, useState } from "react";
import { Chart, PieSeries } from "@devexpress/dx-react-chart-material-ui";
import { Animation } from "@devexpress/dx-react-chart";
import { Palette } from "@devexpress/dx-react-chart";

import { useHttpClient } from "../../../shared/hooks/httpHook";
import { ModalError } from "../../../shared/components/UIElements/ModalError";
import { LoadingSpinnerOverlayRegular } from "../../../shared/components/UIElements/LoadingSpinnerOverlayRegular";
import { addThousandSeparator } from "../../../shared/util/addThousandSeparator";
import { getMonthNameAndYear } from "../../../shared/util/getMonthNameAndYear";
import { getClosedSales } from "../../api/analyticsAPI";
import {
  AuthContextProps,
  SettingsContextProps,
} from "../../../shared/data/types";

import "./HomeDashboard.scss";

interface HomeDashboardConvRatesProps {
  settingsCtx: SettingsContextProps;
  auth: AuthContextProps;
  fetchId: string;
  genSales: number[];
  closedSales: number[];
  isLoadingGenSales: boolean;
  isLoadingClosedSales: boolean;
}

export const HomeDashboardConvRates = (props: HomeDashboardConvRatesProps) => {
  const {
    settingsCtx,
    auth,
    fetchId,
    genSales,
    closedSales,
    isLoadingGenSales,
    isLoadingClosedSales,
  } = props;
  const { error, sendRequest, clearError, isLoading } = useHttpClient();
  const [loadedData, setLoadedData] = useState<
    {
      label: string;
      val: number;
      delta: number;
      deltaIsPositive: boolean;
    }[]
  >([
    {
      label: "",
      val: 0,
      delta: 0,
      deltaIsPositive: true,
    },
  ]);

  useEffect(() => {
    if (
      genSales?.length > 0 &&
      closedSales?.length > 0 &&
      !isLoadingGenSales &&
      !isLoadingClosedSales
    ) {
      let dataToSave: {
        label: string;
        val: number;
        delta: number;
        deltaIsPositive: boolean;
      }[] = [];

      genSales.forEach((gs, i) => {
        const cs = closedSales?.[i];
        const convRate = (cs / gs || 0) * 100;
        dataToSave.push({
          label: `${i}`,
          val:
            typeof convRate === "number" && convRate !== Infinity
              ? convRate <= 100
                ? convRate
                : 100
              : convRate === Infinity
              ? 100
              : 0,
          delta: 0,
          deltaIsPositive: true,
        });
      });

      dataToSave.forEach((data, i) => {
        if (i < dataToSave?.length - 1) {
          const delta =
            dataToSave?.[i]?.val / dataToSave?.[i + 1]?.val >= 1
              ? (dataToSave?.[i]?.val / dataToSave?.[i + 1]?.val - 1) * 100
              : (1 - dataToSave?.[i]?.val / dataToSave?.[i + 1]?.val) * 100;

          dataToSave[i].delta = delta;
          dataToSave[i].deltaIsPositive =
            dataToSave?.[i]?.val / dataToSave?.[i + 1]?.val >= 1;
        }
      });

      setLoadedData(dataToSave);
    }
  }, [isLoadingGenSales, isLoadingClosedSales, genSales, closedSales]);

  return (
    <div className="dashboard-item dashboard-item--conv-rates">
      {(isLoadingClosedSales || isLoadingGenSales) && (
        <LoadingSpinnerOverlayRegular />
      )}
      <ModalError error={error} onClear={clearError} />
      <p className="dashboard-item__title">Taxas de Conversão</p>
      {/* {loadedData.length > 0 && (
        <div className="dashboard-item__doughnut-chart">
          <Chart height={250} data={loadedData}>
            <Palette scheme={["#05445e", "#189ab4", "#75e6da", "#d4f1f4"]} />
            <PieSeries
              valueField="val"
              argumentField="label"
              innerRadius={0.6}
            />
            <Animation />
          </Chart>
        </div>
      )} */}
      {loadedData?.length >= 3 && (
        <div className="dashboard-item__legends dashboard-item__legends--chart">
          <div className="dashboard-item__legend dashboard-item__legend--chart">
            <Chart
              height={90}
              data={[
                loadedData?.[0],
                { ...loadedData[0], val: 100 - loadedData?.[0]?.val },
              ]}
            >
              <Palette scheme={["#05445e", "rgba(5, 66, 92, 0.25)"]} />
              <PieSeries
                valueField="val"
                argumentField="label"
                innerRadius={0.6}
              />
              <Animation />
            </Chart>
            <div className="dashboard-item__legend-data dashboard-item__legend-data--chart">
              <p className="dashboard-item__legend-value">
                {addThousandSeparator(loadedData?.[0]?.val || 0)}%
                {!!loadedData?.[0]?.delta && (
                  <span
                    className={`dashboard-item__legend-value-delta ${
                      loadedData?.[0]?.deltaIsPositive
                        ? "dashboard-item__legend-value-delta--pos"
                        : "dashboard-item__legend-value-delta--neg"
                    }`}
                  >
                    {addThousandSeparator(loadedData?.[0]?.delta || 0)
                      ?.split(",")?.[0]
                      ?.replace("Infinity", "∞")}
                    %
                  </span>
                )}
              </p>
              <p className="dashboard-item__legend-period">
                {getMonthNameAndYear(0)}
              </p>
            </div>
          </div>
          <div className="dashboard-item__legend dashboard-item__legend--chart">
            <Chart
              height={90}
              data={[
                loadedData?.[1],
                { ...loadedData[1], val: 100 - loadedData?.[1]?.val || 0 },
              ]}
            >
              <Palette scheme={["#189ab4", "rgba(24, 154, 180, 0.25)"]} />
              <PieSeries
                valueField="val"
                argumentField="label"
                innerRadius={0.6}
              />
              <Animation />
            </Chart>
            <div className="dashboard-item__legend-data dashboard-item__legend-data--chart">
              <p className="dashboard-item__legend-value">
                {addThousandSeparator(loadedData?.[1]?.val || 0)}%
                {!!loadedData?.[1]?.delta && (
                  <span
                    className={`dashboard-item__legend-value-delta ${
                      loadedData?.[1]?.deltaIsPositive
                        ? "dashboard-item__legend-value-delta--pos"
                        : "dashboard-item__legend-value-delta--neg"
                    }`}
                  >
                    {addThousandSeparator(loadedData?.[1]?.delta || 0)
                      ?.split(",")?.[0]
                      ?.replace("Infinity", "∞")}
                    %
                  </span>
                )}
              </p>
              <p className="dashboard-item__legend-period">
                {getMonthNameAndYear(-1)}
              </p>
            </div>
          </div>
          <div className="dashboard-item__legend dashboard-item__legend--chart">
            <Chart
              height={90}
              data={[
                loadedData?.[2],
                { ...loadedData[2], val: 100 - loadedData?.[2]?.val || 0 },
              ]}
            >
              <Palette scheme={["#75e6da", "rgba(117, 230, 218, 0.25)"]} />
              <PieSeries
                valueField="val"
                argumentField="label"
                innerRadius={0.6}
              />
              <Animation />
            </Chart>
            <div className="dashboard-item__legend-data dashboard-item__legend-data--chart">
              <p className="dashboard-item__legend-value">
                {addThousandSeparator(loadedData?.[2]?.val || 0)}%
              </p>
              <p className="dashboard-item__legend-period">
                {getMonthNameAndYear(-2)}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
