import { makeStyles } from "@material-ui/core/styles";

export const MainNavigationStyles = makeStyles((theme) => ({
  scrollRootLeft: {
    position: "fixed",
    zIndex: 500,
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
  root: {
    flexGrow: 1,
    zIndex: 500,
  },
  appBar: {
    zIndex: 500,
    backgroundColor: "#fff",
    color: "#263351",
    height: "80px",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      height: "64px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "56px",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "inline-block",
    },
  },
  title: {
    flexGrow: 1,
  },
  desktopNavlinks: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobileNavlinks: {
    width: "250px",
    height: "100%",
  },
  navbarContentPadding: {
    padding: "0 1.5rem",
    [theme.breakpoints.down("sm")]: {
      padding: "0 0.5rem",
    },
  },
  formExtRoot: {
    position: "relative",
  },
  formRoot: {
    position: "relative",
    width: "380px",
    padding: "20px 10px",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      width: "300px",
    },
    "& > *": {
      margin: theme.spacing(1),
      width: "365px",
      [theme.breakpoints.down("xs")]: {
        width: "280px",
      },
    },
  },
}));
