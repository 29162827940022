export const formatCpfOrCnpj = (document: string) => {
  const docNumbers = document?.replace(/\D/g, "");
  let formattedDoc = "";

  if (docNumbers?.length === 11) {
    const strA = docNumbers.substr(0, 3);
    const strB = docNumbers.substr(3, 3);
    const strC = docNumbers.substr(6, 3);
    const strD = docNumbers.substr(9, 2);

    formattedDoc = `${strA}.${strB}.${strC}-${strD}`;
  } else if (docNumbers?.length === 14) {
    const strA = docNumbers.substr(0, 2);
    const strB = docNumbers.substr(2, 3);
    const strC = docNumbers.substr(5, 3);
    const strD = docNumbers.substr(8, 4);
    const strE = docNumbers.substr(12, 2);

    formattedDoc = `${strA}.${strB}.${strC}/${strD}-${strE}`;
  }

  return formattedDoc;
};
