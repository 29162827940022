import { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import DeleteForeverRoundedIcon from "@material-ui/icons/DeleteForeverRounded";

import { ModalConfirm } from "../../shared/components/UIElements/ModalConfirm";
import { PopoverFormCreateFolder } from "./PopoverFormCreateFolder";
import { PopoverCreateFile } from "./PopoverCreateFile";
import { deleteFolder } from "../api/archiveAPI";
import { AuthContextProps, ArchiveData } from "../../shared/data/types";

interface MenuFolderOptionsProps {
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean
  ) => Promise<any>;
  anchorEl: HTMLButtonElement;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLButtonElement>>;
  setLoadedArchive: React.Dispatch<React.SetStateAction<ArchiveData>>;
  folder: ArchiveData["folders"][0];
  auth: AuthContextProps;
  cid: string;
}

export const MenuFolderOptions = (props: MenuFolderOptionsProps) => {
  const {
    anchorEl,
    setAnchorEl,
    auth,
    folder,
    cid,
    sendRequest,
    setLoadedArchive,
  } = props;
  const history = useHistory();
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [anchorElFormFolder, setAnchorElFormFolder] =
    useState<null | HTMLButtonElement>(null);
  const [anchorElFormFile, setAnchorElFormFile] =
    useState<null | HTMLButtonElement>(null);

  const closeConfirmHandler = () => {
    setConfirmDelete(false);
  };

  const confirmHandler = () => {
    deleteFolder({
      auth,
      sendRequest,
      cid,
      folderId: folder._id,
      setLoadedArchive,
    });
    setConfirmDelete(false);
  };

  return (
    <Fragment>
      <ModalConfirm
        open={confirmDelete}
        closeHandler={closeConfirmHandler}
        confirmHandler={confirmHandler}
        message="Deseja excluir permanentemente esta pasta?"
      />
      <PopoverFormCreateFolder
        id="popover-edit-folder"
        anchorEl={anchorElFormFolder}
        setAnchorEl={setAnchorElFormFolder}
        auth={auth}
        sendRequest={sendRequest}
        setLoadedArchive={setLoadedArchive}
        apiCall="edit"
        initialValue={folder.name || ""}
        folderId={folder._id}
        cid={cid}
      />
      <PopoverCreateFile
        id="popover-edit-file"
        anchorEl={anchorElFormFile}
        setAnchorEl={setAnchorElFormFile}
        auth={auth}
        sendRequest={sendRequest}
        setLoadedArchive={setLoadedArchive}
        apiCall="create"
        folderId={folder._id}
        cid={cid}
      />
      <div>
        <Menu
          id="opcoes-item-funil"
          style={{ zIndex: 10002 }}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => {
            setAnchorEl(null);
          }}
        >
          <MenuItem
            onClick={(e) => {
              setAnchorElFormFile(
                e.currentTarget as unknown as HTMLButtonElement
              );
            }}
          >
            <AddRoundedIcon style={{ marginRight: "0.75rem" }} /> Novo Arquivo
          </MenuItem>
          <MenuItem
            onClick={(e) => {
              setAnchorElFormFolder(
                e.currentTarget as unknown as HTMLButtonElement
              );
            }}
          >
            <EditRoundedIcon style={{ marginRight: "0.75rem" }} /> Editar Pasta
          </MenuItem>
          <MenuItem
            onClick={() => {
              setConfirmDelete(true);
            }}
          >
            <DeleteForeverRoundedIcon style={{ marginRight: "0.75rem" }} />{" "}
            Excluir Pasta
          </MenuItem>
        </Menu>
      </div>
    </Fragment>
  );
};
