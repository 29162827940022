import Popover from "@material-ui/core/Popover";
import React, { Fragment } from "react";
import Button from "@material-ui/core/Button";

import { useForm } from "../../shared/hooks/formHook";
import { Input } from "../../shared/components/FormElements/Input";
import { PopoverFormStyles } from "../../sales/components/PopoverFormStyles";
import { VALIDATOR_REQUIRE } from "../../shared/util/validators";
import { addFolder, editFolder } from "../api/archiveAPI";
import { AuthContextProps, ArchiveData } from "../../shared/data/types";

interface PopoverFormCreateFolderProps {
  id: string;
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLButtonElement>>;
  auth: AuthContextProps;
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean
  ) => Promise<any>;
  setLoadedArchive: React.Dispatch<React.SetStateAction<ArchiveData>>;
  apiCall: "create" | "edit";
  folderId?: string;
  initialValue?: string;
  cid: string;
}

export const PopoverFormCreateFolder = (
  props: PopoverFormCreateFolderProps
) => {
  const {
    id,
    folderId,
    anchorEl,
    setAnchorEl,
    auth,
    sendRequest,
    setLoadedArchive,
    apiCall,
    initialValue = "",
    cid,
  } = props;
  const open = Boolean(anchorEl);
  const classes = PopoverFormStyles();
  const { formState, inputHandler } = useForm(
    {
      name: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  const addFolderHandler = () => {
    addFolder({
      sendRequest,
      auth,
      formState,
      setLoadedArchive,
      cid,
    });

    setAnchorEl(null);
  };

  const editFolderHandler = () => {
    editFolder({
      sendRequest,
      auth,
      formState,
      setLoadedArchive,
      folderId,
      cid,
    });

    setAnchorEl(null);
  };

  return (
    <Fragment>
      <Popover
        style={{ zIndex: 10002 }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <form className={classes.formRoot}>
          <Input
            id="name"
            type="text"
            label="Nome da Pasta"
            variant="outlined"
            validators={[VALIDATOR_REQUIRE()]}
            helperText="Insira um nome válido."
            onInput={inputHandler}
            initialValue={initialValue || ""}
            initialValid={!!initialValue}
            isName
          />
          <Button
            variant="contained"
            color="primary"
            disabled={!formState.isValid}
            onClick={() => {
              if (apiCall === "create") {
                addFolderHandler();
              } else if (apiCall === "edit") {
                editFolderHandler();
              }
            }}
          >
            {apiCall === "create" ? "ADICIONAR PASTA" : "EDITAR NOME DA PASTA"}
          </Button>
        </form>
      </Popover>
    </Fragment>
  );
};
