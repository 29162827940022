import { Fragment, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import Collapse from "@material-ui/core/Collapse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWaveSquare } from "@fortawesome/free-solid-svg-icons";

import { addThousandSeparator } from "../../shared/util/addThousandSeparator";
import { ProposalData, AuthContextProps } from "../../shared/data/types";

import "./PropDashUcItem.scss";

interface PropDashInvItemProps {
  auth: AuthContextProps;
  index: number;
  loadedInv: ProposalData["inverterData"][0];
}

export const PropDashInvItem = (props: PropDashInvItemProps) => {
  const { loadedInv, auth, index } = props;
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Fragment>
      <div
        className="cust-prop-item-prop"
        onClick={(e) => {
          setOpen(!open);
        }}
      >
        <div className="cust-prop-item-prop__data">
          <p className="cust-prop-item-prop__data-item cust-prop-item-prop__data-item--thin">
            {loadedInv?.iModel || ""}
          </p>
          <div className="cust-prop-item-prop__user-data">
            <FontAwesomeIcon
              icon={faWaveSquare}
              style={{ fontSize: "1.25rem", marginRight: "0.25rem" }}
            />
            <div className="cust-prop-item-prop__user-data-content">
              <p className="cust-prop-item-prop__data-item">
                {addThousandSeparator(loadedInv?.iPower)} W
              </p>
              <p className="cust-prop-item-prop__data-item">
                {loadedInv?.iQuantity} Unidades
              </p>
            </div>
          </div>
        </div>
        <div className="cust-prop-item-prop__controls">
          <IconButton
            size="small"
            title={open ? "Ver Mais" : "Fechar"}
            className={`table__item-action${open ? "--open" : ""}`}
            onClick={(e) => {
              setOpen(!open);
              e.stopPropagation();
            }}
          >
            <ExpandMoreRoundedIcon style={{ fontSize: "22px" }} />
          </IconButton>
        </div>
        <div className="cust-prop-item-prop__details">
          <Collapse in={open}>
            <div className="cust-prop-item-prop__details-content">
              <p className="cust-prop-item-prop__data-item cust-prop-item-prop__data-item--thin">
                Fabricante:{" "}
                <span className="cust-prop-item-prop__data-item--highlight">
                  {loadedInv?.iManufacturer}
                </span>
              </p>
              <p className="cust-prop-item-prop__data-item cust-prop-item-prop__data-item--thin">
                Garantia:{" "}
                <span className="cust-prop-item-prop__data-item--highlight">
                  {loadedInv?.iWarranty} Anos
                </span>
              </p>
              <p className="cust-prop-item-prop__data-item cust-prop-item-prop__data-item--thin">
                Monitoramento:{" "}
                <span className="cust-prop-item-prop__data-item--highlight">
                  {loadedInv?.iMonitoring}
                </span>
              </p>
              <p className="cust-prop-item-prop__data-item cust-prop-item-prop__data-item--thin">
                Topologia:{" "}
                <span className="cust-prop-item-prop__data-item--highlight">
                  {loadedInv?.type === "microinverter"
                    ? "Micro Inversor"
                    : "Inversor de String"}
                </span>
              </p>
            </div>
          </Collapse>
        </div>
      </div>
    </Fragment>
  );
};
