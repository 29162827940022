import {
  ContractData,
  AuthContextProps,
  KanbanFilledData,
  SalePopCustData,
} from "../../shared/data/types";

interface FetchProps {
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean,
    zeroResultsKey?: string
  ) => Promise<any>;
  auth: AuthContextProps;
}

// CREATE SALE CONTRACT ///////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////
interface GenerateContractProps extends FetchProps {
  name: string;
  file: File;
  contractId: string;
  saleId: string;
  setKanbanData: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
}

export const generateContract = async (props: GenerateContractProps) => {
  const { sendRequest, auth, name, file, contractId, saleId, setKanbanData } =
    props;

  const formData = new FormData();
  formData.append("file", file);
  formData.append("name", name);
  formData.append("content", "");

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/sales/contracts/${auth.userId}/${saleId}/${contractId}`;
    const responseData: { contracts: SalePopCustData["contracts"] } =
      await sendRequest(
        apiUrl,
        "PUT",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        },
        true
      );

    setKanbanData((prevValues) => {
      const processedItems = prevValues?.items?.map((item) => {
        if (item._id === saleId) {
          item.contracts = responseData.contracts;
        }

        return item;
      });

      return { kanban: prevValues.kanban, items: processedItems };
    });
  } catch (err) {}
};

// DELETE SALE CONTRACT ///////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////
interface DeleteSaleContractProps extends FetchProps {
  saleContractId: string;
  saleId: string;
  setKanbanData: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
}

export const deleteSaleContract = async (props: DeleteSaleContractProps) => {
  const { sendRequest, auth, saleContractId, saleId, setKanbanData } = props;

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/sales/contracts/${auth.userId}/${saleId}/${saleContractId}`;
    const responseData: { contracts: SalePopCustData["contracts"] } =
      await sendRequest(
        apiUrl,
        "DELETE",
        null,
        {
          Authorization: "Bearer " + auth.token,
        },
        true
      );

    setKanbanData((prevValues) => {
      const processedItems = prevValues?.items?.map((item) => {
        if (item._id === saleId) {
          item.contracts = item.contracts.filter((c) => {
            return c._id !== saleContractId;
          });
        }

        return item;
      });

      return { kanban: prevValues.kanban, items: processedItems };
    });
  } catch (err) {}
};
