import { useState } from "react";
import Popover from "@material-ui/core/Popover";
import HelpOutlineRoundedIcon from "@material-ui/icons/HelpOutlineRounded";

import "./Help.scss";

interface HelpProps {
  content: string;
}

export const Help = (props: HelpProps) => {
  const { content } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLDivElement>(null);

  return (
    <div className="help">
      <div
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
        className={`help__btn ${!!anchorEl ? "help__btn--active" : ""}`}
      >
        <HelpOutlineRoundedIcon />
      </div>
      <Popover
        id="serrana-inv-model-edit"
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        style={{ zIndex: 501, marginTop: "0.5rem" }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="help__content">
          <p className="help__content-p">{content}</p>
        </div>
      </Popover>
    </div>
  );
};
