import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";

export const MenuItemCustomBlueFocusBackground = withStyles((theme) => ({
  root: {
    "& span, & svg": {
      color: "#263351",
    },
    "&:focus": {
      // backgroundColor: "#263351",
      "& svg": {
        color: "#007bed",
      },
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: "#007bed",
      },
    },
  },
}))(MenuItem);
