import { useState } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MailIcon from "@material-ui/icons/Mail";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import SmsIcon from "@material-ui/icons/Sms";

import { ModalConfirm } from "../../shared/components/UIElements/ModalConfirm";
import { notifySigner } from "../api/signatureAPI";
import {
  AuthContextProps,
  SignatureData,
  SalePopCustData,
} from "../../shared/data/types";

interface MenuNotifySignaturePersonProps {
  auth: AuthContextProps;
  anchorEl: HTMLButtonElement | HTMLDivElement;
  setAnchorEl: React.Dispatch<
    React.SetStateAction<HTMLButtonElement | HTMLDivElement>
  >;
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean,
    zeroResultsKey?: string
  ) => Promise<any>;
  saleId: string;
  loadedContract: SalePopCustData["contracts"][0];
  loadedSignature: SignatureData;
  loadedPerson: SignatureData["persons"][0];
  setLoadedSignature: React.Dispatch<React.SetStateAction<SignatureData>>;
}

export const MenuNotifySignaturePerson = (
  props: MenuNotifySignaturePersonProps
) => {
  const {
    auth,
    saleId,
    anchorEl,
    setAnchorEl,
    sendRequest,
    loadedContract,
    loadedSignature,
    loadedPerson,
    setLoadedSignature,
  } = props;
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [chosenMethod, setChosenMethod] = useState<
    "E-Mail" | "WhatsApp" | "SMS"
  >("E-Mail");

  const confirmHandler = async (type: "E-Mail" | "WhatsApp" | "SMS") => {
    notifySigner({
      auth,
      sendRequest,
      chosenMethod: type,
      saleId,
      saleContractId: loadedContract._id,
      personId: loadedPerson._id,
      setLoadedSignature,
    });
    setShowConfirmModal(false);
    setAnchorEl(null);
  };

  const confirmModalCloseHandler = () => {
    setShowConfirmModal(false);
  };

  return (
    <div>
      <ModalConfirm
        open={showConfirmModal}
        closeHandler={confirmModalCloseHandler}
        confirmHandler={() => {
          confirmHandler(chosenMethod);
        }}
        message="Deseja notificar este signatário sobre a assinatura do contrato? O link da assinatura será enviado diretamente no corpo da mensagem de notificação."
      />
      <Menu
        style={{ zIndex: 10001 }}
        id="opcoes-item-funil"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        <MenuItem
          onClick={(e) => {
            setChosenMethod("E-Mail");
            setShowConfirmModal(true);
          }}
        >
          <MailIcon style={{ marginRight: "0.75rem" }} /> E-Mail
        </MenuItem>
        <MenuItem
          disabled={loadedPerson.auth !== "whatsapp"}
          onClick={() => {
            setChosenMethod("WhatsApp");
            setShowConfirmModal(true);
          }}
        >
          <WhatsAppIcon style={{ marginRight: "0.75rem" }} /> WhatsApp
        </MenuItem>
        <MenuItem
          disabled={loadedPerson.auth !== "sms"}
          onClick={() => {
            setChosenMethod("SMS");
            setShowConfirmModal(true);
          }}
        >
          <SmsIcon style={{ marginRight: "0.75rem" }} /> SMS
        </MenuItem>
      </Menu>
    </div>
  );
};
