import { IDBPDatabase } from "idb";

import {
  AuthContextProps,
  HistoryData,
  FormHookDispState,
} from "../../shared/data/types";

export interface FetchProps {
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean,
    zeroResultsKey?: string
  ) => Promise<any>;
  auth: AuthContextProps;
}

interface GetCustomerHistoryProps extends FetchProps {
  cid: string;
  setLoadedData: React.Dispatch<React.SetStateAction<HistoryData[]>>;
  setShowLoadMore: React.Dispatch<React.SetStateAction<boolean>>;
  multiplier: number;
  hidration: boolean;
}

export const getCustomerHistory = async (props: GetCustomerHistoryProps) => {
  const {
    cid,
    sendRequest,
    auth,
    setLoadedData,
    setShowLoadMore,
    multiplier,
    hidration,
  } = props;

  try {
    const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/histories/customer/${cid}/${multiplier}`;
    const responseData: { history: HistoryData[]; hasMoreItems: boolean } =
      await sendRequest(apiUrl, "GET", null, {
        Authorization: "Bearer " + auth.token,
      });

    setShowLoadMore(responseData.hasMoreItems);
    setLoadedData((prevValues) => {
      if (!!prevValues && prevValues.length > 0 && !hidration) {
        return [...prevValues, ...responseData.history];
      } else {
        return responseData.history;
      }
    });
  } catch (err) {}
};
