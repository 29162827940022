import { useState, useCallback, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { ACTIVE_VIEW_MAP } from "../data/static";
import { NavContextData } from "../data/types";

interface UserNavProps {
  pathName: string;
}

export const useNav = (props: UserNavProps) => {
  const { pathName } = props;
  const history = useHistory();
  const [activeView, setActiveView] = useState<number>(1200);
  const [activeUrl, setActiveUrl] = useState<string>("/login");

  const navigateHandler = useCallback((url: string) => {
    let activeViewKey = "home";
    for (const key in ACTIVE_VIEW_MAP) {
      let k = key as "home";
      if (url.includes(ACTIVE_VIEW_MAP.home.url.split("/:")[0])) {
        activeViewKey = k;
      }
    }

    setActiveView(ACTIVE_VIEW_MAP[activeViewKey as "home"].view);
    updateLocalStorageDataHandler(
      ACTIVE_VIEW_MAP[activeViewKey as "home"].view,
      "activeView"
    );
    setActiveUrl(url);
    updateLocalStorageDataHandler(url, "activeUrl");
    history.push(url);
  }, []);

  const updateLocalStorageDataHandler = useCallback(
    (data: string | number, key: string) => {
      const navData: NavContextData = JSON.parse(
        localStorage.getItem("navData")
      );

      if (navData) {
        localStorage.setItem(
          "navData",
          JSON.stringify({ ...navData, [key]: data })
        );
      } else {
        localStorage.setItem("navData", JSON.stringify({ [key]: data }));
      }
    },
    []
  );

  const setActiveNavDataHandler = useCallback(
    (dashboardNavData: NavContextData) => {
      setActiveView(dashboardNavData.activeView);
      setActiveUrl(dashboardNavData.activeUrl);
    },
    []
  );

  useEffect(() => {
    if (!activeUrl.includes(pathName)) {
      let activeViewKey = "home";
      for (const key in ACTIVE_VIEW_MAP) {
        let k = key as "home";
        if (pathName.includes(ACTIVE_VIEW_MAP[k].url.split("/:")[0])) {
          activeViewKey = k;
        }
      }

      setActiveView(ACTIVE_VIEW_MAP[activeViewKey as "home"].view);
      updateLocalStorageDataHandler(
        ACTIVE_VIEW_MAP[activeViewKey as "home"].view,
        "activeView"
      );
      setActiveUrl(pathName);
      updateLocalStorageDataHandler(pathName, "activeUrl");
    }
  }, [pathName]);

  useEffect(() => {
    const navData: NavContextData = JSON.parse(localStorage.getItem("navData"));

    if (navData) {
      setActiveNavDataHandler(navData);
    }
  }, []);

  // useEffect(() => {
  //   if (auth.isLoggedIn) {
  //     setActiveView(100);
  //     updateLocalStorageDataHandler(100, "activeView");
  //     setActiveUrl("/inicio");
  //     updateLocalStorageDataHandler("/inicio", "activeUrl");
  //   } else {
  //     setActiveView(1200);
  //     updateLocalStorageDataHandler(1200, "activeView");
  //     setActiveUrl("/login");
  //     updateLocalStorageDataHandler("/login", "activeUrl");
  //   }
  // }, [auth.isLoggedIn]);

  return {
    activeView,
    activeUrl,
    navigateHandler,
  };
};
