import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { ModalErrorStyles } from "./ModalErrorStyles";

interface ModalZeroResultsProps {
  show: boolean;
  closePopupHandler: () => void;
}

export const ModalZeroResults = (props: ModalZeroResultsProps) => {
  const { show, closePopupHandler } = props;
  const classes = ModalErrorStyles();

  return (
    <div>
      <Dialog style={{ zIndex: 10000 }} open={show} onClose={closePopupHandler}>
        <DialogTitle className={classes.errorModalTitle}>
          NENHUM RESULTADO
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.errorModalContent}>
            Não foi encontrado nenhum resultado de pesquisa para os filtros
            aplicados.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closePopupHandler} color="secondary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
