import { makeStyles } from "@material-ui/core/styles";

export const InputCalendarStyles = makeStyles(() => ({
  root: {
    "& button": {
      position: "relative",
      bottom: "10px",
    },
  },
}));
