import Popover from "@material-ui/core/Popover";
import React, { Fragment, useEffect } from "react";
import Button from "@material-ui/core/Button";

import { FileUpload } from "../../shared/components/FormElements/FileUpload";
import { useForm } from "../../shared/hooks/formHook";
import { Input } from "../../shared/components/FormElements/Input";
import { PopoverFormStyles } from "../../sales/components/PopoverFormStyles";
import { VALIDATOR_REQUIRE } from "../../shared/util/validators";
import { addFile, editFile } from "../api/archiveAPI";
import { AuthContextProps, ArchiveData } from "../../shared/data/types";

interface PopoverCreateFileProps {
  id: string;
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLButtonElement>>;
  auth: AuthContextProps;
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean
  ) => Promise<any>;
  setLoadedArchive: React.Dispatch<React.SetStateAction<ArchiveData>>;
  apiCall: "create" | "edit";
  folderId: string;
  fileId?: string;
  file?: ArchiveData["files"][0];
  cid: string;
}

export const PopoverCreateFile = (props: PopoverCreateFileProps) => {
  const {
    id,
    folderId,
    fileId,
    file,
    anchorEl,
    setAnchorEl,
    auth,
    sendRequest,
    setLoadedArchive,
    apiCall,
    cid,
  } = props;
  const open = Boolean(anchorEl);
  const classes = PopoverFormStyles();
  const { formState, inputHandler, setFormData } = useForm(
    {
      name: {
        value: "",
        isValid: false,
      },
      file: {
        value: "",
        isValid: !!file?.url,
      },
    },
    false
  );
  const fileIsImage =
    file?.url?.includes(".jpg") ||
    file?.url?.includes(".png") ||
    file?.url?.includes(".jpeg") ||
    file?.url?.includes(".webp");

  const addFileHandler = () => {
    addFile({
      sendRequest,
      auth,
      setLoadedArchive,
      cid,
      folderId,
      formState,
    });
    setAnchorEl(null);
  };

  const editFileHandler = () => {
    editFile({
      sendRequest,
      auth,
      setLoadedArchive,
      cid,
      fileId,
      formState,
    });
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!open) {
      setFormData(
        {
          name: {
            value: "",
            isValid: false,
          },
          file: {
            value: "",
            isValid: !!file?.url,
          },
        },
        false
      );
    }
  }, [open]);

  return (
    <Popover
      style={{ zIndex: 10002 }}
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={() => {
        setAnchorEl(null);
      }}
      anchorOrigin={{
        vertical: "center",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "right",
      }}
    >
      <form
        // style={{ minHeight: "525px", maxHeight: "525px" }}
        className={classes.formRoot}
      >
        <Input
          id="name"
          type="text"
          label="Nome do Arquivo"
          variant="outlined"
          validators={[VALIDATOR_REQUIRE()]}
          helperText="Insira um nome válido."
          onInput={inputHandler}
          initialValue={file?.name || ""}
          initialValid={!!file?.name}
          isName
        />
        <FileUpload
          id="file"
          onInput={inputHandler}
          notRequired={!!file?.url}
          showImgPreview
          initialFileName={
            file?.url?.substr(file?.url?.lastIndexOf("/") + 1) || ""
          }
          initialImage={fileIsImage ? file?.url : ""}
        />
        <Button
          variant="contained"
          color="primary"
          disabled={!formState.isValid}
          onClick={() => {
            if (apiCall === "create") {
              addFileHandler();
            } else if (apiCall === "edit") {
              editFileHandler();
            }
          }}
        >
          {apiCall === "create" ? "ADICIONAR ARQUIVO" : "EDITAR ARQUIVO"}
        </Button>
      </form>
    </Popover>
  );
};
