import React from "react";
import { dateFormat } from "./dateFormat";
import { validDateChecker } from "./validDateChecker";
import { BeforeInstallPromptEvent } from "../../App";

export const isIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(userAgent);
};

const isInStandaloneMode = () => {
  return "standalone" in window.navigator && window.navigator["standalone"];
};

export const addToHomescreenPrompt = (
  addToHomescreenEvent: BeforeInstallPromptEvent,
  setAddToHomescreenEvent: React.Dispatch<
    React.SetStateAction<BeforeInstallPromptEvent>
  >
) => {
  if (addToHomescreenEvent) {
    addToHomescreenEvent.prompt();
    setAddToHomescreenEvent(null);
  } else if (isIos() && !isInStandaloneMode()) {
    const data: { prompt: boolean; promptDate: string } = JSON.parse(
      localStorage.getItem("iosInstallPrompt")
    );
    const shouldPrompt = data ? !validDateChecker(data.promptDate) : true;
    if (shouldPrompt) {
      localStorage.setItem(
        "iosInstallPrompt",
        JSON.stringify({ prompt: true, promptDate: dateFormat(0) })
      );
    }
  }
};
