import React, { useReducer, useEffect, useState } from "react";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";

import { InputDispState, InputDispAction } from "./Input";
import { validate } from "../../util/validators";

const inputReducer = (state: InputDispState, action: InputDispAction) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.val,
        isValid: validate(action.val, action.validators),
      };
    case "TOUCH":
      return {
        ...state,
        isTouched: true,
      };
    case "UNTOUCH":
      return {
        ...state,
        isTouched: false,
      };
    default:
      return state;
  }
};

interface InputPasswordProps {
  id: string;
  label: string;
  onInput: (id: string, value: string, isValid: boolean, label: string) => void;
  helperText: string;
  initialValue?: string;
  initialValid?: boolean;
  forceError?: boolean;
  placeholder?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  reset?: boolean;
  reinitialize?: boolean;
  labelWidth: number;
  validators: { type: string; val?: number }[];
}

export const InputPassword = (props: InputPasswordProps) => {
  const {
    id,
    label,
    onInput,
    forceError,
    helperText,
    initialValue,
    initialValid,
    placeholder,
    fullWidth,
    disabled,
    validators,
    reinitialize,
    reset,
    labelWidth,
  } = props;
  const [inputState, dispatch] = useReducer(inputReducer, {
    value: initialValue || "",
    isTouched: false,
    isValid: initialValid || false,
  });
  const [showPassword, setShowPassword] = useState(false);
  const { value, isValid, isTouched } = inputState;

  useEffect(() => {
    onInput(id, value, isValid, label.charAt(0).toUpperCase() + label.slice(1));
  }, [id, value, isValid, label, onInput]);

  useEffect(() => {
    if (reset) {
      dispatch({
        type: "CHANGE",
        val: "",
        validators: validators,
      });
      dispatch({
        type: "UNTOUCH",
      });
    }
    if (reinitialize) {
      dispatch({
        type: "CHANGE",
        val: initialValue,
        validators: validators,
      });
      dispatch({
        type: "UNTOUCH",
      });
    }
  }, [reset, reinitialize, initialValue]);

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: "CHANGE",
      val: event.target.value,
      validators: validators,
    });
  };

  const touchHandler = () => {
    dispatch({ type: "TOUCH" });
  };

  const clickShowPasswordHandler = () => {
    setShowPassword(!showPassword);
  };

  const mouseDownPasswordHanlder = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
  };

  return (
    <FormControl variant="outlined">
      <InputLabel htmlFor={id}>
        {label.charAt(0).toUpperCase() + label.slice(1)}
      </InputLabel>
      <OutlinedInput
        fullWidth={fullWidth}
        disabled={disabled}
        error={(!isValid && isTouched) || forceError}
        value={value}
        onChange={changeHandler}
        onBlur={touchHandler}
        type={showPassword ? "text" : "password"}
        id={id}
        name={id}
        placeholder={placeholder}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              onClick={clickShowPasswordHandler}
              onMouseDown={mouseDownPasswordHanlder}
              edge="end"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
        labelWidth={labelWidth}
      />
      <FormHelperText id={id} error={(!isValid && isTouched) || forceError}>
        {forceError ? helperText : !isValid && isTouched && helperText}
      </FormHelperText>
    </FormControl>
  );
};
