export const getSaleStatusNameFromId = (
  id: "CLOSED" | "LOST" | "IN_PROGRESS",
  type: "SALES" | "PRE_SALES"
) => {
  if (type === "SALES") {
    switch (id) {
      case "CLOSED":
        return "Venda Ganha";

      case "LOST":
        return "Venda Perdida";

      default:
        return "";
    }
  } else if (type === "PRE_SALES") {
    switch (id) {
      case "CLOSED":
        return "Lead Qualificado";

      case "LOST":
        return "Lead Desqualificado";

      default:
        return "";
    }
  } else {
    return "";
  }
};
