import {
  AuthContextProps,
  UserLoginProps,
  History,
} from "../../shared/data/types";

interface FetchProps {
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean
  ) => Promise<any>;
  auth: AuthContextProps;
}

interface TestConnectionProps extends FetchProps {}

export const testConnection = async (props: TestConnectionProps) => {
  const { sendRequest, auth } = props;

  try {
    const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/admins/test-connection`;
    await sendRequest(apiUrl, "GET", null, {});
  } catch (err) {}
};

interface LogUser extends FetchProps {
  email: string;
  password: string;
  history: History;
}

export const logUser = async (props: LogUser) => {
  const { sendRequest, auth, email, password, history } = props;

  try {
    const responseData: UserLoginProps = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/users/login`,
      "POST",
      JSON.stringify({
        email: email,
        password: password,
      }),
      {
        "Content-Type": "application/json",
      }
    );

    if (!responseData.admAccess) {
      auth.login(
        responseData.userId,
        responseData.managerId,
        responseData.managerUserId,
        responseData.vendors,
        responseData.token,
        responseData.type,
        responseData.isAdmin,
        responseData.statusOk,
        responseData.status,
        responseData.signupPlan,
        responseData.activeModules || [],
        responseData.whitelabel || ""
      );
      localStorage.setItem("loginEmail", email);
      localStorage.setItem("loginPsd", password);
    } else {
      history.push(`/admin1?email=${email}&key=vrT%l-XhJ@dJ*Y78`);
    }
  } catch (err) {}
};
