import { Fragment } from "react";
import { useHistory } from "react-router-dom";

import { WarningBar } from "../../../shared/components/Navigation/WarningBar";
import { ModalError } from "../../../shared/components/UIElements/ModalError";
import { ModalNotification } from "../../../shared/components/UIElements/ModalNotification";
import { AuthContextProps } from "../../../shared/data/types";

interface HomeModalsProps {
  needCriticalUpdate: boolean;
  needUpdate: boolean;
  error: string;
  clearError: (needPush?: boolean) => void;
  auth: AuthContextProps;
}

export const HomeModals = (props: HomeModalsProps) => {
  const { needCriticalUpdate, needUpdate, error, clearError, auth } = props;
  const history = useHistory();

  return (
    <Fragment>
      <ModalError error={error} onClear={clearError} />
      <ModalNotification auth={auth} />
      {auth.statusOk && needCriticalUpdate && (
        <WarningBar
          text={
            <span>
              <u>Atualização Importante</u>! <b>FECHE E ABRA</b> o Azume (todas
              abas) até que essa mensagem DESAPAREÇA.
            </span>
          }
          clickableText="DETALHES DA ATUALIZAÇÃO"
          clickAction={() => {
            history.push("/atualizacoes");
          }}
          altColor
        />
      )}
      {auth.statusOk && needUpdate && !needCriticalUpdate && (
        <WarningBar
          text={
            <span>
              <u>Atualização Disponível</u>! <b>FECHE E ABRA</b> o Azume (todas
              abas) até que essa mensagem DESAPAREÇA.
            </span>
          }
          clickableText="DETALHES DA ATUALIZAÇÃO"
          clickAction={() => {
            history.push("/atualizacoes");
          }}
          blueColor
        />
      )}
      {auth.isLoggedIn && !auth.statusOk && (
        <WarningBar
          text="A sua situação de pagamento encontra-se irregular. Devido a isto, foram removidas as funcionalidades do aplicativo até que você possa"
          clickableText="REGULARIZAR A SITUAÇÃO"
          clickAction={() => {
            history.push("/financeiro");
          }}
        />
      )}
    </Fragment>
  );
};
