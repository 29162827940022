import React, { useState, useEffect } from "react";
import Fab from "@material-ui/core/Fab";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import Typography from "@material-ui/core/Typography";
import Zoom from "@material-ui/core/Zoom";
import Button from "@material-ui/core/Button";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";

import { Input } from "../../../shared/components/FormElements/Input";
import { InputPhone } from "../../../shared/components/FormElements/InputPhone";
import { InputSelectRequired } from "../../../shared/components/FormElements/InputSelectRequired";
import { LoadingSpinnerOverlayRegular } from "../../../shared/components/UIElements/LoadingSpinnerOverlayRegular";
import { ModalError } from "../../../shared/components/UIElements/ModalError";
import { ModalSuccess } from "../../../shared/components/UIElements/ModalSuccess";
import { ModalInvalid } from "../../../shared/components/UIElements/ModalInvalid";
import { useForm } from "../../hooks/formHook";
import { useHttpClient } from "../../../shared/hooks/httpHook";
import { removeFormStateProperty } from "../../../shared/util/removeFormStateProperties";
import { updateFormValidity } from "../../../shared/util/updateFormValidity";
import { openWhatsapp } from "../../../shared/util/openWhatsapp";
import { openNewTabUrl } from "../../../shared/util/openNewTabUrl";
import { useQuery } from "../../../shared/util/useQuery";
import { aboutPageFormHandler } from "../../../general/api/signupPagesAPI";
import {
  VALIDATOR_REQUIRE,
  VALIDATOR_EMAIL,
  VALIDATOR_MINLENGTH,
} from "../../util/validators";
import { TIME_WORKING, SALES_TEAM_STATUS } from "../../../shared/data/static";

import { MainNavigationStyles } from "../Navigation/MainNavigationStyles";
import "./ButtonWhatsAppStyles.css";

interface ButtonWhatsAppFormProps {
  zoom?: boolean;
}
export const ButtonWhatsAppForm = (props: ButtonWhatsAppFormProps) => {
  const { zoom } = props;
  const classes = MainNavigationStyles();
  const [showWindow, setShowWindow] = useState(false);
  const { formState, inputHandler, setFormData, reportInvalid } = useForm(
    {},
    false
  );
  const { isLoading, error, success, sendRequest, clearError, clearSuccess } =
    useHttpClient();
  const [invalidInputs, setInvalidInputs] = useState<{
    showInvalid: boolean;
    invalidFields: string[];
  }>({
    showInvalid: false,
    invalidFields: [],
  });
  const [vendorPhone, setVendorPhone] = useState<string>("");
  const [extFormValid, setExtFormValid] = useState<boolean>(false);
  const src = useQuery().get("src") || "";
  const tag = useQuery().get("tag") || "";

  const trigger = useScrollTrigger({
    target: window,
    disableHysteresis: true,
    threshold: 100,
  });

  const showWindowToggleHandler = () => {
    setShowWindow((prevValue) => {
      return !prevValue;
    });
  };

  const resetHandler = () => {
    setFormData({}, false, true);
  };

  useEffect(() => {
    if (formState.inputs?.timeWorking?.value?.toString()?.includes("Ainda")) {
      removeFormStateProperty(formState, "salesTeamStatus");
    }
  }, [formState?.inputs?.timeWorking?.value]);

  useEffect(() => {
    setExtFormValid(updateFormValidity(formState));
  }, [formState]);

  const registerHandler = () => {
    openNewTabUrl("https://assineazume.com.br/obrigado");
    aboutPageFormHandler({ sendRequest, formState, setVendorPhone, src, tag })
      .then(() => {
        // if (!openedTyp) {
        //   openNewTabUrl("https://assineazume.com.br/obrigado", setOpenedtyp);
        // }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const reportInvalidHandler = () => {
    const whatIsInvalid = reportInvalid(formState.inputs);
    setInvalidInputs({ showInvalid: true, invalidFields: whatIsInvalid });
  };

  const confirmModalCloseHandler = () => {
    setInvalidInputs({ showInvalid: false, invalidFields: [] });
  };

  return (
    <React.Fragment>
      <Zoom in={showWindow}>
        <div className="whatsapp-btn-window-form">
          {isLoading && <LoadingSpinnerOverlayRegular />}
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
            className={classes.formRoot}
          >
            <ModalError error={error} onClear={clearError} />
            <ModalSuccess
              success={success}
              onClear={clearSuccess}
              message="Cadastro efetuado com sucesso! Em até 24h úteis um de nossos consultores entrará em contato com você."
              dontPush
              reset={resetHandler}
            />
            <ModalInvalid
              open={invalidInputs.showInvalid}
              closeHandler={confirmModalCloseHandler}
              message={invalidInputs.invalidFields.map((field) => {
                return <li key={field + "key"}>{field}</li>;
              })}
            />
            <Typography className="about-centered-subtitle-no-margin">
              Cadastro para Contato
            </Typography>
            <Input
              id="name"
              type="text"
              label="nome"
              variant="outlined"
              validators={[VALIDATOR_REQUIRE()]}
              helperText="Insira um nome válido."
              onInput={inputHandler}
              isName
              forceUpperCase
              reset={formState.reset}
              forceError={formState.inputs?.name?.forceError}
            />
            <Input
              id="email"
              type="email"
              label="e-mail"
              variant="outlined"
              validators={[VALIDATOR_EMAIL()]}
              helperText="Insira um e-mail válido."
              onInput={inputHandler}
              reset={formState.reset}
              forceError={formState.inputs?.email?.forceError}
            />
            <InputPhone
              id="phone"
              type="text"
              label="celular"
              variant="outlined"
              validators={[VALIDATOR_MINLENGTH(10)]}
              helperText="Insira um número válido."
              onInput={inputHandler}
              reset={formState.reset}
              forceError={formState.inputs?.phone?.forceError}
            />
            <InputSelectRequired
              id="timeWorking"
              label="Já trabalha com Energia Solar?"
              variant="outlined"
              onInput={inputHandler}
              options={TIME_WORKING}
              reset={formState.reset}
              forceError={formState.inputs?.contactType?.forceError}
            />
            {!!formState.inputs?.timeWorking?.isValid &&
              !formState.inputs?.timeWorking?.value
                ?.toString()
                ?.includes("Ainda") && (
                <InputSelectRequired
                  id="salesTeamStatus"
                  label="Possui quantos vendedores?"
                  variant="outlined"
                  onInput={inputHandler}
                  options={SALES_TEAM_STATUS}
                  reset={formState.reset}
                  forceError={formState.inputs?.contactType?.forceError}
                />
              )}
            {extFormValid && (
              <Button
                onClick={registerHandler}
                type="submit"
                variant="contained"
                color="primary"
              >
                ENTRAR EM CONTATO
              </Button>
            )}
            {!extFormValid && (
              <Button
                onClick={reportInvalidHandler}
                variant="contained"
                color="secondary"
              >
                O QUE FALTA PREENCHER?
              </Button>
            )}
          </form>
        </div>
      </Zoom>
      <div className="whatsapp-btn">
        {zoom && (
          <Zoom in={trigger}>
            <div role="presentation" className={classes.scrollRootLeft}>
              <Fab color="primary" onClick={showWindowToggleHandler}>
                <WhatsAppIcon style={{ fontSize: "2.2rem" }} />
              </Fab>
            </div>
          </Zoom>
        )}
        {!zoom && (
          <Fab
            color="primary"
            className={classes.scrollRootLeft}
            onClick={showWindowToggleHandler}
          >
            <WhatsAppIcon style={{ fontSize: "2.2rem" }} />
          </Fab>
        )}
      </div>
    </React.Fragment>
  );
};
