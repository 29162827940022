import Popover from "@material-ui/core/Popover";
import React, { useContext, Fragment, useState } from "react";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import { InputPrefixOutlinedOptionalInt } from "../../shared/components/FormElements/InputPrefixOutlinedOptionalInt";
import { SettingsContext } from "../../shared/context/settingsContext";
import { addService } from "../api/salesFunnelAPI";
import { calcKitPriceForVendor } from "../../kits/util/calcKitPriceForVendor";
import { getPriceComponentsValues } from "../../proposal/util/getPriceComponentsValues";
import { addThousandSeparator } from "../../shared/util/addThousandSeparator";
import { useForm } from "../../shared/hooks/formHook";
import {
  AuthContextProps,
  KanbanFilledData,
  ProposalData,
} from "../../shared/data/types";

import { PopoverFormStyles } from "./PopoverFormStyles";

interface PopoverFormAddServiceProps {
  itemId: string;
  item: KanbanFilledData["items"][0];
  anchorEl: HTMLButtonElement | HTMLLIElement | null;
  setAnchorEl: React.Dispatch<
    React.SetStateAction<HTMLButtonElement | HTMLLIElement>
  >;
  auth: AuthContextProps;
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean
  ) => Promise<any>;
  setKanbanData: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
}

export const PopoverFormAddService = (props: PopoverFormAddServiceProps) => {
  const { item, anchorEl, setAnchorEl, auth, sendRequest, setKanbanData } =
    props;
  const settingsCtx = useContext(SettingsContext);
  const open = Boolean(anchorEl);
  const classes = PopoverFormStyles();
  const [chosenService, setChosenService] = useState<string>(
    settingsCtx?.afterSalesServices?.[0]?._id
  );
  const { formState, inputHandler } = useForm({}, true);

  const services = settingsCtx.afterSalesServices;

  const changeValueHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChosenService(e.target.value);
  };

  const confirmHandler = () => {
    const proposal = item?.approvedProposal as ProposalData;
    const customer = item?.customer;
    const power =
      proposal?.realSystemData?.power || item?.afterSalesData?.power || 0;
    const modsQtd = proposal?.pvModuleData?.length
      ? proposal?.pvModuleData?.map((m) => m.quantity)?.reduce((a, b) => a + b)
      : item?.afterSalesData?.modsQtd || 0;
    const invQtd = proposal?.inverterData?.length
      ? proposal?.inverterData?.map((i) => i.iQuantity)?.reduce((a, b) => a + b)
      : item?.afterSalesData?.invQtd || 0;
    const distance =
      proposal?.realSystemData?.distance || item?.afterSalesData?.distance || 0;
    const rootHight = item?.afterSalesData?.roofHight || 0;
    const inverterType =
      proposal?.inverterData?.[0]?.type !== "microinverter"
        ? "String"
        : "Micro";

    const servicePrice = calcKitPriceForVendor({
      auth,
      settingsCtx,
      systemPower: power,
      modulesQtd: modsQtd,
      invQtd: invQtd,
      eqpPriceTotalNoMarkup: 0,
      ownerId: auth?.userId,
      distance: distance,
      location: customer?.city || "",
      invPower: 0,
      voltage: 0,
      fixation: "",
      service: chosenService,
      roofHight: rootHight || 0,
      inverterType,
      preVendor: null,
    });

    const { serviceCosts, costPercentages } = getPriceComponentsValues(
      auth.userId,
      settingsCtx,
      power,
      modsQtd,
      invQtd,
      distance || 0,
      customer?.city || "",
      0,
      0,
      "",
      rootHight || 0,
      chosenService,
      inverterType,
      null
    );

    let priceComps: string[] = [];
    serviceCosts.forEach((c) => {
      priceComps.push(
        `${c.name} - ${
          c.isRevenue ? "Receita" : "Custo"
        } - R$ ${addThousandSeparator(c.value)}`
      );
    });

    costPercentages.forEach((c) => {
      priceComps.push(
        `${c.name} - ${
          c.isRevenue ? "Receita" : "Custo"
        } - ${addThousandSeparator(c.value || 0)} %`
      );
    });

    addService({
      sendRequest,
      auth,
      setKanbanData,
      price: servicePrice,
      priceComps,
      serviceId: chosenService,
      saleId: item?._id,
      quantity: +formState.inputs?.quantity?.value || 1,
    });
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <Popover
        id={item?._id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        style={{ zIndex: 502 }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <form className={classes.formRoot}>
          <FormControl>
            <InputLabel id="fetch-type-filter-selector-label">
              Selecionar serviço / produto
            </InputLabel>
            <Select
              labelId="fetch-type-filter-selector-label"
              id="fetch-type-filter-selector"
              value={chosenService}
              onChange={changeValueHandler}
            >
              {services?.map((option) => {
                return (
                  <MenuItem key={option._id} value={option._id}>
                    {option.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {settingsCtx?.afterSalesServices?.find((s) => s._id === chosenService)
            ?.type === "SUPPLY" && (
            <InputPrefixOutlinedOptionalInt
              id="quantity"
              type="text"
              label="Unidades"
              prefix="Qtd"
              variant="outlined"
              onInput={inputHandler}
              initialValue={"1"}
              helperText="Insira a quantidade de unidades do produto."
              reinitialize
            />
          )}
          <Button
            variant="contained"
            color="primary"
            disabled={!chosenService}
            onClick={() => {
              confirmHandler();
              setAnchorEl(null);
            }}
          >
            ADICIONAR SERVIÇO
          </Button>
        </form>
      </Popover>
    </Fragment>
  );
};
