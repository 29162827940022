import { AuthContextProps } from "../../shared/data/types";

export interface FetchProps {
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean
  ) => Promise<any>;
  auth: AuthContextProps;
  setZendeskJWT: React.Dispatch<React.SetStateAction<string>>;
}

// CREATE TALLOS CONTACT ////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////
interface AuthZendeskUserProps extends FetchProps {}

export const authZendeskUser = async (props: AuthZendeskUserProps) => {
  const { sendRequest, auth, setZendeskJWT } = props;

  try {
    const responseData: { token: string } = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/general/zendesk`,
      "POST",
      null,
      {
        Authorization: "Bearer " + auth.token,
      }
    );

    setZendeskJWT(responseData?.token);
  } catch (err) {}
};
