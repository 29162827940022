import { useState, useEffect, Fragment } from "react";
import IconButton from "@material-ui/core/IconButton";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import DeleteForeverRoundedIcon from "@material-ui/icons/DeleteForeverRounded";

import { useHttpClient } from "../../shared/hooks/httpHook";
import { ModalError } from "../../shared/components/UIElements/ModalError";
import { ModalConfirm } from "../../shared/components/UIElements/ModalConfirm";
import { LoadingSpinnerOverlayRegular } from "../../shared/components/UIElements/LoadingSpinnerOverlayRegular";
import { PopoverFormRejectReasonChoice } from "./PopoverFormRejectReasonChoice";
import { removeRejectReason } from "../api/salesFunnelAPI";
import {
  AuthContextProps,
  KanbanFilledData,
  SalePopCustData,
} from "../../shared/data/types";

import "./SaleDash.scss";

interface SaleDashRejectReasonItemProps {
  auth: AuthContextProps;
  loadedItem: SalePopCustData;
  rejReason: SalePopCustData["rejectReasons"][0];
  setLoadedData: React.Dispatch<React.SetStateAction<KanbanFilledData>>;
}

export const SaleDashRejectReasonItem = (
  props: SaleDashRejectReasonItemProps
) => {
  const { auth, loadedItem, rejReason, setLoadedData } = props;
  const { error, sendRequest, clearError, isLoading } = useHttpClient();
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [anchorElFormRejectReason, setAnchorElFormRejectReason] = useState<
    null | HTMLButtonElement | HTMLLIElement
  >(null);

  const removeeRejectReasonHandler = () => {
    setShowConfirmModal(false);
    removeRejectReason({
      sendRequest,
      auth,
      saleId: loadedItem?._id,
      reasonId: rejReason?._id,
      setKanbanData: setLoadedData,
    });
  };

  return (
    <Fragment>
      <ModalError error={error} onClear={clearError} />
      <PopoverFormRejectReasonChoice
        itemId={loadedItem?._id}
        reasonId={rejReason?._id}
        item={loadedItem}
        anchorEl={anchorElFormRejectReason}
        setAnchorEl={setAnchorElFormRejectReason}
        auth={auth}
        sendRequest={sendRequest}
        setKanbanData={setLoadedData}
      />
      <ModalConfirm
        open={showConfirmModal}
        closeHandler={() => {
          setShowConfirmModal(false);
        }}
        confirmHandler={() => {
          removeeRejectReasonHandler();
        }}
        message="Deseja excluir permanentemente este item?"
      />
      <div className="sale-dash__checklist-checkbox">
        {isLoading && <LoadingSpinnerOverlayRegular />}
        <div className="">
          <p className={`sale-dash__checklist-task`}>{rejReason?.reason}</p>
          <p className={`sale-dash__checklist-date`}>
            {rejReason?.registryDate}
          </p>
        </div>
        <div
          className={`sale-dash__cust-data-actions sale-dash__cust-data-actions--2`}
        >
          <IconButton
            size="small"
            style={{
              color: "#263351",
            }}
            title="Finalizar"
            className={`table__item-action`}
            onClick={(e) => {
              setAnchorElFormRejectReason(e.currentTarget);
            }}
          >
            <EditRoundedIcon style={{ fontSize: "22px" }} />
          </IconButton>
          <IconButton
            size="small"
            style={{
              color: "#263351",
            }}
            title="Finalizar"
            className={`table__item-action`}
            onClick={() => {
              setShowConfirmModal(true);
            }}
          >
            <DeleteForeverRoundedIcon style={{ fontSize: "22px" }} />
          </IconButton>
        </div>
      </div>
    </Fragment>
  );
};
