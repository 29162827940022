import { useHistory } from "react-router-dom";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSolarPanel,
  faBox,
  faWaveSquare,
} from "@fortawesome/free-solid-svg-icons";

interface MenuEquipamentsOptionsProps {
  anchorEl: HTMLButtonElement | HTMLSpanElement | HTMLLIElement;
  setAnchorEl: React.Dispatch<
    React.SetStateAction<HTMLButtonElement | HTMLSpanElement | HTMLLIElement>
  >;
  type: "registro" | "upload";
  clickCloser: () => void;
}

export const MenuEquipamentsOptions = (props: MenuEquipamentsOptionsProps) => {
  const { anchorEl, setAnchorEl, type, clickCloser } = props;
  const history = useHistory();

  const navHandler = (eqp: "modulos" | "inversores" | "equipamentos") => {
    history.push(`/${eqp}/${type}`);
    setAnchorEl(null);
    clickCloser();
  };

  return (
    <div>
      <Menu
        style={{ zIndex: 10001 }}
        id="opcoes-item-funil"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        <MenuItem
          onClick={(e) => {
            navHandler("modulos");
          }}
        >
          <FontAwesomeIcon
            icon={faSolarPanel}
            style={{ fontSize: "1rem", marginRight: "1rem" }}
          />{" "}
          MÓDULOS
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            navHandler("inversores");
          }}
        >
          <FontAwesomeIcon
            icon={faWaveSquare}
            style={{ fontSize: "1rem", marginRight: "1rem" }}
          />{" "}
          INVERSORES
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            navHandler("equipamentos");
          }}
        >
          <FontAwesomeIcon
            icon={faBox}
            style={{ fontSize: "1rem", marginRight: "1.25rem" }}
          />{" "}
          ITENS ADICIONAIS
        </MenuItem>
      </Menu>
    </div>
  );
};
