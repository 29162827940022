import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";

const Alert = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

interface SnackbarAlertProps {
  openMessage: boolean;
  setOpenMessage: React.Dispatch<React.SetStateAction<boolean>>;
  severity: "success" | "info" | "warning" | "error";
  txt?: string;
}

export const SnackbarAlert = (props: SnackbarAlertProps) => {
  const { openMessage, setOpenMessage, severity, txt } = props;

  const copyMessageCloseHandler = (
    event?: React.SyntheticEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenMessage(false);
  };

  return (
    <Snackbar
      open={openMessage}
      autoHideDuration={5000}
      onClose={copyMessageCloseHandler}
    >
      <Alert
        style={{ zIndex: 5000000 }}
        onClose={copyMessageCloseHandler}
        severity={severity}
      >
        {txt || "Link da Proposta Copiado!"}
      </Alert>
    </Snackbar>
  );
};
