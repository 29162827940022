import Popover from "@material-ui/core/Popover";
import React, { Fragment, useContext } from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import { ModalError } from "../../shared/components/UIElements/ModalError";
import { useHttpClient } from "../../shared/hooks/httpHook";
import { useForm } from "../../shared/hooks/formHook";
import { Input } from "../../shared/components/FormElements/Input";
import { InputCpf } from "../../shared/components/FormElements/InputCpf";
import { InputSelectRequired } from "../../shared/components/FormElements/InputSelectRequired";
import { InputPhone } from "../../shared/components/FormElements/InputPhone";
import { SettingsContext } from "../../shared/context/settingsContext";
import {
  addPersonToSignature,
  editPersonToSignature,
} from "../api/signatureAPI";
import {
  VALIDATOR_REQUIRE,
  VALIDATOR_MINLENGTH,
  VALIDATOR_EMAIL,
} from "../../shared/util/validators";
import {
  AuthContextProps,
  SignatureData,
  KanbanFilledData,
} from "../../shared/data/types";

import { PopoverFormStyles } from "./PopoverFormStyles";

interface PopoverAddSignaturePersonProps {
  anchorEl: HTMLButtonElement | HTMLLIElement | HTMLDivElement | null;
  setAnchorEl: React.Dispatch<
    React.SetStateAction<HTMLButtonElement | HTMLLIElement | HTMLDivElement>
  >;
  auth: AuthContextProps;
  setLoadedSignature: React.Dispatch<React.SetStateAction<SignatureData>>;
  saleId: string;
  loadedContract: KanbanFilledData["items"][0]["contracts"][0];
  loadedPerson?: SignatureData["persons"][0];
}

export const PopoverAddSignaturePerson = (
  props: PopoverAddSignaturePersonProps
) => {
  const {
    anchorEl,
    setAnchorEl,
    auth,
    setLoadedSignature,
    saleId,
    loadedContract,
    loadedPerson,
  } = props;
  const { error, sendRequest, clearError, isLoading } = useHttpClient();
  const open = Boolean(anchorEl);
  const classes = PopoverFormStyles();
  const settingsCtx = useContext(SettingsContext);
  const { formState, inputHandler } = useForm({}, false);

  const addPersonHandler = () => {
    addPersonToSignature({
      sendRequest,
      auth,
      setLoadedSignature,
      saleId,
      saleContractId: loadedContract._id,
      formState,
    }).then(() => {
      setAnchorEl(null);
    });
  };

  const editPersonHandler = () => {
    editPersonToSignature({
      sendRequest,
      auth,
      setLoadedSignature,
      saleId,
      saleContractId: loadedContract._id,
      personId: loadedPerson?._id,
      formState,
    }).then(() => {
      setAnchorEl(null);
    });
  };

  return (
    <Fragment>
      <ModalError error={error} onClear={clearError} />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        style={{ zIndex: 501 }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <form style={{ zIndex: 502 }} className={classes.formRoot}>
          <Input
            id="name"
            type="text"
            label="Nome"
            isCharOnly
            variant="outlined"
            validators={[VALIDATOR_REQUIRE()]}
            helperText="Insira o nome completo do signatário."
            onInput={inputHandler}
            initialValue={loadedPerson?.name || ""}
            initialValid={!!loadedPerson?.name}
            forceError={
              formState.inputs.name && formState.inputs.name.forceError
            }
          />
          <Input
            id="surname"
            type="text"
            label="Sobrenome"
            isCharOnly
            variant="outlined"
            validators={[VALIDATOR_REQUIRE()]}
            helperText="Insira o sobrenome completo do signatário."
            onInput={inputHandler}
            initialValue={loadedPerson?.surname || ""}
            initialValid={!!loadedPerson?.surname}
            forceError={
              formState.inputs.surname && formState.inputs.surname.forceError
            }
          />
          <Input
            id="email"
            type="email"
            label="email"
            variant="outlined"
            validators={[VALIDATOR_EMAIL()]}
            helperText="Insira o e-mail do signatário."
            onInput={inputHandler}
            initialValue={loadedPerson?.email || ""}
            initialValid={!!loadedPerson?.email}
            forceError={
              formState.inputs.email && formState.inputs.email.forceError
            }
          />
          <InputCpf
            id="document"
            type="text"
            label="CPF"
            variant="outlined"
            validators={[VALIDATOR_MINLENGTH(11)]}
            helperText="Insira o CPF do signatário."
            onInput={inputHandler}
            initialValue={loadedPerson?.document || ""}
            initialValid={
              loadedPerson?.document?.replace(/\D/g, "")?.length >= 11
            }
            forceError={
              formState.inputs.document && formState.inputs.document.forceError
            }
          />
          <InputPhone
            id="phone"
            type="text"
            label="WhatsApp"
            variant="outlined"
            validators={[VALIDATOR_MINLENGTH(10)]}
            helperText="Insira o número de WhatsApp do signatário."
            onInput={inputHandler}
            initialValue={loadedPerson?.phone || ""}
            initialValid={!!loadedPerson?.phone}
            forceError={
              formState.inputs.phone && formState.inputs.phone.forceError
            }
          />
          <InputSelectRequired
            id="authType"
            label="Autenticação da Assinatura"
            variant="outlined"
            onInput={inputHandler}
            options={["E-mail", "WhatsApp"]}
            initialValue={
              loadedPerson?.auth === "email"
                ? "E-mail"
                : loadedPerson?.auth === "whatsapp"
                ? "WhatsApp"
                : loadedPerson?.auth === "sms"
                ? "SMS"
                : ""
            }
          />
          {!isLoading && (
            <Button
              variant="contained"
              color="primary"
              disabled={!formState.isValid}
              onClick={() => {
                if (!loadedPerson) {
                  addPersonHandler();
                } else {
                  editPersonHandler();
                }
              }}
            >
              {!loadedPerson ? "ADICIONAR SIGNATÁRIO" : "EDITAR SIGNATÁRIO"}
            </Button>
          )}
          {isLoading && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "1.5rem",
              }}
            >
              <CircularProgress color="primary" />
            </div>
          )}
        </form>
      </Popover>
    </Fragment>
  );
};
