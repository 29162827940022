import Popover from "@material-ui/core/Popover";
import React, { Fragment, useContext, useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Docxtemplater from "docxtemplater";
import ChartModule from "docxtemplater-chart-module";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { useHistory } from "react-router-dom";
import { saveAs } from "file-saver";

import { useForm } from "../../shared/hooks/formHook";
import { InputPrefixOutlinedInt } from "../../shared/components/FormElements/InputPrefixOutlinedInt";
import { InputDefined } from "../../shared/components/FormElements/InputDefined";
import { useHttpClient } from "../../shared/hooks/httpHook";
import { ModalError } from "../../shared/components/UIElements/ModalError";
import { ModalPurchaseModule } from "../../shared/components/UIElements/ModalPurchaseModule";
import { InputSelect } from "../../shared/components/FormElements/InputSelect";
import { SettingsContext } from "../../shared/context/settingsContext";
import { getAfterSalesPropTagsValues } from "../util/getAfterSalesPropTagsValues";
import { dateFormat } from "../../shared/util/dateFormat";
import { fetchUserData } from "../../general/api/userAPI";
import {
  VALIDATOR_REQUIRE,
  VALIDATOR_INT,
  VALIDATOR_MIN,
} from "../../shared/util/validators";
import {
  AuthContextProps,
  TemplateData,
  ProposalData,
  CashFlowData,
  MonthlyGenerationData,
  SalePopCustData,
  UserData,
} from "../../shared/data/types";

import { PopoverFormStyles } from "./PopoverFormStyles";

interface PopoverGenAfterSalesPropProps {
  anchorEl: HTMLButtonElement | HTMLLIElement | null;
  setAnchorEl: React.Dispatch<
    React.SetStateAction<HTMLButtonElement | HTMLLIElement>
  >;
  sale: SalePopCustData;
  auth: AuthContextProps;
}

export const PopoverGenAfterSalesProp = (
  props: PopoverGenAfterSalesPropProps
) => {
  const { anchorEl, setAnchorEl, auth, sale } = props;
  const open = Boolean(anchorEl);
  const classes = PopoverFormStyles();
  const history = useHistory();
  const settingsCtx = useContext(SettingsContext);
  const { isLoading, error, success, sendRequest, clearError, clearSuccess } =
    useHttpClient();
  const [loadedTemplate, setLoadedTemplate] = useState<TemplateData>(null);
  const [loadedUser, setLoadedUser] = useState<UserData>(null);
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [showPurchaseModal, setShowPurchaseModule] = useState<boolean>(false);
  const [validityDate, setValidityDate] = useState<string>(dateFormat(15));
  const { formState, inputHandler } = useForm({}, false);

  useEffect(() => {
    const fetchTemplate = async () => {
      try {
        let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/templates/authenticated/data/${auth.userId}`;
        const responseData: { template: TemplateData } = await sendRequest(
          apiUrl,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setLoadedTemplate(responseData.template);
      } catch (err) {}
    };
    fetchTemplate();
  }, [sendRequest, auth]);

  useEffect(() => {
    if (auth.isLoggedIn) {
      fetchUserData({ sendRequest, auth, setLoadedData: setLoadedUser });
    }
  }, [auth.isLoggedIn]);

  useEffect(() => {
    if (+formState?.inputs?.validity?.value > 0) {
      setValidityDate(dateFormat(Number(formState.inputs.validity.value)));
    }
  }, [formState?.inputs?.validity?.value]);

  const templateOpts =
    loadedTemplate?.pdfTemplates
      ?.filter((t) => {
        return t?.type === "AFTER_SALES";
      })
      ?.map((opt) => {
        return opt?.name;
      }) || [];

  const loadFile = (
    url: string,
    callback: (err: Error, data: string) => void
  ) => {
    PizZipUtils.getBinaryContent(url, callback);
  };

  const populateTemplate = (err: Error, data: string) => {
    if (err) {
      console.log(err);
      alert("Erro ao tentar carregar o arquivo.");
      setIsGenerating(false);
      return;
    }
    const zip = new PizZip(data);
    const doc = new Docxtemplater(zip, {
      paragraphLoop: true,
      linebreaks: true,
      modules: [new ChartModule()],
    });

    doc.render(
      getAfterSalesPropTagsValues(
        sale,
        sale?.approvedProposal as ProposalData,
        loadedUser,
        validityDate
      )
    );
    const blob = doc.getZip().generate({
      type: "blob",
      mimeType:
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    }); //Output the document using Data-URI
    const file = new File([blob], "proposta.docx", {
      type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    });

    saveAs(blob, `Pós-Venda ${sale?.name || ""}.docx`);
    setIsGenerating(false);
    setAnchorEl(null);
  };

  const generateProposalHandler = () => {
    if (auth.activeModules?.includes("Gerador de Propostas de Pós-Venda")) {
      setIsGenerating(true);
      if (!!formState?.inputs?.template?.value) {
        const chosenTemplate = loadedTemplate?.pdfTemplates
          ?.filter((t) => {
            return t?.type === "AFTER_SALES";
          })
          ?.find((opt) => {
            return opt?.name === formState?.inputs?.template?.value;
          });

        if (!!chosenTemplate) {
          loadFile(
            `${process.env.REACT_APP_ASSET_URL}/${chosenTemplate.url}`,
            populateTemplate
          );
        } else {
          setIsGenerating(false);
        }
      } else {
        setIsGenerating(false);
      }
    } else {
      setShowPurchaseModule(true);
    }
  };

  return (
    <Fragment>
      <ModalError error={error} onClear={clearError} />
      <ModalPurchaseModule
        open={showPurchaseModal}
        setOpen={setShowPurchaseModule}
        title="LIBERE AS PROPOSTAS DE PÓS-VENDA"
        content="Com o módulo 'GERADOR DE PROPOSTAS DE PÓS-VENDA' você poderá gerar propostas personalizadas para serviços de pós-venda. Chame nossa equipe no WhatsApp e descubra como liberar esse módulo em sua conta!"
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        style={{ zIndex: 501 }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <form style={{ zIndex: 502 }} className={classes.formRoot}>
          <InputSelect
            id="template"
            label="Template"
            variant="outlined"
            onInput={inputHandler}
            options={templateOpts || []}
            initialValue={templateOpts?.[0]}
          />
          <div className="form-inputs-grid-1fr-1fr">
            <InputPrefixOutlinedInt
              id="validity"
              type="text"
              label="Validade"
              variant="outlined"
              prefix="Dias"
              onInput={inputHandler}
              validators={[
                VALIDATOR_REQUIRE(),
                VALIDATOR_INT(),
                VALIDATOR_MIN(1),
              ]}
              initialValue={
                settingsCtx.preferences?.proposalGenStdValues?.validity?.toString() ||
                "15"
              }
              initialValid={true}
              helperText="Validade da proposta em dias. Ex: 15."
              forceError={
                formState.inputs?.validity &&
                formState?.inputs?.validity?.forceError
              }
            />
            <InputDefined
              id="validityDate"
              type="text"
              label="Data Validade"
              onInput={inputHandler}
              disabled={true}
              variant="filled"
              validators={[VALIDATOR_REQUIRE()]}
              initialValue={validityDate}
            />
          </div>
          {!isLoading && !isGenerating && loadedTemplate && loadedUser && (
            <Button
              variant="contained"
              color="primary"
              disabled={!formState.isValid || templateOpts?.length === 0}
              onClick={generateProposalHandler}
            >
              GERAR PROPOSTA DE PÓS-VENDA
            </Button>
          )}
          {(isLoading || isGenerating || !loadedTemplate || !loadedUser) && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "1.5rem",
              }}
            >
              <CircularProgress color="primary" />
            </div>
          )}
        </form>
      </Popover>
    </Fragment>
  );
};
