import { Fragment, useState } from "react";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import DeleteForeverRoundedIcon from "@material-ui/icons/DeleteForeverRounded";

import { ModalConfirm } from "../../shared/components/UIElements/ModalConfirm";
import { PopoverFormAddOrEditNote } from "./PopoverFormAddOrEditNote";
import { deleteNote } from "../api/salesFunnelAPI";
import {
  AuthContextProps,
  KanbanFilledData,
  NoteData,
  NotePopCustData,
} from "../../shared/data/types";

interface MenuSalesNotesOptionsProps {
  saleId: string;
  customerId: string;
  anchorEl: HTMLButtonElement;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLButtonElement>>;
  anchorElForm: HTMLLIElement | HTMLButtonElement;
  setAnchorElForm: React.Dispatch<
    React.SetStateAction<HTMLLIElement | HTMLButtonElement>
  >;
  note: NoteData | NotePopCustData;
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean
  ) => Promise<any>;
  auth: AuthContextProps;
  setScheduleData?: React.Dispatch<React.SetStateAction<NotePopCustData[]>>;
  setLoadedData?: React.Dispatch<React.SetStateAction<NotePopCustData[]>>;
}

export const MenuSalesNotesOptions = (props: MenuSalesNotesOptionsProps) => {
  const {
    anchorEl,
    setAnchorEl,
    anchorElForm,
    setAnchorElForm,
    note,
    sendRequest,
    auth,
    saleId,
    customerId,
    setLoadedData,
    setScheduleData,
  } = props;
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

  const closeConfirmHandler = () => {
    setConfirmDelete(false);
  };

  const confirmHandler = () => {
    deleteNote({
      sendRequest,
      auth,
      sid: saleId,
      nid: note?._id,
      setLoadedData,
      setScheduleData: setScheduleData,
      scheduleListElement: false,
    });
    setConfirmDelete(false);
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <ModalConfirm
        open={confirmDelete}
        closeHandler={closeConfirmHandler}
        confirmHandler={confirmHandler}
        message="Deseja excluir permanentemente esta nota?"
      />
      <PopoverFormAddOrEditNote
        id={saleId}
        nid={note?._id}
        note={note}
        anchorEl={anchorElForm}
        setAnchorEl={setAnchorElForm}
        auth={auth}
        sendRequest={sendRequest}
        setLoadedData={setLoadedData}
        setScheduleData={setScheduleData}
        apiCall="edit"
        initalContent={note?.content}
        initialtype={note?.type}
        initialWarning={note?.warning}
        initialWarningDate={note?.notificationDate}
        initWarningTimeStart={note?.notificationTimeStart}
        initWarningTimeEnd={note?.notificationTimeEnd}
      />
      <div>
        <Menu
          id="opcoes-item-funil"
          anchorEl={anchorEl}
          keepMounted
          style={{ zIndex: 10001 }}
          open={Boolean(anchorEl)}
          onClose={() => {
            setAnchorEl(null);
          }}
        >
          <MenuItem
            onClick={(e) => {
              setAnchorElForm(e.currentTarget);
              setAnchorEl(null);
            }}
          >
            <EditRoundedIcon style={{ marginRight: "0.75rem" }} /> Editar Nota
          </MenuItem>
          {auth.type !== "VENDOR" && (
            <MenuItem
              onClick={() => {
                setConfirmDelete(true);
              }}
            >
              <DeleteForeverRoundedIcon style={{ marginRight: "0.75rem" }} />{" "}
              Excluir Nota
            </MenuItem>
          )}
        </Menu>
      </div>
    </Fragment>
  );
};
