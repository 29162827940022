import React, { Fragment, useEffect, useState } from "react";
import MobileDrawer from "@material-ui/core/Drawer";

import { MobileNavLinks, MobileNavLinksProps } from "./MobileNavLinks";
import { ModalPurchaseModule } from "../UIElements/ModalPurchaseModule";
import { BeforeInstallPromptEvent } from "../../../App";

interface SideDrawerProps {
  anchor: "left" | "top" | "right" | "bottom";
  open: boolean;
  onClose: () => void;
  classes: string;
  clickCloser: () => void;
  sideMenu?: boolean;
  addToHomescreenEvent: BeforeInstallPromptEvent;
  setAddToHomescreenEvent: React.Dispatch<
    React.SetStateAction<BeforeInstallPromptEvent>
  >;
  slug: string;
}

export const SideDrawer = (props: SideDrawerProps) => {
  const {
    addToHomescreenEvent,
    setAddToHomescreenEvent,
    anchor,
    open,
    onClose,
    classes,
    clickCloser,
    slug,
  } = props;
  const [showPurchaseModal, setShowPurchaseModule] = useState<boolean>(false);

  const openPurchaseModuleModalHandler = () => {
    setShowPurchaseModule(true);
  };

  return (
    <Fragment>
      <ModalPurchaseModule
        open={showPurchaseModal}
        setOpen={setShowPurchaseModule}
        title="FAÇA O UPGRADE PARA O PLANO EMPRESARIAL"
        content="No plano empresarial você terá acesso a: Website com Simulador/Calculadora Solar, Gerenciador de Contratos, Gerenciador de Arquivos, Gerenciador de Obras, Gerenciador de Projetos, Gerenciador de Pós-Venda, Múltiplas Contas de Colaboradores e muito mais. Chame-nos no WhatsApp e saiba mais sobre o Upgrade do seu plano!"
      />
      <MobileDrawer anchor={anchor} open={open} onClose={onClose}>
        <nav className={classes}>
          <MobileNavLinks
            clickCloser={clickCloser}
            addToHomescreenEvent={addToHomescreenEvent}
            setAddToHomescreenEvent={setAddToHomescreenEvent}
            slug={slug}
            openPurchaseModuleModalHandler={openPurchaseModuleModalHandler}
          />
        </nav>
      </MobileDrawer>
    </Fragment>
  );
};
