import { makeStyles } from "@material-ui/core/styles";

export const LoadingSpinnerFullScreenGearStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    zIndex: 5,
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "transparent",
    position: "absolute",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
  rootCloserToTop: {
    display: "flex",
    zIndex: 5,
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "transparent",
    position: "absolute",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
  linearRoot: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));
