import { useState, useEffect, Fragment } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

import { SaleDashHistoryItem } from "./SaleDashHistoryItem";
import { ModalError } from "../../shared/components/UIElements/ModalError";
import { ButtonLoadMore } from "../../shared/components/UIElements/ButtonLoadMore";
import { useHttpClient } from "../../shared/hooks/httpHook";
import { getCustomerHistory } from "../../customer/api/historyAPI";
import { AuthContextProps, HistoryData } from "../../shared/data/types";

import "./SaleDash.scss";

interface SaleDashHistoryProps {
  auth: AuthContextProps;
  customerId: string;
}

export const SaleDashHistory = (props: SaleDashHistoryProps) => {
  const { auth, customerId } = props;
  const [multiplier, setMultiplier] = useState<number>(0);
  const [showLoadMore, setShowLoadMore] = useState<boolean>(false);
  const [loadedData, setLoadedData] = useState<HistoryData[]>(null);
  const { error, sendRequest, clearError, isLoading } = useHttpClient();

  useEffect(() => {
    if (!!customerId) {
      getCustomerHistory({
        sendRequest,
        auth,
        cid: customerId,
        hidration: true,
        setLoadedData,
        setShowLoadMore,
        multiplier: 0,
      });
    }

    return () => {
      setLoadedData(null);
    };
  }, [sendRequest, auth, customerId]);

  const fetchHistoryHandler = async (mult: number) => {
    await getCustomerHistory({
      sendRequest,
      auth,
      cid: customerId,
      hidration: false,
      setLoadedData,
      setShowLoadMore,
      multiplier: mult,
    });
  };

  return (
    <Fragment>
      <ModalError error={error} onClear={clearError} />
      {isLoading && !showLoadMore && (
        <div className="sale-dash__col-right-content-spinner">
          <CircularProgress color="primary" />
        </div>
      )}
      {loadedData?.length === 0 && (
        <p className="sale-dash__col-right-content-empty">
          Este cliente não possui histórico
        </p>
      )}
      <div className="sale-dash__history">
        {loadedData?.length > 0 &&
          loadedData.map((item, i) => {
            return (
              <SaleDashHistoryItem
                auth={auth}
                setLoadedData={setLoadedData}
                loadedHistory={item}
                index={i}
                allHistory={loadedData}
                key={`history-${i}`}
              />
            );
          })}
      </div>
      {!!loadedData && (
        <div className="sale-dash__col-right-content-load-more">
          <ButtonLoadMore
            setMultiplier={setMultiplier}
            showLoadMore={showLoadMore}
            isLoading={isLoading && !!loadedData}
            noMargin
            small
            fetchItems={fetchHistoryHandler}
          />
        </div>
      )}
    </Fragment>
  );
};
